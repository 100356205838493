import { useTranslation } from 'react-i18next';
import { CheckBadgeIcon, CurrencyDollarIcon, BoltIcon } from '@heroicons/react/24/outline';
import { useBreakpoints } from '@/utils/breakpoint';

export function Features() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div className="mt-20 bg-brand-blue py-14 text-white">
      <div className="container mx-auto flex flex-col gap-8 px-4 lg:flex-row lg:justify-between lg:gap-16">
        <div className="text-left lg:mr-10 lg:text-center">
          <div className="text-5xl font-semibold lg:text-6xl">98%</div>
          <div className="text-2xl font-medium lg:text-3xl">{t('common.successRate')}</div>
        </div>
        <div
          className="flex-1"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
        >
          <div className="text-base font-medium lg:text-xl">
            <CheckBadgeIcon className="mx-auto mr-2 inline-block h-5 w-5 align-sub lg:h-6 lg:w-6" />
            {t('programs.expertReview')}
          </div>
          <div className="break-keep-all-kr mt-1 text-sm font-light lg:mt-3 lg:text-base">
            {t('programs.expertReviewDescription')}
          </div>
        </div>
        <div
          className="flex-1"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
        >
          <div className="text-base font-medium lg:text-xl">
            <CurrencyDollarIcon className="mx-auto mr-2 inline-block h-5 w-5 align-sub lg:h-6 lg:w-6" />
            {t('programs.lowerCost')}
          </div>
          <div className="break-keep-all-kr mt-1 text-sm font-light lg:mt-3 lg:text-base">
            {t('programs.lowerCostDescription')}
          </div>
        </div>
        <div
          className="flex-1"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
        >
          <div className="text-base font-medium lg:text-xl">
            <BoltIcon className="mx-auto mr-2 inline-block h-5 w-5 align-sub lg:h-6 lg:w-6" />
            {t('programs.fasterAndMoreAccurate')}
          </div>
          <div className="break-keep-all-kr mt-1 text-sm font-light lg:mt-3 lg:text-base">
            {t('programs.fasterAndMoreAccurateDescription')}
          </div>
        </div>
      </div>
      <div id="required-documents-desktop" />
    </div>
  );
}

import { CheckListItem } from '@/components/partner/CheckListItem';
import { PartnerFeatures } from '@/components/partner/PartnerFeatures';
import Layout from '@/components/ui/layouts/Layout';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { Quote } from '@/components/ui/review/Quote';
import { useBreakpoints } from '@/utils/breakpoint';
import SEO from '@/components/ui/seo/SEO';

function BreadCrumbs() {
  const { t } = useTranslation();
  return (
    <nav className="container mx-auto rounded-lg px-4 text-gray-700" aria-label="Breadcrumb">
      <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
        <li className="inline-flex items-center">
          <LocalizedLink
            to="/partners/"
            className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
          >
            {t('header.partners')}
          </LocalizedLink>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{t('partner.agencies')}</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default function Agencies() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <Layout>
      <main className="mt-10">
        <SEO title={`SuperVisas | ${t('seo.agencies')}`} description={t('partner.forAgenciesDescription')}>
          <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
        </SEO>
        <div className="container mx-auto px-4">
          <BreadCrumbs />
          <div className="mt-20 mb-20 flex flex-col gap-16 lg:mx-auto lg:max-w-[1200px] lg:flex-row">
            <div className="w-full lg:min-w-[420px] lg:flex-1">
              <StaticImage
                src="../../assets/img/partner/agencies.jpg"
                alt="agencies"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="text-gray-500 lg:flex-1">
              <h1 className="font-display text-lg font-bold text-gray-800 lg:text-3xl">{t('partner.agencies')}</h1>
              <p className="mt-4">{t('partner.superVisasHasHelpedOver40ImmigrationAgencies')}</p>
              <ul className="mt-10 space-y-6">
                <CheckListItem
                  title={t('partner.accurateFastVisaProcessing')}
                  description={t('partner.accurateFastVisaProcessingDescription')}
                />
                <CheckListItem title={t('partner.reducedCost')} description={t('partner.reducedCostDescription1')} />
              </ul>
            </div>
          </div>
        </div>
        <PartnerFeatures />
        <div className="container mx-auto flex flex-col px-4 lg:flex-row lg:gap-8">
          <ul
            className="mt-12 flex flex-col items-center gap-8 text-center lg:max-w-[500px]"
            {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          >
            <li className="flex flex-col p-4 md:p-6 lg:justify-between  lg:p-4 xl:p-8 2xl:p-12">
              <Quote author="ANURAAG JOSHI, FOUNDER & CEO" organization="BizCanada, Inc.">
                {t('partner.anuraagReview')}
              </Quote>
            </li>
            <li className="flex flex-col p-4 md:p-6 lg:justify-between  lg:p-4 xl:p-8 2xl:p-12">
              <Quote author="JENNY WU, MANAGER" organization="Wailian Group">
                {t('partner.jennyReview')}
              </Quote>
            </li>
          </ul>
          <div
            className="meetings-iframe-container mt-20 flex-1"
            data-src="https://meetings.hubspot.com/joe-chen/demo?embed=true"
          />
        </div>
      </main>
    </Layout>
  );
}

import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal';
import { CheckBox } from '../ui/inputs/CheckBox';
import { Radio } from '../ui/inputs/Radio';
import { FilterState, initialState, FilterAction, LanguageAbility } from './filterReducer';

const MAX_MONEY = 1000000;

interface ProgramFiltersProps {
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
  currency: 'CAD' | 'USD';
  showLanguageAbilityFilter?: boolean;
}

export function ProgramFilters({ state, dispatch, currency, showLanguageAbilityFilter = false }: ProgramFiltersProps) {
  const { t } = useTranslation();
  const { programTypes, jobOfferNeeded, languageAbility, moneyAvailable } = state;
  return (
    <div className="rounded-lg bg-gray-100 px-4 pt-8 lg:w-[300px] lg:py-8 lg:px-6 lg:shadow">
      <div className="flex justify-between border-b border-b-gray-300 pb-1">
        <div className="font-medium text-gray-400">{t('common.filterBy')}</div>
        <button
          type="button"
          className="-mr-2 -mt-2 rounded-md p-2 font-medium text-brand-blue hover:bg-brand-blue-light focus:text-brand-blue-dark disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
          onClick={() => dispatch({ type: 'RESET' })}
          disabled={equal(state, initialState)}
        >
          {t('common.resetFilters')}
        </button>
      </div>
      <div className="mt-6 flex flex-col gap-4 lg:gap-6">
        <div>
          <div className="mb-1 text-xs font-medium uppercase text-gray-500">{t('programs.wantTo')}</div>
          <CheckBox
            id="study"
            label={t('program.study')}
            name="study"
            checked={programTypes.includes('study')}
            onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'study' })}
          />
          <CheckBox
            id="visit"
            label={t('program.visit')}
            name="visit"
            checked={programTypes.includes('visitor')}
            onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'visitor' })}
          />
          <CheckBox
            id="work"
            label={t('program.work')}
            name="work"
            checked={programTypes.includes('work')}
            onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'work' })}
          />
          <CheckBox
            id="immigrate"
            label={t('program.immigrate')}
            name="immigrate"
            checked={programTypes.includes('immigrate')}
            onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'immigrate' })}
          />
        </div>
        <div>
          <div className="mb-1 text-xs font-medium uppercase text-gray-500">{t('programs.jobOfferNeeded')}</div>
          <div className="flex flex-col gap-0.5">
            <Radio
              id="jobOfferNeeded"
              label={t('common.yes')}
              name="jobOfferNeeded"
              checked={jobOfferNeeded === true}
              onChange={() => dispatch({ type: 'SET_JOB_OFFER_NEEDED', payload: true })}
            />
            <Radio
              id="jobOfferNotNeeded"
              label={t('common.no')}
              name="jobOfferNeeded"
              checked={jobOfferNeeded === false}
              onChange={() => dispatch({ type: 'SET_JOB_OFFER_NEEDED', payload: false })}
            />
          </div>
        </div>
        {showLanguageAbilityFilter && (
          <div>
            <div className="mb-1 text-xs font-medium uppercase text-gray-500">{t('programs.languageAbility')}</div>
            <div className="flex flex-col gap-0.5">
              <Radio
                id="noEnglish"
                label={t('programs.noEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.NO_ENGLISH}
                onChange={() => dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.NO_ENGLISH })}
                title={t('common.clbLanguageTestScoreOf', { score: '0~3' })}
              />
              <Radio
                id="elementaryEnglish"
                label={t('programs.elementaryEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.ELEMENTARY_ENGLISH}
                onChange={() => dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.ELEMENTARY_ENGLISH })}
                title={t('common.clbLanguageTestScoreOf', { score: '4~5' })}
              />
              <Radio
                id="limitedWorkingEnglish"
                label={t('programs.limitedWorkingEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.LIMITED_WORKING_ENGLISH}
                onChange={() =>
                  dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.LIMITED_WORKING_ENGLISH })
                }
                title={t('common.clbLanguageTestScoreOf', { score: '6' })}
              />
              <Radio
                id="professionalWorkingEnglish"
                label={t('programs.professionalWorkingEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.PROFESSIONAL_WORKING_ENGLISH}
                onChange={() =>
                  dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.PROFESSIONAL_WORKING_ENGLISH })
                }
                title={t('common.clbLanguageTestScoreOf', { score: '7~8' })}
              />
              <Radio
                id="fullProfessionalEnglish"
                label={t('programs.fullProfessionalEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.FULL_PROFESSIONAL_ENGLISH}
                onChange={() =>
                  dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.FULL_PROFESSIONAL_ENGLISH })
                }
                title={t('common.clbLanguageTestScoreOf', { score: '9' })}
              />
              <Radio
                id="nativeBilingualEnglish"
                label={t('programs.nativeBilingualEnglish')}
                name="languageAbility"
                checked={languageAbility === LanguageAbility.NATIVE_BILINGUAL_ENGLISH}
                onChange={() =>
                  dispatch({ type: 'SET_LANGUAGE_ABILITY', payload: LanguageAbility.NATIVE_BILINGUAL_ENGLISH })
                }
                title={t('common.clbLanguageTestScoreOf', { score: '10+' })}
              />
            </div>
          </div>
        )}
        <div>
          <label htmlFor="moneyAvailable" className="mb-2 block text-xs font-medium uppercase text-gray-500">
            <span className="flex items-center justify-between lg:inline">
              {currency === 'CAD' ? t('common.netWorthInCAD') : t('common.netWorthInUSD')}
              <div className="text-right text-base lg:mt-1 lg:h-5 lg:w-full">
                {moneyAvailable === undefined ? null : (
                  <span className="text-gray-600">
                    <span className="font-light">{currency === 'CAD' ? 'CA' : 'US'}</span>
                    <span className="font-mono font-medium tabular-nums text-gray-900">
                      {new Intl.NumberFormat(currency === 'CAD' ? 'en-CA' : 'en-US', {
                        style: 'currency',
                        currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(moneyAvailable)}
                      {moneyAvailable === MAX_MONEY ? '+' : null}
                    </span>
                  </span>
                )}
              </div>
            </span>
            <input
              id="moneyAvailable"
              type="range"
              min="0"
              max={MAX_MONEY}
              step="1000"
              value={moneyAvailable}
              onChange={(e) => dispatch({ type: 'SET_MONEY_AVAILABLE', payload: Number(e.target.value) })}
              className="mt-4 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
            />
          </label>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';

export function USDescription() {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="text-2xl font-semibold text-brand-blue lg:text-4xl">{t('common.unitedStates')}</h1>
      <ul className="mt-8 space-y-8 text-sm text-gray-500 lg:text-base">
        <li>
          <p className="break-keep-all-kr">{t('programs.unitedStatesDescription1')}</p>
        </li>
        <li>
          <p className="break-keep-all-kr">{t('programs.unitedStatesDescription2')}</p>
        </li>
        <li>
          <p className="break-keep-all-kr">{t('programs.unitedStatesDescription3')}</p>
        </li>
        <li>
          <p className="break-keep-all-kr">{t('programs.unitedStatesDescription4')}</p>
        </li>
      </ul>
    </div>
  );
}

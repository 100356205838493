import { useBreakpoints } from '@/utils/breakpoint';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../ui/links/ExternalLink';
import Link from '../ui/links/Link';

function Step({ title, description, stepNumber }: { title: string; description: React.ReactNode; stepNumber: number }) {
  const { isMobile } = useBreakpoints();
  return (
    <li
      className="flex gap-2 lg:gap-4"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue-50 font-sans-serif font-semibold tabular-nums leading-none text-brand-blue">
        {stepNumber}
      </span>
      <span className="flex-1">
        <div className="text-xl font-semibold text-gray-700">{title}</div>
        <p className="break-keep-all-kr mt-2 text-sm text-gray-500 lg:text-base">{description}</p>
      </span>
    </li>
  );
}

export function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-10 mb-20 px-4">
      <h3 className="mb-6 text-2xl font-semibold text-gray-800">{t('programs.howItWorks')}</h3>
      <ul className="space-y-6">
        <Step
          stepNumber={1}
          title={t('programs.createAnAccount')}
          description={t('programs.createAnAccountDescription')}
        />
        <Step
          stepNumber={2}
          title={t('programs.confirmationBeforePayment')}
          description={
            <Trans
              i18nKey="programs.confirmationBeforePaymentDescription"
              t={t}
              components={[
                <Link
                  to="/about#transparency-pledge"
                  variant="text"
                  className="!text-brand-blue hover:!text-brand-blue-dark"
                />,
                <ExternalLink
                  to="https://stripe.com/"
                  className="!text-brand-blue hover:!text-brand-blue-dark"
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          }
        />
        <Step
          stepNumber={3}
          title={t('programs.completeYourApplicationOnline')}
          description={t('programs.completeYourApplicationOnlineDescription')}
        />
        <Step
          stepNumber={4}
          title={t('programs.visaPreparationAndSubmission')}
          description={t('programs.visaPreparationAndSubmissionDescription')}
        />
        <Step
          stepNumber={5}
          title={t('programs.approvalAndRenewals')}
          description={t('programs.approvalAndRenewalsDescription')}
        />
      </ul>
      <div id="success-rate-desktop" />
    </div>
  );
}

import { ReactElement, useEffect, useState } from 'react';
import { useRedirect } from '@/utils/RedirectContext';
import { DialogTitle } from '@radix-ui/react-dialog';
import { useCountry } from '@/utils/CountryContext';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocalization } from 'gatsby-theme-i18n';
import { getItemFromLocalStorage } from '@/utils/storage-api';
import Footer from '../footers/Footer';
import Header from '../headers/Header';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader } from '../Dialog';
import Button from '../buttons/Button';

interface LayoutProps {
  children: ReactElement;
  variant?: 'blue' | 'white';
}

function SelectedCountry({ country }: { country: 'US' | 'CA' | '' }) {
  const { t } = useTranslation();
  if (country === 'US') {
    return (
      <StaticImage
        className="inline-block align-middle shadow-lg"
        src="../../../assets/img/us.svg"
        alt="USA flag"
        placeholder="blurred"
        layout="fixed"
        width={28.5}
        height={15}
      />
    );
  }
  if (country === 'CA') {
    return (
      <StaticImage
        className="inline-block align-middle shadow-lg"
        src="../../../assets/img/ca.svg"
        alt="Canada flag"
        placeholder="blurred"
        layout="fixed"
        width={30}
        height={15}
      />
    );
  }
  return <span>{t('common.notSpecified')}</span>;
}

function RedirectChecker({ children }) {
  const [persistedLang, setPersistedLang] = useState('en');
  const { showModal, acceptRedirect, rejectRedirect, resetRedirectState } = useRedirect();
  const { country } = useCountry();
  const { t } = useTranslation();
  const { config } = useLocalization();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const lang = getItemFromLocalStorage('i18nextLng');
      setPersistedLang(lang || 'en');
    }
  }, []);
  const language = config.find((item) => item.code === persistedLang)?.localName || 'English';
  return (
    <div>
      {children}
      <Dialog
        open={showModal}
        onOpenChange={(open) => {
          if (!open) {
            rejectRedirect();
            resetRedirectState();
          }
        }}
      >
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle className="font-medium text-gray-900 lg:text-lg lg:leading-6">
              {t('redirect.mismatchDetected')}
            </DialogTitle>
            <DialogDescription className="prose">
              <p>{t('redirect.theUrlYouHaveRequested')}</p>
              <div className="font-medium text-gray-700">{t('redirect.yourCurrentSettingsAreAsFollows')}</div>
              <div className="text-sm font-medium text-gray-700">
                {t('common.destinationCountry')}: <SelectedCountry country={country} />
              </div>
              <div className="text-sm font-medium text-gray-700">
                {t('common.language')}: <span>{language}</span>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                className="flex-1 border-green-600 bg-green-600 hover:border-green-700 hover:bg-green-700 focus:border-green-700 focus:bg-green-700"
                onClick={rejectRedirect}
              >
                {t('redirect.changeSettingsToMatchUrl')}
              </Button>
            </DialogClose>
            <Button className="flex-1" onClick={acceptRedirect}>
              {t('redirect.redirectAccordingToPreferences')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default function Layout({ children, variant = 'white' }: LayoutProps) {
  return (
    <RedirectChecker>
      <div className="reset-css flex min-h-screen flex-col justify-between text-base text-brand-dark" id="top">
        <div>
          <Header variant={variant} />
          <>{children}</>
        </div>
        <Footer />
      </div>
    </RedirectChecker>
  );
}

import { useBreakpoints } from '@/utils/breakpoint';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

export function PartnerFeatures() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div className="w-full border-t border-b border-t-gray-200 border-b-gray-200 bg-gray-50 py-10 xl:h-[280px]">
      <div className="container mx-auto px-4">
        <ul className="flex flex-col items-center gap-16 lg:flex-row lg:justify-between lg:gap-8">
          <li
            className="relative h-[160px] w-full max-w-[320px] lg:w-auto lg:max-w-none lg:flex-1 xl:h-[200px]"
            {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          >
            <span className="absolute left-0 top-0 z-10">
              <StaticImage
                className="w-[260px] xl:w-[320px]"
                src="../../assets/img/partner/feature1_front.png"
                alt="Program Name & Applicant"
                layout="fullWidth"
              />
            </span>
            <span className="absolute right-0 top-[42px] xl:top-[52px]">
              <StaticImage
                class="w-[280px] xl:w-[360px]"
                src="../../assets/img/partner/feature1_back.png"
                alt="Application Tracking"
                layout="fullWidth"
              />
            </span>
            <div className="absolute bottom-0 w-full text-center font-semibold text-brand-blue">
              {t('partner.onlineApplicationTracking')}
            </div>
          </li>
          <li
            className="relative h-[160px] w-full max-w-[320px] lg:w-auto lg:max-w-none lg:flex-1 xl:h-[200px]"
            {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          >
            <span className="absolute left-0 top-0">
              <StaticImage
                className="w-[280px] xl:w-[320px]"
                src="../../assets/img/partner/feature2_back.png"
                alt="Reply Time"
                layout="fullWidth"
              />
            </span>
            <span className="absolute right-0 top-[60px] z-10 xl:top-[66px]">
              <StaticImage
                className="w-[240px] xl:w-[270px]"
                src="../../assets/img/partner/feature2_front.png"
                alt="Send us a message button"
                layout="fullWidth"
              />
            </span>
            <div className="absolute bottom-0 w-full text-center font-semibold text-brand-blue">
              {t('partner.accessToImmigrationExperts')}
            </div>
          </li>
          <li
            className="relative h-[160px] w-full max-w-[320px] lg:w-auto lg:max-w-none lg:flex-1 xl:h-[200px]"
            {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          >
            <span className="absolute left-0 top-0">
              <StaticImage
                className="w-[280px] xl:w-[380px]"
                src="../../assets/img/partner/feature3_back.png"
                alt="Notification Message"
                layout="fullWidth"
              />
            </span>
            <span className="absolute right-[42px] top-[60px] z-10 xl:right-[30px] xl:top-[78px]">
              <StaticImage
                className="w-[70px] xl:w-[85px]"
                src="../../assets/img/partner/feature3_front.png"
                alt="Notification Bell Icon"
                layout="fullWidth"
              />
            </span>
            <div className="absolute bottom-0 w-full text-center font-semibold text-brand-blue">
              {t('partner.automatedVisaNotifications')}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

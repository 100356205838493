import { cn } from '@/utils/cn';
import { ComponentPropsWithoutRef } from 'react';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: 'primary' | 'secondary' | 'text' | 'outline';
  className?: string;
}

export default function Button({
  type = 'button',
  variant = 'primary',
  children,
  className = '',
  ...rest
}: ButtonProps) {
  /* eslint-disable react/button-has-type */
  switch (variant) {
    case 'primary':
      return (
        <button
          type={type}
          className={cn(
            `rounded-lg border-2 border-brand-blue bg-brand-blue px-5 py-2.5 text-base normal-case text-white transition-colors duration-150 hover:border-brand-blue-dark hover:bg-brand-blue-dark focus:border-brand-blue-dark focus:bg-brand-blue-dark active:bg-brand-blue-darkest`,
            className,
          )}
          {...rest}
        >
          {children}
        </button>
      );
    case 'secondary':
      return (
        <button
          type={type}
          className={cn(
            `rounded-lg border-2 border-blue-50 bg-blue-50 px-5 py-2.5 text-base normal-case text-brand-blue transition-colors duration-150 hover:border-blue-100 hover:bg-blue-100 hover:text-brand-blue-dark focus:border-blue-100 focus:bg-blue-100 focus:text-brand-blue-dark active:bg-brand-blue-darkest`,
            className,
          )}
          {...rest}
        >
          {children}
        </button>
      );
    case 'text':
      return (
        <button
          type={type}
          className={cn(
            `m-0 cursor-pointer rounded-lg border-0 bg-transparent px-5 py-2.5 text-base normal-case text-brand-blue transition-colors duration-150 hover:bg-brand-blue-light focus:bg-brand-blue-light active:bg-brand-blue active:text-white`,
            className,
          )}
          {...rest}
        >
          {children}
        </button>
      );
    case 'outline':
      return (
        <button
          type={type}
          className={cn(
            `rounded-lg border-2 border-brand-blue px-5 py-2.5 text-base normal-case text-brand-blue transition-colors duration-150 hover:bg-transparent/5 focus:bg-transparent/5`,
            className,
          )}
          {...rest}
        >
          {children}
        </button>
      );
    default:
      return null;
  }
}

import { HeroPathways } from '@/components/pathways/HeroPathways';
import { Pathway } from '@/components/pathways/Pathway';
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

export default function Pathways() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="px-4">
        <SEO
          title={`SuperVisas | ${t('seo.pathwaysToImmigrateToCanada')}`}
          description={t('seo.areYouLookingToMoveToCanada')}
        />
        <HeroPathways />
        <ul className="mx-auto mb-40 flex flex-col items-start gap-20 text-gray-500 lg:max-w-[813px] lg:items-center lg:gap-12">
          <Pathway
            title={t('pathways.studyThenWorkThenImmigrate')}
            description={t('pathways.studyThenWorkThenImmigrateDescription')}
            to="/pathways/study/"
          >
            <StaticImage
              src="../../assets/img/pathways/study.png"
              alt="study"
              placeholder="blurred"
              layout="fullWidth"
            />
          </Pathway>
          <Pathway
            title={t('pathways.workThenImmigrate')}
            description={t('pathways.workThenImmigrateDescription')}
            to="/pathways/work/"
          >
            <StaticImage src="../../assets/img/pathways/work.png" alt="work" placeholder="blurred" layout="fullWidth" />
          </Pathway>
          <Pathway
            title={t('pathways.investThenImmigrate')}
            description={t('pathways.investThenImmigrateDescription')}
            to="/pathways/invest/"
          >
            <StaticImage
              src="../../assets/img/pathways/invest.png"
              alt="invest"
              placeholder="blurred"
              layout="fullWidth"
            />
          </Pathway>
        </ul>
      </main>
    </Layout>
  );
}

import { ProgramSummary } from '@/@types/program';
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

interface SortState {
  sortBy: 'default' | 'name' | 'price';
  sortDirection: 'default' | 'asc' | 'desc';
}

interface SortAction {
  type: 'SORT_BY';
  payload: 'name' | 'price';
}

export function sortReducer(state: SortState, action: SortAction) {
  switch (action.type) {
    case 'SORT_BY':
      if (state.sortBy !== action.payload) {
        return {
          ...state,
          sortBy: action.payload,
          sortDirection: 'asc' as const,
        };
      }
      if (state.sortDirection === 'asc') {
        return {
          ...state,
          sortBy: action.payload,
          sortDirection: 'desc' as const,
        };
      }
      return {
        ...state,
        sortBy: 'default' as const,
        sortDirection: 'default' as const,
      };
    default:
      return state;
  }
}

export function SortIcon({ by, sortState }: { by: 'name' | 'price'; sortState: SortState }) {
  const { sortBy, sortDirection } = sortState;
  if (sortBy === 'default') {
    return <ChevronUpDownIcon className="ml-1 inline-block h-[18px] w-[18px]" />;
  }
  if (by !== sortBy) {
    return <ChevronUpDownIcon className="ml-1 inline-block h-[18px] w-[18px]" />;
  }
  if (sortDirection === 'default') {
    return <ChevronUpDownIcon className="ml-1 inline-block h-[18px] w-[18px]" />;
  }
  if (sortDirection === 'asc') {
    return <ChevronUpIcon className="ml-1 inline-block h-[18px] w-[18px]" />;
  }
  return <ChevronDownIcon className="ml-1 inline-block h-[18px] w-[18px]" />;
}

export function SortButton({
  by,
  children,
  sortState,
  onClick,
}: {
  by: 'name' | 'price';
  children: ReactNode;
  sortState: SortState;
  onClick: () => void;
}) {
  return (
    <button onClick={onClick} type="button" className="whitespace-nowrap">
      {children}
      <SortIcon by={by} sortState={sortState} />
    </button>
  );
}

export function sortByNameOrPrice(state: SortState, a: ProgramSummary, b: ProgramSummary) {
  if (state.sortBy === 'default') {
    return 0;
  }
  if (state.sortBy === 'name') {
    if (state.sortDirection === 'asc') {
      return a.name.localeCompare(b.name);
    }
    return b.name.localeCompare(a.name);
  }
  if (state.sortBy === 'price') {
    if (state.sortDirection === 'asc') {
      return a.price.amount - b.price.amount;
    }
    return b.price.amount - a.price.amount;
  }
  return 0;
}

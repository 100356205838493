import { useCountry } from '@/utils/CountryContext';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocalization } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

export function USAAndCanada() {
  const { t } = useTranslation();
  const { setCountry } = useCountry();
  const { localizedPath, defaultLang, prefixDefault, locale } = useLocalization();

  return (
    <div className="container mx-auto mt-24 flex flex-col items-center gap-10 px-4 lg:mt-32 lg:mb-16">
      <h4 className="w-full max-w-3xl text-center font-display text-xl font-semibold text-gray-600 text-balance lg:text-3xl lg:leading-normal">
        {t('home.clickToChooseYourDestination')}
      </h4>
      <div className="flex w-full flex-col gap-10 lg:w-fit lg:flex-row lg:items-center lg:justify-evenly lg:gap-40">
        <div className="flex flex-col items-center gap-2 lg:gap-6">
          <p className="text-lg font-semibold text-gray-600 lg:text-2xl">
            {t('home.myDestinationIs', { country: t('common.usa') })}
          </p>
          <button
            type="button"
            className="border-grey rounded border-2 p-1 hover:border-brand-blue"
            onClick={() => {
              setCountry('US');
              navigate(
                localizedPath({
                  defaultLang,
                  prefixDefault,
                  locale,
                  path: '/us',
                }),
              );
            }}
          >
            <StaticImage
              layout="fullWidth"
              className="w-[150px] lg:w-[190px]"
              src="../../assets/img/home/USA_flag.png"
              alt="USA Flag"
              placeholder="blurred"
            />
          </button>
        </div>
        <div className="flex flex-col items-center gap-2 lg:gap-6">
          <p className="text-lg font-semibold text-gray-600 lg:text-2xl">
            {t('home.myDestinationIs', { country: t('home.canada') })}
          </p>
          <button
            type="button"
            className="border-grey rounded border-2 p-1 hover:border-brand-blue"
            onClick={() => {
              setCountry('CA');
              navigate(
                localizedPath({
                  defaultLang,
                  prefixDefault,
                  locale,
                  path: '/canada',
                }),
              );
            }}
          >
            <StaticImage
              layout="fullWidth"
              className="w-[150px] lg:w-[187.5px]"
              src="../../assets/img/home/Canada_flag.png"
              alt="Canada Flag"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

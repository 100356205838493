import { useEffect, useState } from 'react';

export function useCheckList({ count }: { count: number }) {
  const [checked, setChecked] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setChecked((prevChecked) => (prevChecked + 1) % (count + 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return checked;
}

import { useBreakpoints } from '@/utils/breakpoint';
import { useTranslation } from 'react-i18next';

interface ImmigrationStatisticsProps {
  year: number;
  study: number;
  work: number;
  visit: number;
  immigrate: number;
  sourceLabel: string;
  sourceUrl: string;
}

export function ImmigrationStatistics({
  year,
  study,
  work,
  visit,
  immigrate,
  sourceLabel,
  sourceUrl,
}: ImmigrationStatisticsProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      className="px-4 lg:w-[467px] lg:px-0"
    >
      <h4 className="block border-b border-b-gray-400 pb-0.5 font-display text-base font-semibold uppercase text-gray-700 lg:text-xl">
        {t('programs.immigrationStatistics', { year })}
      </h4>
      <ul className="mt-4 grid grid-cols-2 gap-8 text-right text-xs font-medium text-gray-500">
        <li>
          <div className="mb-0.5 text-2xl font-semibold text-brand-blue lg:text-4xl">
            {Number(study).toLocaleString(language)}
          </div>
          <div>{t('programs.admittedForStudy')}</div>
        </li>
        <li>
          <div className="mb-0.5 text-2xl font-semibold text-brand-blue lg:text-4xl">
            {Number(work).toLocaleString(language)}
          </div>
          <div>{t('programs.admittedForWork')}</div>
        </li>
        <li>
          <div className="mb-0.5 text-2xl font-semibold text-brand-blue lg:text-4xl">
            {Number(visit).toLocaleString(language)}
          </div>
          <div>{t('programs.admittedForVisit')}</div>
        </li>
        <li>
          <div className="mb-0.5 text-2xl font-semibold text-brand-blue lg:text-4xl">
            {Number(immigrate).toLocaleString(language)}
          </div>
          <div>{t('programs.admittedForImmigration')}</div>
        </li>
      </ul>
      <div className="mt-8 text-xs text-gray-400">
        *{t('programs.source')}:{' '}
        <a href={sourceUrl} target="_blank" rel="noopener noreferrer" className="underline">
          {sourceLabel}
        </a>{' '}
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import { cn } from '@/utils/cn';
import ExternalLink from '../ui/links/ExternalLink';
import Link from '../ui/links/Link';

export function FeatureLinks({
  destinationCountry,
  className = '',
}: {
  destinationCountry: 'CA' | 'US';
  className?: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  return (
    <div className={cn('border-y border-gray-300 bg-gray-50', className)}>
      <ul className="container mx-auto flex flex-col px-4 py-4 lg:flex-row lg:py-8">
        <li className="flex flex-1 flex-col justify-between border-b border-gray-300 py-8 text-center lg:items-center lg:border-r lg:border-b-0 lg:py-4 lg:px-4">
          <div>
            <h5 className="text-xl font-semibold text-gray-700 lg:mx-auto lg:max-w-[317px]">
              {t('home.wonderingAbout')}
            </h5>
            <p className="mt-4 mb-8 text-gray-600 lg:mx-auto lg:max-w-[317px]">{t('home.wonderingAboutDescription')}</p>
          </div>
          <ExternalLink
            to={`/eligibility?${queryParams}`}
            variant="primary"
            className="w-full min-w-[200px] text-center lg:w-auto"
          >
            {t('home.useEligibilityChecker')}
          </ExternalLink>
        </li>
        <li
          className={cn(
            'flex flex-1 flex-col justify-between border-gray-300 py-8 text-center lg:items-center lg:py-4 lg:px-4',
            destinationCountry === 'CA' ? 'border-b lg:border-r lg:border-b-0' : '',
          )}
        >
          <div>
            <h5 className="text-xl font-semibold text-gray-700 lg:mx-auto lg:max-w-[317px]">
              {t('home.alreadyDoneYourResearch')}
            </h5>
            <p className="mt-4 mb-8 text-gray-600 lg:mx-auto lg:max-w-[317px]">
              {t('home.alreadyDoneYourResearchDescription')}
            </p>
          </div>
          <Link
            to={destinationCountry === 'CA' ? '/visas-canada/list/' : '/visas-us/list/'}
            variant="primary"
            className="w-full min-w-[200px] text-center lg:w-auto"
          >
            {t('home.searchAllVisas')}
          </Link>
        </li>
        {destinationCountry === 'CA' && (
          <li className="flex flex-1 flex-col justify-between py-8 text-center lg:items-center lg:py-4 lg:px-4">
            <div>
              <h5 className="text-xl font-semibold text-gray-700 lg:mx-auto lg:max-w-[317px]">
                {t('home.startingTheImmigrationJourney')}
              </h5>
              <p className="mt-4 mb-8 text-gray-600 lg:mx-auto lg:max-w-[317px]">
                {t('home.startingTheImmigrationJourneyDescription')}
              </p>
            </div>
            <Link to="/pathways/" variant="primary" className="w-full min-w-[200px] text-center lg:w-auto">
              {t('home.seeAllPathways')}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}

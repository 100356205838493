import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@/utils/breakpoint';

function Accordion({ title, description }: { title: string; description: ReactNode }) {
  const { isMobile } = useBreakpoints();
  return (
    <details
      className="question border-grey-lighter border-b py-4"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <summary className="flex items-center text-base font-semibold text-gray-700 lg:text-xl">
        <ChevronRightIcon className="mr-2 h-4 w-4 transition-all delay-100 ease-in-out" />
        {title}
      </summary>
      <div className="mt-4 px-6 text-sm leading-normal text-gray-600 lg:text-base">{description}</div>
    </details>
  );
}

export function FAQ({ destinationCountry }: { destinationCountry: 'US' | 'CA' }) {
  const { t } = useTranslation();

  const { isMobile } = useBreakpoints();
  return (
    <div className="container mx-auto my-20 px-4">
      <div
        className="flex flex-col items-start gap-4 lg:flex-row lg:justify-between"
        {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      >
        <h3 className="font-display text-xl font-medium text-brand-blue lg:text-2xl">
          {t('home.frequentlyAskedQuestions')}
        </h3>
        <LocalizedLink to="/faq/" className="text-brand-blue hover:text-brand-blue-dark">
          {t('home.moreFAQ')}
          <ChevronRightIcon className="ml-0.5 inline-block h-5 w-5 align-text-bottom" />
        </LocalizedLink>
      </div>
      <ul className="mt-6 mb-20">
        <li>
          <Accordion
            title={t('home.doYouIssueTheVisas')}
            description={
              destinationCountry === 'CA' ? t('home.doYouIssueTheVisasAnswer') : t('home.doYouIssueTheVisasAnswerUS')
            }
          />
        </li>
        <li>
          <Accordion
            title={t('home.isSuperVisasReputableCompany')}
            description={t('home.isSuperVisasReputableCompanyAnswerUS')}
          />
        </li>
        <li>
          <Accordion
            title={t('home.canYouFindMeAJob')}
            description={
              destinationCountry === 'CA' ? t('home.canYouFindMeAJobAnswer') : t('home.canYouFindMeAJobAnswerUS')
            }
          />
        </li>
        <li>
          <Accordion
            title={t('home.howDoYouAchieve98SuccessRate')}
            description={
              destinationCountry === 'CA'
                ? t('home.howDoYouAchieve98SuccessRateAnswer')
                : t('home.howDoYouAchieve98SuccessRateAnswerUS')
            }
          />
        </li>
        <li>
          <Accordion
            title={t('home.doINeedToProvideAccurateInformation')}
            description={t('home.doINeedToProvideAccurateInformationAnswer')}
          />
        </li>
        <li>
          <Accordion
            title={t('home.howLongDoesItTakeForMeToGetAVisa')}
            description={t('home.howLongDoesItTakeForMeToGetAVisaAnswer')}
          />
        </li>
        <li>
          <Accordion title={t('home.howDoIContactYou')} description={t('home.howDoIContactYouAnswer')} />
        </li>
      </ul>
    </div>
  );
}

import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.SENTRY_LANDING_DSN,
  environment: process.env.STACK,
  release: process.env.RELEASE ?? process.env.AWS_COMMIT_ID,
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

/* This file is used in gatsby-browser.js, so it should not be TypeScript */
/* Feature-detecting localStorage - https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage */
function storageAvailable(type) {
  let storage;
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function getItemFromLocalStorage(key) {
  if (storageAvailable('localStorage')) {
    return localStorage.getItem(key);
  }
  return null;
}

export function setItemToLocalStorage(key, value) {
  if (storageAvailable('localStorage')) {
    localStorage.setItem(key, value);
  }
}

import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from '@/utils/breakpoint';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { TaskList } from './TaskList';
import ExternalLink from '../ui/links/ExternalLink';
import Link from '../ui/links/Link';
import { BannerFreeStudyPermit, BannerGPT } from './Banner';

export function UniversalHomeHero({ destinationCountry = '' }: { destinationCountry?: 'US' | 'CA' | '' }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const { isMobile } = useBreakpoints();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);

  let searchAllVisasUrl = '/visas/';
  if (destinationCountry === 'CA') {
    searchAllVisasUrl = '/visas-canada/list/';
  } else if (destinationCountry === 'US') {
    searchAllVisasUrl = '/visas-us/list/';
  }
  return (
    <section className="relative min-h-[calc(100vh-112px)] bg-brand-sky lg:flex lg:min-h-[calc(100vh-80px)] lg:items-center lg:py-40">
      {destinationCountry === 'US' && (
        <StaticImage
          className="left-0 bottom-0 z-0 h-[calc((100vh-220px)*4/5)] w-[calc(100vw*2/3)] object-none object-right-top lg:h-[calc((100vh-400px)/2)]"
          src="../../assets/img/home/usa_stars.svg"
          alt="USA Stars"
          placeholder="blurred"
          style={{ position: 'absolute' }}
          imgStyle={{
            objectPosition: 'top right',
          }}
        />
      )}
      {destinationCountry === 'CA' && (
        <div className="absolute left-0 bottom-0 min-h-[calc((100vh-80px)*4/5)] w-[calc(100vw*3/4)] bg-[url('../../assets/img/home/canada_maple.svg')] bg-cover bg-right-top bg-no-repeat lg:min-h-[calc((100vh-80px)*7/10)] lg:w-[calc(100vw*5/12)] lg:bg-contain lg:bg-right-bottom" />
      )}
      <div className="container mx-auto px-4  pt-10 lg:flex lg:items-center lg:justify-center lg:pt-0">
        <div className="relative mt-18 lg:mt-0">
          {destinationCountry === 'US' ? <BannerGPT /> : <BannerFreeStudyPermit />}
          <div className="flex flex-col gap-20 lg:flex-row lg:items-center lg:justify-between lg:gap-36 xl:gap-60">
            <div className="px-4 lg:flex-1 lg:shrink-0 lg:px-0">
              <h1 className="break-keep-all max-w-[300px] font-display text-4xl font-bold leading-snug text-white lg:max-w-[460px] lg:text-5xl lg:leading-snug lg:tracking-tight xl:text-7xl xl:leading-tight">
                <Trans i18nKey="home.visaApplicationsMadeSimple" t={t} />
              </h1>
              <p className="break-keep-all mt-11 max-w-[300px] text-sm leading-normal text-white lg:max-w-[400px] lg:text-lg lg:leading-normal xl:text-xl xl:leading-relaxed">
                {t('home.visaApplicationsMadeSimpleDescription')}
              </p>
              <div className="mt-12 grid grid-cols-1 gap-4 text-brand-blue xl:grid-cols-2 xl:gap-6">
                <ExternalLink
                  to={`/eligibility?${queryParams}`}
                  variant="outline"
                  className="relative z-10 w-full border-white bg-brand-sky text-center text-white hover:bg-brand-blue lg:w-auto"
                >
                  {t('common.checkEligibility')}
                </ExternalLink>
                <Link
                  to={searchAllVisasUrl}
                  variant="outline"
                  className="relative z-10 w-full border-white bg-brand-sky text-center text-white hover:bg-brand-blue lg:w-auto"
                >
                  {t('home.searchAllVisas')}
                </Link>
              </div>
            </div>
            {!isMobile && <TaskList />}
          </div>
        </div>
      </div>
    </section>
  );
}

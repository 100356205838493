import { EstimatedTime } from '@/components/pathways/EstimatedTime';
import { PathwayHero } from '@/components/pathways/PathwayHero';
import { ProcessForWork } from '@/components/pathways/ProcessForWork';
import Layout from '@/components/ui/layouts/Layout';
import ExternalLink from '@/components/ui/links/ExternalLink';
import SEO from '@/components/ui/seo/SEO';
import { gTag } from '@/utils/gTag';
import { useQueryParams } from '@/utils/useQueryParams';
import { useTranslation } from 'react-i18next';

export default function Work() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { queryParams } = useQueryParams({ language });
  return (
    <Layout>
      <main className="mt-10 mb-40">
        <SEO
          title={`SuperVisas | ${t('seo.canadianImmigrationPathway')}: ${t('seo.workThenImmigrate')}`}
          description={t('seo.doYouHaveACanadianJobOffer')}
        />
        <PathwayHero title={t('pathways.workThenImmigrate')}>
          <>
            <p>{t('pathways.workThenImmigrateSummary1')}</p>
            <p>{t('pathways.workThenImmigrateSummary2')}</p>
          </>
        </PathwayHero>
        <EstimatedTime totalTime={`1-${t('common.year', { count: 5 })}`}>
          <ul className="space-y-4 text-base font-semibold text-gray-600 lg:text-lg">
            <li className="space-y-1">
              <div>
                {t('pathways.applyForAWorkPermit')}
                <span className="ml-2 text-brand-blue">1-{t('common.month', { count: 12 })}</span>
              </div>
              <p className="text-sm font-normal text-gray-500">{t('pathways.timelineDependsOnWorkPermit')}</p>
            </li>
            <li className="space-y-1">
              <div className="text-base font-semibold text-gray-600 lg:text-lg">
                {t('pathways.acquireCanadianWorkExperience')}
                <span className="ml-2 text-brand-blue">
                  {t('common.month', { count: 6 })} - {t('common.year', { count: 3 })}
                </span>
              </div>
              <p className="text-sm font-normal text-gray-500">{t('pathways.timelineDependsOnWorkPermit')}</p>
            </li>
            <li className="space-y-1">
              <div className="text-base font-semibold text-gray-600 lg:text-lg">
                {t('pathways.applyForImmigration')}
                <span className="ml-2 text-brand-blue">7-{t('common.month', { count: 8 })}</span>
              </div>
              <p className="text-sm font-normal text-gray-500">{t('pathways.timelineDependsOnWorkPermit')}</p>
            </li>
          </ul>
        </EstimatedTime>
        <ProcessForWork />
        <div className="mt-10 mb-20 flex justify-center">
          <ExternalLink
            to={`/applicant/signup?${queryParams}`}
            variant="primary"
            className="w-full text-center lg:w-[160px]"
            onClick={() => gTag('event', 'sign_up', { event_label: 'work' })}
          >
            {t('header.signUp')}
          </ExternalLink>
        </div>
      </main>
    </Layout>
  );
}

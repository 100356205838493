import { useBreakpoints } from '@/utils/breakpoint';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const getLocalizedDate = ({ language, year, month }: { year: number; month: number; language: string }) => {
  const date = new Date(year, month - 1);
  const localizedDate = date.toLocaleDateString(language, { year: 'numeric', month: 'long' });
  return localizedDate;
};

function Article({ title, year, month, url }: { title: string; year: number; month: number; url: string }) {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <a href={url} target="_blank" rel="noreferrer" className="inline-flex flex-col gap-1">
      <div className="text-sm text-slate-500">{getLocalizedDate({ language, year, month })}</div>
      <div className="break-keep-all-kr text-lg font-semibold text-slate-700">{title}</div>
    </a>
  );
}

export function Press() {
  const { t } = useTranslation();

  const { isMobile } = useBreakpoints();
  return (
    <div className="container mx-auto my-30 px-4">
      <h3 className="mx-auto text-center font-display text-3xl font-bold text-brand-blue lg:text-4xl">
        {t('about.press')}
      </h3>
      <h5 className="mt-6 text-lg font-semibold text-slate-700">{t('about.superVisasInTheNews')}</h5>
      <ul
        className="mt-6 flex flex-col items-center gap-10"
        {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      >
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.cisioniTitle')}
            year={2024}
            month={4}
            url="https://www.newswire.ca/news-releases/supervisas-automates-study-permit-with-ai-813281383.html"
          />
          <a
            href="https://www.newswire.ca/news-releases/supervisas-automates-study-permit-with-ai-813281383.html"
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <StaticImage
              className="w-24 sm:w-44"
              alt="CISION"
              src="../../assets/img/about/cision.svg"
              placeholder="blurred"
              layout="fullWidth"
            />
          </a>
        </li>
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.ripplingTitle')}
            year={2023}
            month={11}
            url="https://finance.yahoo.com/news/rippling-connects-supervisas-simplify-employee-140000635.html"
          />
          <a
            href="https://finance.yahoo.com/news/rippling-connects-supervisas-simplify-employee-140000635.html"
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <StaticImage
              className="w-24 sm:w-44"
              alt="rippling"
              src="../../assets/img/partners/rippling.png"
              placeholder="blurred"
              layout="fullWidth"
            />
          </a>
        </li>
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.acceleratorCentreTitle')}
            year={2022}
            month={11}
            url="https://www.linkedin.com/posts/accelerator-centre_waterloo-accelerator-centre-the-story-of-activity-6999372992845668352-gND9"
          />
          <a
            href="https://www.linkedin.com/posts/accelerator-centre_waterloo-accelerator-centre-the-story-of-activity-6999372992845668352-gND9"
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <StaticImage
              className="w-24 sm:w-44"
              width={176}
              alt="accelerator centre"
              src="../../assets/img/partners/acceleratorcentre.png"
              placeholder="blurred"
            />
          </a>
        </li>
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.founderInstituteTitle')}
            year={2022}
            month={10}
            url="https://fi.co/50-CANADA/supervisa-ai"
          />
          <a href="https://fi.co/50-CANADA/supervisa-ai" target="_blank" rel="noreferrer" className="inline-block">
            <StaticImage
              className="w-24 sm:w-44"
              alt="founder institute"
              src="../../assets/img/partners/fi.png"
              placeholder="blurred"
              layout="fullWidth"
            />
          </a>
        </li>
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.technologyInnovatorsTitle')}
            year={2021}
            month={10}
            url="https://www.technology-innovators.com/supervisas/"
          />
          <a
            href="https://www.technology-innovators.com/supervisas/"
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <StaticImage
              className="w-24 sm:w-44"
              width={176}
              alt="Technology Innovators"
              src="../../assets/img/about/technology_innovators.png"
              placeholder="blurred"
            />
          </a>
        </li>
        <li className="flex w-full items-center justify-between gap-6">
          <Article
            title={t('about.velocityTitle')}
            year={2020}
            month={2}
            url="https://velocityincubator.com/2020/02/five-early-stage-startups-take-home-300k-at-26th-velocity-fund-pitch-competition/"
          />
          <a
            href="https://velocityincubator.com/2020/02/five-early-stage-startups-take-home-300k-at-26th-velocity-fund-pitch-competition/"
            target="_blank"
            rel="noreferrer"
            className="inline-block"
          >
            <StaticImage
              className="w-24 sm:w-32 xl:w-40"
              width={160}
              height={57}
              alt="velocity"
              src="../../assets/img/partners/velocity.png"
              placeholder="blurred"
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

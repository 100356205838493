import { useMemo } from 'react';
import Fuse from 'fuse.js';

export function useFuse<T>(list: T[], searchTerm: string, fuseOptions?: Fuse.IFuseOptions<T>) {
  const fuse = useMemo(() => {
    const index = Fuse.createIndex(fuseOptions.keys, list);
    return new Fuse(list, fuseOptions, index);
  }, [list, fuseOptions]);

  const results = useMemo(() => fuse.search(searchTerm), [fuse, searchTerm]);

  return results;
}

import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import HeroAbout from '@/views/about/HeroAbout';
import { OurMission } from '@/views/about/OurMission';
import { Press } from '@/views/about/Press';
import { SuperVisasTeam } from '@/views/about/SuperVisasTeam';
import { useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main>
        <SEO
          title={`SuperVisas | ${t('header.aboutUs')}`}
          description={t('seo.ourMissionIsToProvideTheMostAccurate')}
        />
        <HeroAbout />
        <SuperVisasTeam />
        <OurMission />
        <Press />
      </main>
    </Layout>
  );
}

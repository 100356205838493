import { FAQTopic, FAQSection } from '@/@types/faq';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

interface FAQNavigationProps {
  category: { slug: string; title: string };
  section: FAQSection;
  topic: FAQTopic;
  faqTitle: string;
}

function FAQBreadcrumbs({ category, section, topic, faqTitle }: FAQNavigationProps) {
  const { t } = useTranslation();
  return (
    <div className="mt-12 leading-8 text-gray-900">
      <Link className="hover:underline" to="/faq/">
        {t('header.faq')}
      </Link>{' '}
      <Link className="hover:underline" to={`/faq/?category=${category.slug}`}>
        {' '}
        <ChevronRightIcon className="inline-block h-5 w-5 select-none text-gray-400" /> {category.title}{' '}
      </Link>
      <ChevronRightIcon className="inline-block h-5 w-5 select-none text-gray-400" />{' '}
      <Link className="hover:underline" to={`/faq/?section=${section.slug}`}>
        {section.title}
      </Link>{' '}
      <ChevronRightIcon className="inline-block h-5 w-5 select-none text-gray-400" />{' '}
      <Link className="hover:underline" to={`/faq/${category.slug}/${section.slug}/${topic.slug}`}>
        {topic.title}
      </Link>
      <span>
        {' '}
        <ChevronRightIcon className="inline-block h-5 w-5 select-none text-gray-400" />{' '}
        <span className="text-gray-500">{faqTitle}</span>
      </span>
    </div>
  );
}

export default FAQBreadcrumbs;

import { graphql, PageProps, navigate } from 'gatsby';
import SEO from '@/components/ui/seo/SEO';

import { BlogPosts, BlogFilterState, BlogFilterAction, BlogCategoryWithCountByCountry } from '@/@types/blog';
import { PostList } from '@/components/blog/PostList';
import Layout from '@/components/ui/layouts/Layout';
import { useTranslation } from 'react-i18next';
import { useDeferredValue, useReducer } from 'react';
import { Topics } from '@/components/blog/Topics';

interface BlogData {
  posts: {
    posts: BlogPosts;
    categories: BlogCategoryWithCountByCountry;
  };
  allImageSharp: any;
}
interface PageContext {
  hrefLang: string;
}

function getInitialState({
  params,
  categories,
}: {
  params: URLSearchParams;
  categories: BlogCategoryWithCountByCountry;
}): BlogFilterState {
  if (params.has('topic') && params.has('country')) {
    const topicId = parseInt(params.get('topic').replace('/', ''), 10);
    const country = params.get('country').replace('/', '') as 'BOTH' | 'CA' | 'US';
    const categoryCountry = categories?.[country];
    if (categoryCountry && (topicId === 0 || categoryCountry?.find((category) => category.id === topicId))) {
      return { searchTerm: '', topicId, country };
    }
  }
  return {
    searchTerm: '',
    topicId: 0,
    country: 'BOTH',
  };
}

const Blog = ({
  data: {
    posts: { posts, categories },
  },
  pageContext: { hrefLang },
  location,
}: PageProps<BlogData, PageContext>) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  function updateParams({ topicId, country }: { topicId: number; country: 'BOTH' | 'CA' | 'US' }) {
    params.set('topic', topicId.toString());
    params.set('country', country);
    navigate(`?${params.toString()}`, { replace: true });
  }
  const [state, dispatch] = useReducer((acc: BlogFilterState, action: BlogFilterAction) => {
    switch (action.type) {
      case 'SET_SEARCH_TERM': {
        const newState = {
          ...acc,
          searchTerm: action.payload.toLowerCase(),
          topicId: 0,
        };
        return newState;
      }
      case 'SET_TOPIC_ID': {
        const newState = {
          ...acc,
          topicId: action.payload,
          searchTerm: '',
        };
        updateParams({ topicId: newState.topicId, country: newState.country });
        return newState;
      }
      case 'SET_COUNTRY': {
        const newState = {
          ...acc,
          country: action.payload,
          topicId: 0,
          searchTerm: '',
        };
        updateParams({ topicId: newState.topicId, country: newState.country });
        return newState;
      }
      default:
        return acc;
    }
  }, getInitialState({ params, categories }));
  const { topicId, country } = state;
  const debouncedSearchTerm = useDeferredValue(state.searchTerm);
  return (
    <Layout>
      <main className="wrapper white-content" id="program-landing-page">
        <SEO title={`SuperVisas | ${t('header.blog')}`} description={t('seo.getTheMostUpToDateNewsAboutVisa')} />
        <div className="container mx-auto px-4 pt-16 pb-40 lg:pt-30">
          <h1 className="text-center font-display text-3xl font-bold text-slate-900 lg:text-5xl">
            SuperVisas <span className="text-brand-blue">{t('header.blog')}</span>
          </h1>
          <div className="mt-16 mb-80 flex flex-col-reverse gap-8 lg:mt-30 lg:flex-row lg:gap-12">
            <div className="lg:flex-1">
              <PostList
                posts={posts}
                hrefLang={hrefLang}
                topicId={topicId}
                country={country}
                debouncedSearchTerm={debouncedSearchTerm}
              />
            </div>
            <div className="lg:w-[330px]">
              <Topics categories={categories} posts={posts} filterState={state} dispatch={dispatch} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query BlogTemplatePage($locale: String!) {
    posts(language: { eq: $locale }) {
      id
      categories {
        BOTH {
          id
          name
          count
        }
        CA {
          id
          name
          count
        }
        US {
          id
          name
          count
        }
      }
      posts {
        id
        slug
        title
        image
        thumbnail
        categories {
          id
          name
        }
        country
        plainText
        description
        publishedDate
        author {
          id
          name
          title
          image
        }
      }
    }
  }
`;

import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface CountryBadgeProps {
  country: 'CA' | 'US' | 'BOTH';
}

export function CountryBadge({ country }: CountryBadgeProps) {
  if (country === 'BOTH') {
    return null;
  }
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'rounded px-2.5 py-0.5 text-xs font-medium',
        country === 'US' ? 'bg-blue-100 text-blue-500' : 'bg-red-100 text-red-500',
      )}
    >
      {country === 'US' ? t('common.usa') : t('home.canada')}
    </div>
  );
}

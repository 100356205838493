import { BlogCategoryWithCountByCountry, BlogFilterAction, BlogFilterState, BlogPost } from '@/@types/blog';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Dispatch } from 'react';
import { RadioGroup, RadioGroupItem } from '../ui/RadioGroup';

interface TopicsProps {
  categories: BlogCategoryWithCountByCountry;
  posts: BlogPost[];
  filterState: BlogFilterState;
  dispatch: Dispatch<BlogFilterAction>;
}

export function Topics({ categories, filterState, dispatch, posts }: TopicsProps) {
  const { t } = useTranslation();
  const { searchTerm, topicId, country } = filterState;
  const totalPosts = posts.filter((p) =>
    country === 'BOTH' ? true : p.country === country || p.country === 'BOTH',
  ).length;
  return (
    <>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
        </div>
        <input
          type="search"
          placeholder={t('post.searchBlog')}
          className="form-input mt-1 block w-full rounded-md border-gray-300 p-4 pl-10 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          value={searchTerm}
          onChange={(e) => {
            dispatch({ type: 'SET_SEARCH_TERM', payload: e.target.value });
          }}
        />
      </div>
      <div className="lg:hidden">
        <div className="mt-6 w-full select-none border-b border-gray-200 pb-2 text-slate-400">
          <Trans
            i18nKey="post.byDestinationCountry"
            t={t}
            components={[<span className="font-semibold text-slate-500" />]}
          />
        </div>
        <select
          id="country"
          className="form-select mt-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          onChange={(e) => {
            dispatch({
              type: 'SET_COUNTRY',
              payload: e.target.value as 'BOTH' | 'CA' | 'US',
            });
          }}
        >
          <option value="BOTH">{t('common.all')}</option>
          <option value="CA">{t('home.canada')}</option>
          <option value="US">{t('common.unitedStates')}</option>
        </select>
        <div className="mt-8 block w-full select-none border-b border-gray-200 pb-2 text-slate-400">
          <Trans i18nKey="post.byTopic" t={t} components={[<span className="font-semibold text-slate-500" />]} />
        </div>
        <select
          id="topic"
          className="form-select mt-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          onChange={(e) => {
            dispatch({
              type: 'SET_TOPIC_ID',
              payload: Number(e.target.value),
            });
          }}
        >
          <option value={0}>
            {t('post.allTopics')} ({totalPosts})
          </option>
          {categories[country].map(({ id, name, count }) => (
            <option key={id} value={id}>
              {name} ({count})
            </option>
          ))}
        </select>
      </div>
      <ul className="mt-8 hidden space-y-3 rounded-lg bg-slate-50 p-6 text-slate-800 lg:block">
        <li className="!mb-12 flex flex-col gap-1">
          <RadioGroup
            value={country}
            onValueChange={(v) => {
              dispatch({ type: 'SET_COUNTRY', payload: v } as BlogFilterAction);
            }}
          >
            <div className="block w-full select-none border-b border-gray-200 pb-2 text-slate-400">
              <Trans
                i18nKey="post.byDestinationCountry"
                t={t}
                components={[<span className="font-semibold text-slate-500" />]}
              />
            </div>
            <div className="mt-4 text-slate-800">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="BOTH" id="radio-both" />
                <label htmlFor="radio-both">{t('common.all')}</label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="CA" id="radio-ca" />
                <label htmlFor="radio-ca">{t('home.canada')}</label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="US" id="radio-us" />
                <label htmlFor="radio-us">{t('common.unitedStates')}</label>
              </div>
            </div>
          </RadioGroup>
        </li>
        <li className="w-full select-none border-b border-gray-200 pb-2 text-slate-400">
          <Trans i18nKey="post.byTopic" t={t} components={[<span className="font-semibold text-slate-500" />]} />
        </li>
        <button
          type="button"
          onClick={() => {
            dispatch({ type: 'SET_TOPIC_ID', payload: 0 });
          }}
          className={cn('text-left', topicId === 0 ? 'text-brand-blue' : 'text-slate-800')}
        >
          {t('post.allTopics')}{' '}
          <span
            className={cn(
              'rounded-full p-0.5 align-baseline text-xs font-semibold',
              topicId === 0 ? 'text-pink-600' : 'text-slate-400',
            )}
          >
            {totalPosts}
          </span>
        </button>
        {categories[country].map(({ id, name, count }) => (
          <li key={id}>
            <button
              type="button"
              onClick={() => {
                dispatch({ type: 'SET_TOPIC_ID', payload: id });
              }}
              className={cn('text-left', topicId === id ? 'text-brand-blue' : 'text-slate-800')}
            >
              {name}{' '}
              <span
                className={cn(
                  'rounded-full p-0.5 align-baseline text-xs font-semibold',
                  topicId === id ? 'text-pink-600' : 'text-slate-400',
                )}
              >
                {count}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}

import { useEffect, useState } from 'react';

const popularCanadaVisas = [
  {
    name: 'Visitor Visa',
    country: 'CA',
  },
  {
    name: 'Study Permit',
    country: 'CA',
  },
  {
    name: 'Intra-Company Transferee (ICT) Work Permit',
    country: 'CA',
  },
  {
    name: 'Employer-specific Work Permit',
    country: 'CA',
  },
  {
    name: 'Express Entry',
    country: 'CA',
  },
  {
    name: 'Start-up Visa',
    country: 'CA',
  },
];

const popularUSVisas = [
  {
    name: 'B1/B2 Visitor Visa',
    country: 'US',
  },
  {
    name: 'TN under USMCA',
    country: 'US',
  },
  {
    name: 'L-1A',
    country: 'US',
  },
  {
    name: 'H-1B',
    country: 'US',
  },
  {
    name: 'F-1',
    country: 'US',
  },
  {
    name: 'EB-1A: Extraordinary Ability',
    country: 'US',
  },
];

const popularVisas = [...popularCanadaVisas, ...popularUSVisas];

function getRandomVisa(country: '' | 'US' | 'CA') {
  const visas =
    {
      US: popularUSVisas,
      CA: popularCanadaVisas,
    }[country] || popularVisas;
  const randomIndex = Math.floor(Math.random() * visas.length);
  return visas[randomIndex];
}

export function useRandomVisa({ checked, country }: { checked: number; country: '' | 'US' | 'CA' }) {
  const [visa, setVisa] = useState(
    {
      US: popularUSVisas,
      CA: popularCanadaVisas,
    }[country]?.[0] || popularVisas[0],
  );
  useEffect(() => {
    if (checked === 0) {
      setVisa(getRandomVisa(country));
    }
  }, [checked]);
  return visa;
}

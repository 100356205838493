import { useCountry } from '@/utils/CountryContext';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import { navigate } from 'gatsby';
import Button from '../ui/buttons/Button';

export function GuideToCountrySpecificPages() {
  const { t } = useTranslation();
  const { setCountry } = useCountry();
  const { localizedPath, defaultLang, prefixDefault, locale } = useLocalization();
  return (
    <div className="bg-gray-100 py-10 ring-1 ring-inset ring-gray-200 lg:py-16">
      <div className="container mx-auto flex flex-col items-center px-4">
        <h4 className="w-full max-w-xl  text-center font-display text-lg font-semibold text-gray-600 text-balance lg:text-2xl">
          {t('home.goToOurCountrySpecificPages')}
        </h4>
        <div className="mt-12 flex w-full flex-col gap-4 lg:w-auto lg:flex-row lg:gap-6">
          <Button
            variant="outline"
            className="min-w-[218px] bg-white text-center"
            onClick={() => {
              setCountry('CA');
              navigate(
                localizedPath({
                  defaultLang,
                  prefixDefault,
                  locale,
                  path: '/canada',
                }),
              );
            }}
          >
            {t('common.canadaVisas')}
          </Button>
          <Button
            variant="outline"
            className="min-w-[218px] bg-white text-center"
            onClick={() => {
              setCountry('US');
              navigate(
                localizedPath({
                  defaultLang,
                  prefixDefault,
                  locale,
                  path: '/us',
                }),
              );
            }}
          >
            {t('common.usVisas')}
          </Button>
        </div>
      </div>
    </div>
  );
}

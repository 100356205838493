import { useTranslation } from 'react-i18next';
import { TrustedBy } from '@/components/home/Trustedby';
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import { UniversalHomeHero } from '@/components/home/UniversalHomeHero';
import { USAAndCanada } from '@/components/home/USAAndCanada';
import { GuideToCountrySpecificPages } from '@/components/home/GuideToCountrySpecificPages';
import { TrustBox } from '@/components/home/TrustBox';
import { TaskList } from '@/components/home/TaskList';
import { useBreakpoints } from '@/utils/breakpoint';

const IndexPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <Layout variant="blue">
      <main>
        <SEO
          title={`SuperVisas | ${t('seo.makingImmigrationSimple')}`}
          description={t('seo.theEasiestAndMostAffordable')}
          trustPilot
        />
        <UniversalHomeHero />
        <USAAndCanada />
        {isMobile && <TaskList />}
        <TrustedBy />
        <TrustBox />
        <GuideToCountrySpecificPages />
      </main>
    </Layout>
  );
};

export default IndexPage;

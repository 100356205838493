import { useBreakpoints } from '@/utils/breakpoint';
import { CheckIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import Link from '../ui/links/Link';

export function CanadaSummary() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div
      className="container mx-auto flex flex-col gap-8 lg:flex-row lg:gap-16 lg:px-4"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <StaticImage
        class="flex-1"
        src="../../assets/img/business/city-with-canada-flag.jpg"
        alt="city with Canada flag"
        placeholder="blurred"
        layout="fullWidth"
      />
      <div className="flex flex-1 flex-col justify-center px-4 py-4 lg:px-0 lg:py-8">
        <h3 className="font-display text-2xl font-semibold text-brand-blue lg:text-4xl">{t('home.canada')}</h3>
        <p className="break-keep-all-kr mt-5 text-sm text-gray-600 lg:text-base">{t('programs.canadaDescription')}</p>
        <ul className="mt-7 space-y-3 text-sm text-brand-blue lg:text-base">
          <li className="flex items-start gap-1.5">
            <CheckIcon className="inline-block h-4 w-4 opacity-60 lg:mt-0.5" />
            <span>{t('programs.accessToRegulatedImmigrationConsultant')}</span>
          </li>
          <li className="flex items-start gap-1.5">
            <CheckIcon className="inline-block h-4 w-4 opacity-60 lg:mt-0.5" />
            <span>{t('programs.exclusiveSettlementServices')}</span>
          </li>
        </ul>
        <div className="mt-8 flex flex-col gap-4 lg:flex-row">
          <Link to="/visas-canada/list/" variant="primary" className="inline-block text-center">
            {t('programs.viewCanadaPrograms')}
          </Link>
          <Link to="/visas-canada/" variant="outline" className="inline-block text-center">
            {t('programs.aboutCanada')}
          </Link>
        </div>
      </div>
    </div>
  );
}

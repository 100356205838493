import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  article?: boolean;
  siteUrl?: string;
  twitterUsername?: string;
  author?: string;
  trustPilot?: boolean;
  children?: React.ReactNode;
}

const SEO = ({
  title,
  description,
  image = null,
  article = false,
  siteUrl = `https://${process.env.GATSBY_ROOT_DOMAIN}`,
  twitterUsername = '@super_visas',
  author,
  trustPilot = false,
  children,
}: SEOProps) => {
  const { pathname } = useLocation();
  const ogImage = image || 'https://www.supervisas.com/static/local/img/og-image-default.png';
  return (
    <Helmet title={title}>
      {process.env.GATSBY_ROOT_DOMAIN !== 'supervisas.com' && <meta name="robots" content="noindex, nofollow" />}
      {description && <meta name="description" content={description} />}
      <meta name="image" content={ogImage} />
      {siteUrl && <meta property="og:url" content={`${siteUrl}${pathname}`} />}
      <meta property="og:type" content={article ? 'article' : 'website'} />
      {author && <meta name="author" content={author} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:image" content={ogImage} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      <meta name="twitter:image" content={ogImage} />
      {process.env.GATSBY_FACEBOOK_PIXEL_ID && (
        <meta name="facebook-domain-verification" content="xbebhom5ida8fjsz5bx96ix00chgsx" />
      )}
      {trustPilot && (
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      )}
      {children && children}
    </Helmet>
  );
};
export default SEO;

import { ComponentPropsWithoutRef } from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { cn } from '@/utils/cn';

interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  variant?: 'primary' | 'secondary' | 'text' | 'outline';
  to: string;
  className?: string;
  partiallyActive?: boolean;
}

export default function Link({ variant = 'text', children, to, className = '', partiallyActive = false }: LinkProps) {
  switch (variant) {
    case 'primary':
      return (
        <LocalizedLink
          className={cn(
            'm-0 rounded-lg border-2 border-brand-blue bg-brand-blue px-5 py-2.5 text-base normal-case text-white transition-colors duration-150 hover:border-brand-blue-dark hover:bg-brand-blue-dark focus:border-brand-blue-dark focus:bg-brand-blue-dark active:bg-brand-blue-darkest',
            className,
          )}
          to={to}
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      );
    case 'secondary':
      return (
        <LocalizedLink
          className={cn(
            'm-0 rounded-lg border-2 border-blue-50 bg-blue-50 px-5 py-2.5 text-base normal-case text-brand-blue transition-colors duration-150 hover:border-blue-100 hover:bg-blue-100 hover:text-brand-blue-dark focus:border-blue-100 focus:bg-blue-100 focus:text-brand-blue-dark active:bg-brand-blue-darkest',
            className,
          )}
          to={to}
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      );
    case 'text':
      return (
        <LocalizedLink
          className={cn(
            'm-0 rounded-lg border-0 text-base normal-case text-brand-dark transition-colors duration-150 hover:text-brand-blue focus:text-brand-blue active:text-brand-blue',
            className,
          )}
          to={to}
          activeClassName="!text-brand-blue"
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      );
    case 'outline':
      return (
        <LocalizedLink
          className={cn(
            'm-0 rounded-lg border-2 border-brand-blue px-5 py-2.5 text-base normal-case text-brand-blue transition-colors duration-150 hover:bg-transparent/5 focus:bg-transparent/5',
            className,
          )}
          to={to}
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      );
    default:
      return null;
  }
}

import { ProgramsUS } from '@/@types/program';
import { FilterAction, filterReducer, initialState } from '@/components/program/filterReducer';
import { FilterWrapper } from '@/components/program/FilterWrapper';
import { filterBy, ProgramCards } from '@/components/program/ProgramCards';
import { ProgramFilters } from '@/components/program/ProgramFilters';
import { SearchByVisaName } from '@/components/program/SearchByVisaName';
import Layout from '@/components/ui/layouts/Layout';
import ExternalLink from '@/components/ui/links/ExternalLink';
import SEO from '@/components/ui/seo/SEO';
import { useBreakpoints } from '@/utils/breakpoint';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useLocation } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import { LocalizedLink } from 'gatsby-theme-i18n';
import queryString from 'query-string';
import { useDeferredValue, useReducer, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';

function USProgramHero() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const { isMobile } = useBreakpoints();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  return (
    <div className="flex flex-col gap-6 lg:flex-row lg:items-end lg:justify-between">
      <div className="flex flex-col gap-2">
        <h1 className="font-display text-base font-bold text-brand-blue lg:text-4xl">
          {t('programs.findTheRightUSVisa')}
        </h1>
        <p className="text-gray-500">{t('programs.useOurFilter')}</p>
      </div>
      <ExternalLink
        to={`/eligibility?${queryParams}`}
        variant={isMobile ? 'outline' : 'secondary'}
        className="w-full text-center lg:w-auto"
      >
        {t('common.checkMyEligibility')}
      </ExternalLink>
    </div>
  );
}

export default function USVisasList({
  data: {
    programs: { us_programs: programs },
  },
}: PageProps<{ programs: ProgramsUS }>) {
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const [visaName, setVisaName] = useState('');
  const debouncedVisaName = useDeferredValue(visaName);
  const debouncedState = useDeferredValue(state);
  const [, startTransition] = useTransition();
  const { t } = useTranslation();
  const dispatchWithTransition = (action: FilterAction) => {
    startTransition(() => {
      dispatch(action);
    });
  };

  const filteredPrograms = programs.filter((program) => filterBy(debouncedState, program, debouncedVisaName));
  return (
    <Layout>
      <main className="container mx-auto mt-10 mb-40 px-4">
        <SEO
          title={`SuperVisas | ${t('seo.findTheRightUSVisa')}`}
          description={t('seo.findTheRightUSVisaDescription')}
        />
        <nav className="mb-10 text-gray-700" aria-label="Breadcrumb">
          <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
            <li className="inline-flex items-center">
              <LocalizedLink
                to="/visas/"
                className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
              >
                {t('header.programs')}
              </LocalizedLink>
            </li>
            <li className="inline-flex items-center">
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
              <LocalizedLink
                to="/visas-us/"
                className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 md:ml-1"
              >
                {t('common.unitedStates')}
              </LocalizedLink>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{t('common.list')}</span>
              </div>
            </li>
          </ul>
        </nav>
        <USProgramHero />
        <div className="mt-15 flex flex-col gap-4 lg:flex-row lg:items-start">
          <div className="flex flex-col gap-4">
            <SearchByVisaName className="block w-full" value={visaName} setValue={setVisaName} />
            <FilterWrapper>
              <ProgramFilters state={debouncedState} dispatch={dispatchWithTransition} currency="USD" />
              <div className="px-4 pb-8 text-sm text-gray-500 lg:hidden">
                {t('programs.numberOfVisas', { count: filteredPrograms.length })}
              </div>
            </FilterWrapper>
          </div>
          <ProgramCards filteredPrograms={filteredPrograms} state={debouncedState} dispatch={dispatchWithTransition} />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query programsUS($locale: String!) {
    programs(language: { eq: $locale }) {
      id
      language
      us_programs {
        id
        name
        slug
        program_type
        program_type_name
        plain_text_summary
        country {
          name
          iso_code
        }
        requirements {
          id
          net_worth_min
          job_offer
        }
      }
    }
  }
`;

import { CheckCircleIcon } from '@heroicons/react/24/solid';

interface AnimatedCheckboxProps {
  checked: boolean;
}

export function AnimatedCheckbox({ checked }: AnimatedCheckboxProps) {
  return (
    <>
      {checked && (
        <CheckCircleIcon className="absolute -top-0.5 left-0 z-20 inline-block h-7 w-7 text-green-600 duration-200 ease-in animate-in fade-in lg:h-9 lg:w-9" />
      )}
      <div className="absolute left-0 -top-0.5 z-10 inline-flex h-7 w-7 items-center justify-center lg:h-9 lg:w-9">
        <div className="inline-block h-[22.4px] w-[22.4px] rounded-full border border-gray-400 lg:h-[28.8px] lg:w-[28.8px]" />
      </div>
    </>
  );
}

import { LocalizedLink } from 'gatsby-theme-i18n';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useBreakpoints } from '@/utils/breakpoint';
import { graphql, useStaticQuery } from 'gatsby';
import { ProgramSummary } from '@/@types/ProgramData';
import { CanadaProgramIndex, USProgramIndex, priceMapCA, priceMapUS } from '@/@types/program-index';

const numberFormat = new Intl.NumberFormat('en-CA');
const PROGRAMS_QUERY = graphql`
  query {
    programs(language: { eq: "en" }) {
      id
      canada_programs {
        id
        slug
      }
      us_programs {
        id
        slug
      }
    }
  }
`;

function ProgramCard({
  title,
  description,
  active,
  setActive,
  to,
}: {
  title: string;
  description: string;
  active: boolean;
  setActive: () => void;
  to: string;
}) {
  const { t } = useTranslation();
  return (
    <li>
      <button
        type="button"
        className={cn(
          'flex w-[220px] flex-col justify-between border-2 bg-white p-4 !text-left shadow transition-all duration-300 ease-in-out lg:w-[280px]',
          active ? ' border-brand-blue' : 'border-white',
        )}
        onMouseEnter={setActive}
        onClick={setActive}
      >
        <div>
          <h5 className="text-base font-semibold uppercase text-gray-800 lg:text-xl">{title}</h5>
          <p className="my-2 text-xs font-light text-gray-500 line-clamp-3">{description}</p>
        </div>
        <div className="flex w-full flex-row-reverse text-xs uppercase">
          <LocalizedLink to={to} className="rounded py-1 px-2 text-brand-blue hover:bg-brand-blue hover:text-white">
            {t('common.learnMore')}
          </LocalizedLink>
        </div>
      </button>
    </li>
  );
}

function PriceComparison({
  minAttorneyFee,
  maxAttorneyFee,
  superVisasFee,
  destinationCountry,
}: {
  minAttorneyFee: number;
  maxAttorneyFee: number;
  superVisasFee: number;
  destinationCountry: 'CA' | 'US';
}) {
  const { t } = useTranslation();
  const currency = destinationCountry === 'CA' ? 'CA$' : 'US$';
  return (
    <div className="flex flex-col p-6 lg:relative lg:mx-auto lg:max-w-[760px] lg:flex-row">
      <div className="absolute top-[84px] left-[calc(50%+10px)] hidden h-1 w-[100px] -rotate-[70deg] rounded border-t border-t-gray-300 lg:block" />
      <div className="flex-1">
        <div className="text-sm font-medium text-gray-400 lg:text-base">{t('home.privateImmigrationAttorneyFees')}</div>
        <div className="mt-2">
          <div className="font-display text-xl font-light leading-none text-gray-400 lg:text-3xl">
            {currency}
            <strong className="font-sans-serif font-semibold tabular-nums">
              {numberFormat.format(minAttorneyFee)}
            </strong>{' '}
            ~ {currency}
            <strong className="font-sans-serif font-semibold tabular-nums">
              {numberFormat.format(maxAttorneyFee)}
            </strong>
          </div>
          <div className="mt-2 text-xs text-gray-400">+ {t('common.governmentFees')}</div>
        </div>
      </div>
      <div className="flex-1 text-right lg:pt-6">
        <div className="text-sm font-medium text-gray-900 lg:text-base">{t('home.superVisasFees')}</div>
        <div className="mt-2">
          <div className="font-display text-2xl font-light leading-none text-brand-blue lg:text-4xl">
            {currency}
            <strong className="font-sans-serif font-semibold tabular-nums">{numberFormat.format(superVisasFee)}</strong>
          </div>
          <div className="mt-2 text-xs text-gray-400">+ {t('common.governmentFees')}</div>
        </div>
      </div>
    </div>
  );
}

function CanadaVisaCards({
  active,
  setActive,
  programs,
}: {
  active: CanadaProgramIndex;
  setActive: (index: CanadaProgramIndex) => void;
  programs: ProgramSummary[];
}) {
  const { t } = useTranslation();
  const idToSlugMap = programs.reduce((map, program) => {
    map[program.id] = program.slug;
    return map;
  }, {});
  function getSlugById(id: number) {
    /*
      The fallback is for non-production environments.
      In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
    */
    return idToSlugMap[id] || programs[0].slug;
  }
  return (
    <>
      <ProgramCard
        title={t('home.visitorVisa')}
        description={t('home.visitorVisaDescription')}
        active={active === CanadaProgramIndex.VISITOR_VISA}
        setActive={() => setActive(CanadaProgramIndex.VISITOR_VISA)}
        to={`/visas/${getSlugById(CanadaProgramIndex.VISITOR_VISA)}/`}
      />
      <ProgramCard
        title={t('home.studyPermit')}
        description={t('home.studyPermitDescription')}
        active={active === CanadaProgramIndex.STUDY_PERMIT}
        setActive={() => setActive(CanadaProgramIndex.STUDY_PERMIT)}
        to={`/visas/${getSlugById(CanadaProgramIndex.STUDY_PERMIT)}/`}
      />
      <ProgramCard
        title={t('home.ictWorkPermit')}
        description={t('home.ictWorkPermitDescription')}
        active={active === CanadaProgramIndex.ICT_WORK_PERMIT}
        setActive={() => setActive(CanadaProgramIndex.ICT_WORK_PERMIT)}
        to={`/visas/${getSlugById(CanadaProgramIndex.ICT_WORK_PERMIT)}/`}
      />
      <ProgramCard
        title={t('home.expressEntry')}
        description={t('home.expressEntryDescription')}
        active={active === CanadaProgramIndex.EXPRESS_ENTRY}
        setActive={() => setActive(CanadaProgramIndex.EXPRESS_ENTRY)}
        to={`/visas/${getSlugById(CanadaProgramIndex.EXPRESS_ENTRY)}/`}
      />
    </>
  );
}

function USVisaCards({
  active,
  setActive,
  programs,
}: {
  active: USProgramIndex;
  setActive: (index: USProgramIndex) => void;
  programs: ProgramSummary[];
}) {
  const { t } = useTranslation();
  const idToSlugMap = programs.reduce((map, program) => {
    map[program.id] = program.slug;
    return map;
  }, {});
  function getSlugById(id: number) {
    /*
      The fallback is for non-production environments.
      In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
    */
    return idToSlugMap[id] || programs[0].slug;
  }
  return (
    <>
      <ProgramCard
        title="B-1"
        description={t('home.b1Description')}
        active={active === USProgramIndex.B_1}
        setActive={() => setActive(USProgramIndex.B_1)}
        to={`/visas/${getSlugById(USProgramIndex.B_1)}/`}
      />
      <ProgramCard
        title="TN"
        description={t('home.tnDescription')}
        active={active === USProgramIndex.TN}
        setActive={() => setActive(USProgramIndex.TN)}
        to={`/visas/${getSlugById(USProgramIndex.TN)}/`}
      />
      <ProgramCard
        title="H-1B"
        description={t('home.h1bDescription')}
        active={active === USProgramIndex.H_1B}
        setActive={() => setActive(USProgramIndex.H_1B)}
        to={`/visas/${getSlugById(USProgramIndex.H_1B)}/`}
      />
      <ProgramCard
        title="EB-1A"
        description={t('home.eb1aDescription')}
        active={active === USProgramIndex.EB_1A}
        setActive={() => setActive(USProgramIndex.EB_1A)}
        to={`/visas/${getSlugById(USProgramIndex.EB_1A)}/`}
      />
    </>
  );
}

export function ProgramLinks({ destinationCountry }: { destinationCountry: 'CA' | 'US' }) {
  const { programs } = useStaticQuery(PROGRAMS_QUERY);
  const { t } = useTranslation();
  const [active, setActive] = useState(
    destinationCountry === 'CA' ? CanadaProgramIndex.VISITOR_VISA : USProgramIndex.B_1,
  );
  const price =
    destinationCountry === 'CA'
      ? priceMapCA.get(active as CanadaProgramIndex)
      : priceMapUS.get(active as USProgramIndex);
  const { isMobile } = useBreakpoints();
  return (
    <div className="w-full border-t border-b border-t-gray-200 border-b-gray-200 bg-gray-50 py-10">
      <div
        className="container mx-auto px-4"
        {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      >
        <div className="flex flex-col items-start gap-4 lg:flex-row lg:justify-between">
          <div>
            <h4 className="font-display text-xl font-medium text-brand-blue lg:text-2xl">
              {destinationCountry === 'CA' ? t('home.findTheRightCanadaVisa') : t('programs.findTheRightUSVisa')}
            </h4>
            <p className="font-display text-gray-600">
              {destinationCountry === 'CA' ? t('home.canadaVisas') : t('home.usVisas')}
            </p>
          </div>
          <LocalizedLink
            to={destinationCountry === 'CA' ? '/visas-canada/list/?country=CA' : '/visas-us/list/?country=US'}
            className="text-brand-blue hover:text-brand-blue-dark"
          >
            {t('home.moreVisas')}
            <ChevronRightIcon className="ml-0.5 inline-block h-5 w-5 align-text-bottom" />
          </LocalizedLink>
        </div>
      </div>
      <div
        className="my-10 overflow-x-auto px-4 lg:container lg:mx-auto"
        {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      >
        <ul className="flex min-w-max gap-4 lg:min-w-0 lg:justify-between">
          {destinationCountry === 'CA' ? (
            <CanadaVisaCards
              active={active as CanadaProgramIndex}
              setActive={setActive}
              programs={programs.canada_programs}
            />
          ) : (
            <USVisaCards active={active as USProgramIndex} setActive={setActive} programs={programs.us_programs} />
          )}
        </ul>
      </div>
      <PriceComparison
        minAttorneyFee={price.minAttorneyFee}
        maxAttorneyFee={price.maxAttorneyFee}
        superVisasFee={price.superVisasFee}
        destinationCountry={destinationCountry}
      />
    </div>
  );
}

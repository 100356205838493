import Layout from '@/components/ui/layouts/Layout';
import Link from '@/components/ui/links/Link';
import { ImmigrationStatistics } from '@/components/program/ImmigrationStatistics';
import { ProgramCount } from '@/components/program/ProgramCount';
import { USDescription } from '@/components/program/USDescription';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import SEO from '@/components/ui/seo/SEO';

export default function US() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="pb-40">
        <SEO
          title={`SuperVisas | ${t('seo.usImmigrationSystem')}`}
          description={t('seo.usImmigrationSystemDescription')}
        />
        <StaticImage
          src="../../assets/img/business/usa_flag.jpg"
          alt="Flag of the United States"
          placeholder="blurred"
          layout="fullWidth"
          className="max-h-[420px]"
        />
        <nav className="container mx-auto mt-20 px-4 text-gray-700" aria-label="Breadcrumb">
          <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
            <li className="inline-flex items-center">
              <LocalizedLink
                to="/visas/"
                className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
              >
                {t('header.programs')}
              </LocalizedLink>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">
                  {t('common.unitedStates')}
                </span>
              </div>
            </li>
          </ul>
        </nav>
        <div className="container mx-auto mt-20 flex flex-col gap-8 lg:flex-row lg:gap-16 lg:px-4">
          <div className="flex-1 px-4 lg:px-0">
            <USDescription />
          </div>
          <div className="space-y-8 lg:pt-18">
            <ProgramCount
              title={
                <Trans
                  i18nKey="translation:programs.weSupportProgramsInTheUS"
                  values={{
                    programCount: '10+',
                  }}
                  components={[<span className="text-brand-blue" />]}
                />
              }
              country="US"
            >
              <Link variant="primary" to="/visas-us/list/" className="inline-block">
                {t('programs.viewUSPrograms')}
              </Link>
            </ProgramCount>
            <ImmigrationStatistics
              year={2021}
              study={798977}
              work={1834944}
              visit={10401586}
              immigrate={740002}
              sourceLabel={t('programs.annualFlowReport')}
              sourceUrl="https://www.dhs.gov/sites/default/files/2022-07/2022_0722_plcy_nonimmigrant_fy2021.pdf"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
}

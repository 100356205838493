/*
  Canada
  1. Visitor Visa : Price $325
    competitor price: $625 - $3,250
  35. Study Permit (General): Price $949
    competitor price: $2,400 - $3,750
  25. Intra-Company Transferee (ICT) Work Permit: Price $7990
    competitor price: $10,000 - $15,000
  42. Express Entry: Price $1590
    competitor price: $2,500 - $4,000 
  U.S. 
  342. B-1: Price 249
    competitor price: $500 - $800
  176. TN: Price $749
    competitor price: $1,550 - $2,500
  179. H-1B: Price $1,099
    competitor price: $2,200 - $4,250
  188. EB-1A: Price $4,499
    competitor price: $6,000 - $12,000 
*/

export enum CanadaProgramIndex {
  VISITOR_VISA = 1,
  STUDY_PERMIT = 35,
  ICT_WORK_PERMIT = 25,
  EXPRESS_ENTRY = 42,
  OPEN_WORK_PERMIT = 4,
  LMIA = 28,
  GLOBAL_TALENT_STREAM = 34,
  PARENT_AND_GRANDPARENT_SUPER_VISA = 14,
  EMPLOYER_SPECIFIC_WORK_PERMIT = 23,
  PROVINCIAL_NOMINATION_CLASS = 150,
  SPOUSAL_SPONSORSHIP = 43,
  STARTUP_VISA_PROGRAM = 47,
  FEDERAL_SELF_EMPLOYED_PROGRAM = 40,
  C11_SIGNIFICANT_BENEFIT_WORK_PERMIT = 254,
  CUSMA_T23_T24_WORK_PERMIT = 222,
  ALBERTA_OPPORTUNITY_STREAM = 51,
  SKILLED_WORKERS_IN_MANITOBA_TFW = 71,
  NEW_BRUNSWICK_SKILLED_WORKER_STREAM = 79,
  BRITISH_COLUMBIA_INTERNATIONAL_POST_GRADUATE = 59,
  OINP_MASTERS_GRADUATE_STREAM = 104,
  OINP_PHD_GRADUATE_STREAM = 105,
  C20_RECIPROCAL_EMPLOYMENT_WORK_PERMIT = 255,
}
export enum USProgramIndex {
  B_1 = 342,
  TN = 176,
  H_1B = 179,
  EB_1A = 188,
  L_1 = 175,
  O_1 = 291,
  EB_2_NIW = 236,
  I_130 = 290,
}

export const priceMapCA = new Map([
  [CanadaProgramIndex.VISITOR_VISA, { minAttorneyFee: 625, maxAttorneyFee: 3250, superVisasFee: 325 }],
  [CanadaProgramIndex.STUDY_PERMIT, { minAttorneyFee: 2400, maxAttorneyFee: 3750, superVisasFee: 949 }],
  [CanadaProgramIndex.ICT_WORK_PERMIT, { minAttorneyFee: 10000, maxAttorneyFee: 15000, superVisasFee: 7990 }],
  [CanadaProgramIndex.EXPRESS_ENTRY, { minAttorneyFee: 2500, maxAttorneyFee: 4000, superVisasFee: 1590 }],
]);
export const priceMapUS = new Map([
  [USProgramIndex.B_1, { minAttorneyFee: 500, maxAttorneyFee: 800, superVisasFee: 249 }],
  [USProgramIndex.TN, { minAttorneyFee: 1550, maxAttorneyFee: 2500, superVisasFee: 749 }],
  [USProgramIndex.H_1B, { minAttorneyFee: 2200, maxAttorneyFee: 4250, superVisasFee: 1099 }],
  [USProgramIndex.EB_1A, { minAttorneyFee: 6000, maxAttorneyFee: 12000, superVisasFee: 4499 }],
]);

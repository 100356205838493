import Layout from '@/components/ui/layouts/Layout';
import Link from '@/components/ui/links/Link';
import SEO from '@/components/ui/seo/SEO';
import { useBreakpoints } from '@/utils/breakpoint';
import { CheckBadgeIcon, CurrencyDollarIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

function Hero() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="text-center">
        <h1 className="font-display text-3xl font-bold lg:text-4xl 2xl:text-5xl">
          {t('partner.empoweringYourBusinessWithVisaSupport')}
        </h1>
        <p className="break-keep-all-kr prose my-6 mx-auto text-gray-500 text-balance">
          {t('partner.superVisasMakesTheVisaProcessEasy')}
        </p>
      </div>
    </div>
  );
}

function Features() {
  const { t } = useTranslation();
  return (
    <ul className="container mx-auto flex flex-col gap-8 px-4 text-gray-500 lg:flex-row lg:justify-around">
      <li>
        <h4 className="flex items-center gap-1 font-display text-xl font-semibold text-brand-blue lg:text-2xl">
          <HandThumbUpIcon className="inline-block h-6 w-6 lg:h-7 lg:w-7" />
          <span>{t('partner.easyToUse')}</span>
        </h4>
        <p className="break-keep-all-kr mt-2 lg:max-w-[32ch]">{t('partner.easyToUseDescription')}</p>
      </li>
      <li>
        <h4 className="flex items-center gap-1 font-display text-xl font-semibold text-brand-blue lg:text-2xl">
          <CurrencyDollarIcon className="inline-block h-6 w-6 lg:h-7 lg:w-7" />
          <span>{t('partner.reducedCost')}</span>
        </h4>
        <p className="break-keep-all-kr mt-2 lg:max-w-[32ch]">{t('partner.reducedCostDescription')}</p>
      </li>
      <li>
        <h4 className="flex items-center gap-1 font-display text-xl font-semibold text-brand-blue lg:text-2xl">
          <CheckBadgeIcon className="inline-block h-6 w-6 lg:h-7 lg:w-7" />
          <span>{t('partner.successRate')}</span>
        </h4>
        <p className="break-keep-all-kr mt-2 lg:max-w-[32ch]">{t('partner.successRateDescription')}</p>
      </li>
    </ul>
  );
}

function PartnerLink({
  children,
  title,
  description,
  to,
}: {
  children: React.ReactNode;
  title: string;
  description: string;
  to: string;
}) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <li
      className="flex w-full flex-col gap-8 lg:inline-flex lg:w-auto lg:flex-row lg:items-center lg:gap-12"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <div className="w-full lg:w-[330px]">{children}</div>
      <div className="flex w-full flex-col items-start justify-between gap-4 lg:h-[200px] lg:w-[480px] lg:gap-0 lg:py-2">
        <div>
          <h4 className="font-display text-2xl font-bold text-gray-800">{title}</h4>
          <p className="break-keep-all-kr mt-2">{description}</p>
        </div>
        <Link variant="outline" className="mt-2 inline-block w-full text-center lg:w-auto" to={to}>
          {t('common.learnMore')}
        </Link>
      </div>
    </li>
  );
}

function PartnerLinks() {
  const { t } = useTranslation();
  return (
    <ul className="container mx-auto flex flex-col items-start gap-20 px-4 text-gray-500 lg:items-center lg:gap-12">
      <PartnerLink
        title={t('partner.forEmployers')}
        description={t('partner.forEmployersDescription')}
        to="/partners/employers/"
      >
        <StaticImage
          src="../../assets/img/partner/employers.jpg"
          alt="employers"
          placeholder="blurred"
          layout="fullWidth"
        />
      </PartnerLink>
      <PartnerLink
        title={t('partner.forSchools')}
        description={t('partner.forSchoolsDescription')}
        to="/partners/schools/"
      >
        <StaticImage
          src="../../assets/img/partner/schools.jpg"
          alt="schools"
          placeholder="blurred"
          layout="fullWidth"
        />
      </PartnerLink>
      <PartnerLink
        title={t('partner.forAccelerators')}
        description={t('partner.forAcceleratorsDescription')}
        to="/partners/accelerators/"
      >
        <StaticImage
          src="../../assets/img/partner/accelerators.jpg"
          alt="accelerators"
          placeholder="blurred"
          layout="fullWidth"
        />
      </PartnerLink>
      <PartnerLink
        title={t('partner.forAgencies')}
        description={t('partner.forAgenciesDescription')}
        to="/partners/agencies/"
      >
        <StaticImage
          src="../../assets/img/partner/agencies.jpg"
          alt="agencies"
          placeholder="blurred"
          layout="fullWidth"
        />
      </PartnerLink>
    </ul>
  );
}

export default function Partners() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="mt-10 mb-20">
        <SEO title={`SuperVisas | ${t('header.partners')}`} description={t('seo.superVisasMakesTheVisaProcessEasy')} />
        <div className="space-y-22 pb-22">
          <Hero />
          <Features />
          <PartnerLinks />
        </div>
      </main>
    </Layout>
  );
}

import { useTranslation } from 'react-i18next';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ProgramSummary } from '@/@types/program';
import { FilterAction, FilterState } from './filterReducer';
import { ProgramCard } from './ProgramCard';
import Button from '../ui/buttons/Button';

interface ProgramCardsProps {
  filteredPrograms: ProgramSummary[];
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
}

function matchWithProgramType({ programTypes }: FilterState, program: ProgramSummary) {
  return programTypes.length === 0 || programTypes.includes(program.program_type);
}

function matchWithJobOfferNeeded({ jobOfferNeeded }: FilterState, program: ProgramSummary) {
  if (jobOfferNeeded === undefined) {
    return true;
  }
  // When requirements are empty, we assume that the program doesn't require a job offer.
  if (program.requirements.length === 0) {
    return jobOfferNeeded === false;
  }
  return program.requirements.some((requirement) => requirement.job_offer === jobOfferNeeded);
}

function matchWithLanguageAbility({ languageAbility }: FilterState, program: ProgramSummary) {
  /* If county is US, language ability filter is not used */
  if (languageAbility === undefined || program.requirements.length === 0 || program.country.iso_code === 'US') {
    return true;
  }
  return program.requirements.some(
    ({ language_min: languageMin }) => languageMin === null || languageAbility >= languageMin.reading,
  );
}

function matchWithNetWorth({ moneyAvailable }: FilterState, program: ProgramSummary) {
  if (moneyAvailable === undefined || program.requirements.length === 0) {
    return true;
  }
  return program.requirements.some(
    ({ net_worth_min: netWorthMin }) => netWorthMin === null || moneyAvailable >= netWorthMin * 1000,
  );
}

function matchWithVisaName(visaName: string, program: ProgramSummary) {
  if (visaName.trim() === '') {
    return true;
  }
  return program.name.toLowerCase().includes(visaName.toLowerCase());
}

export function filterBy(state: FilterState, program: ProgramSummary, visaName: string) {
  return (
    matchWithProgramType(state, program) &&
    matchWithJobOfferNeeded(state, program) &&
    matchWithLanguageAbility(state, program) &&
    matchWithNetWorth(state, program) &&
    matchWithVisaName(visaName, program)
  );
}

export function ProgramCards({ filteredPrograms, state, dispatch }: ProgramCardsProps) {
  const { t } = useTranslation();
  const { jobOfferNeeded } = state;
  if (filteredPrograms.length === 0) {
    return (
      <div className="mt-10 flex w-full flex-col items-center lg:w-auto lg:flex-1">
        <DocumentMagnifyingGlassIcon className="h-10 w-10 text-gray-400 lg:h-20 lg:w-20" />
        <h2 className="mt-4 text-base font-semibold text-gray-400 lg:text-4xl">{t('faq.noResults')}</h2>
        <p className="my-8 text-sm text-gray-400">{t('programs.tryAdjustingYourFilters')}</p>
        <Button variant="secondary" onClick={() => dispatch({ type: 'RESET' })}>
          {t('common.resetFilters')}
        </Button>
      </div>
    );
  }
  return (
    <div className="lg:flex-1">
      <ul className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {filteredPrograms.map((program) => (
          <ProgramCard program={program} key={program.id} jobOfferNeeded={jobOfferNeeded} />
        ))}
      </ul>
    </div>
  );
}

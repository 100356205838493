import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { FAQ } from '@/@types/faq';
import Button from '@/components/ui/buttons/Button';

interface FAQAnswerProps {
  faq: FAQ;
}

function FAQAnswer({ faq }: FAQAnswerProps) {
  // TODO: send user's feedback to server
  const [isFeedbackAnswered, setIsFeedbackAnswered] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="mt-8 w-full">
      <h1 className="mb-4 font-display text-3xl font-semibold text-balance lg:text-4xl lg:leading-tight">
        {faq.title}
      </h1>
      <ReactMarkdown className="prose my-12 text-gray-600">{faq.answer}</ReactMarkdown>
      {isFeedbackAnswered ? (
        <div className="mb-16 text-brand-dark-light lg:mb-36">{t('faq.feedback')}</div>
      ) : (
        <div className="mb-16 lg:mb-36">
          <div className="mb-4 text-brand-dark-light">{t('faq.wasHelpful')}</div>
          <Button className="mr-4" onClick={() => setIsFeedbackAnswered(true)}>
            {t('faq.yes')}
          </Button>
          <Button variant="secondary" onClick={() => setIsFeedbackAnswered(true)}>
            {t('faq.no')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default FAQAnswer;

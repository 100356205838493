import { useLocation } from '@reach/router';
import queryString from 'query-string';

export function useQueryParams({ language }: { language: string }) {
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  return { queryParams: queryString.stringify(parsed) };
}

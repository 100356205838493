import { FlagAU } from '@/components/ui/flags/FlagAU';
import { FlagCA } from '@/components/ui/flags/FlagCA';
import { FlagCN } from '@/components/ui/flags/FlagCN';
import { FlagGB } from '@/components/ui/flags/FlagGB';
import { FlagUS } from '@/components/ui/flags/FlagUS';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'react-i18next';

function Profile({ name, children, picture }: { name: string; children: React.ReactNode; picture: React.ReactNode }) {
  return (
    <div className="flex flex-col items-center gap-1">
      {picture}
      <div className="mt-2 text-xl font-medium text-brand-blue">{name}</div>
      <div className="flex justify-center gap-2">{children}</div>
    </div>
  );
}

export function SuperVisasTeam() {
  const { t } = useTranslation();
  return (
    <div className="my-20 border-t border-b border-gray-200 pt-10 pb-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="font-display text-2xl font-semibold lg:text-4xl">
          <Trans i18nKey="about.superVisasTeam" components={[<span className="text-brand-blue" />]} t={t} />
        </h2>
        <div className="mt-20 grid grid-cols-1 gap-10 sm:grid-cols-3">
          <Profile
            name="Alec"
            picture={
              <StaticImage
                src="../../assets/img/about/profile/Alec.png"
                layout="fixed"
                placeholder="blurred"
                width={120}
                height={120}
                alt="Alec"
              />
            }
          >
            <FlagCA />
            <FlagGB />
            <FlagAU />
          </Profile>
          <Profile
            name="Joe"
            picture={
              <StaticImage
                src="../../assets/img/about/profile/Joe.png"
                layout="fixed"
                placeholder="blurred"
                width={120}
                height={120}
                alt="Joe"
              />
            }
          >
            <FlagCA />
            <FlagCN />
          </Profile>
          <Profile
            name="Spencer"
            picture={
              <StaticImage
                src="../../assets/img/about/profile/Spencer.png"
                layout="fixed"
                placeholder="blurred"
                width={120}
                height={120}
                alt="Spencer"
              />
            }
          >
            <FlagUS />
            <FlagCA />
          </Profile>
        </div>
      </div>
    </div>
  );
}

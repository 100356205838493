import { Requirement } from '@/@types/program';
import { useTranslation } from 'react-i18next';
import { ProgramBadge } from './ProgramBadge';

interface JobOfferNeededBadgeProps {
  requirements: Requirement[];
  jobOfferNeeded: boolean | undefined;
}

export function JobOfferNeededBadge({ requirements, jobOfferNeeded }: JobOfferNeededBadgeProps) {
  const { t } = useTranslation();
  if (requirements.length === 0 || jobOfferNeeded === false) {
    return null;
  }
  const jobOfferMayNeeded = requirements.some((requirement) => requirement.job_offer === true);
  if (!jobOfferMayNeeded) {
    return null;
  }
  return <ProgramBadge type="text" name={t('programs.jobOfferNeeded')} />;
}

export function Radio({
  id,
  label,
  name,
  checked,
  onChange,
  title,
}: {
  id: string;
  label: string;
  name: string;
  checked: boolean;
  onChange: () => void;
  title?: string;
}) {
  return (
    <div className="inline-flex items-center py-0.5">
      <input
        id={id}
        type="radio"
        className="form-radio border-gray-300 text-brand-blue focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:ring-offset-0"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="ml-2 text-sm text-gray-600">
        {label}
      </label>
      {title && (
        <span title={title} className="ml-2 select-none text-sm font-light text-gray-500">
          &#9432;
        </span>
      )}
    </div>
  );
}

export function IconQuoteLeft({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M0.907566 15.1703C0.302524 12.5556 0 10.2862 0 8.36217C0 5.59945 0.655464 3.52741 1.96639 2.14604C3.27731 0.715348 5.19328 0 7.71429 0V2.96006C6.40336 2.96006 5.44538 3.3054 4.84034 3.99608C4.28572 4.68676 4.0084 5.72278 4.0084 7.10414V8.80617H7.78991V15.1703H0.907566ZM11.1176 15.1703C10.5126 12.5556 10.2101 10.2862 10.2101 8.36217C10.2101 5.59945 10.8656 3.52741 12.1765 2.14604C13.4874 0.715348 15.4034 0 17.9244 0V2.96006C16.6134 2.96006 15.6555 3.3054 15.0504 3.99608C14.4958 4.68676 14.2185 5.72278 14.2185 7.10414V8.80617H18V15.1703H11.1176Z"
        fill="#828282"
      />
    </svg>
  );
}

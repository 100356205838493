import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import RightArrow from './RightArrow';

interface FAQTopicsProps {
  categorySlug: string;
  sectionSlug: string;
  topics: { slug: string; title: string }[];
}

function FAQTopics({ categorySlug, sectionSlug, topics }: FAQTopicsProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full divide-y divide-x-0 divide-solid divide-brand-gray px-4 lg:w-3/4 lg:px-0">
      {topics.length ? (
        topics.map((topic) => (
          <div className="py-8 text-lg font-semibold lg:text-xl" key={topic.slug}>
            <Link className="text-brand-dark" to={`/faq/${categorySlug}/${sectionSlug}/${topic.slug}/`}>
              <RightArrow />
              {topic.title}
            </Link>
          </div>
        ))
      ) : (
        <div className="py-8 text-lg font-semibold text-brand-dark lg:text-xl">{t('faq.noSections')}</div>
      )}
    </div>
  );
}

export default FAQTopics;

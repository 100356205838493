import { FAQ, FAQTopic, FAQSection } from '@/@types/faq';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import RightArrow from './RightArrow';

interface FAQListProps {
  faqList: FAQ[];
  faqTopic: FAQTopic;
  category: { slug: string; title: string };
  section: FAQSection;
}

function FAQList({ faqList, faqTopic, category, section }: FAQListProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-16 bg-brand-gray-light py-16">
      <div className="container mx-auto px-4">
        <div className="mb-8 font-display text-2xl font-bold text-brand-dark">{t('faq.questionsOnTopic')}</div>
        {faqList.length ? (
          <div className="divide-brand-grey divide-y divide-x-0 divide-solid">
            {faqList.map((faq) => (
              <div key={`faq-item-${faq.slug}`} className="py-8 text-lg font-semibold lg:text-xl">
                <Link
                  className="text-brand-dark"
                  to={`/faq/${category.slug}/${section.slug}/${faqTopic.slug}/${faq.slug}/`}
                >
                  <RightArrow />
                  {faq.title}
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="font-semibold">{t('faq.noOtherQuestions')}</div>
        )}
      </div>
    </div>
  );
}

export default FAQList;

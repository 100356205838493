import { Trans, useTranslation } from 'react-i18next';

export function HeroPathways() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto my-20">
      <h1 className="mx-auto text-center font-display text-3xl font-bold text-gray-700 lg:text-5xl">
        <Trans i18nKey="pathways.wayForwardForEveryone" t={t} components={[<span className="text-brand-blue" />]} />
      </h1>
      <div className="prose mx-auto mt-12 text-gray-600 lg:max-w-[813px]">
        <p>{t('pathways.wayForwardForEveryoneDescription1')}</p>
        <p>{t('pathways.wayForwardForEveryoneDescription2')}</p>
        <p>{t('pathways.wayForwardForEveryoneDescription3')}</p>
      </div>
    </div>
  );
}

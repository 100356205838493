import { useTranslation } from 'react-i18next';

export default function HeroPricing() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto my-20 px-4 text-center">
      <h1 className="font-display text-3xl font-bold lg:text-5xl">{t('pricing.visaPricing')}</h1>
      <div className="prose mx-auto mt-4">
        <p className="break-keep-all-kr text-balance">{t('pricing.superVisasMakesTheVisaProcess')}</p>
      </div>
    </div>
  );
}

import {
  CheckIcon,
  ClipboardDocumentListIcon,
  CpuChipIcon,
  GlobeAltIcon,
  KeyIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { cn } from '@/utils/cn';
import Link from '../ui/links/Link';

function Feature({ title, description, children }) {
  return (
    <li className="flex items-start gap-2">
      <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-blue-50">{children}</div>
      <div className="max-w-[400px]">
        <dt className="text-lg font-medium leading-6 text-slate-900">{title}</dt>
        <dd className="break-keep-all-kr mt-1 text-base text-slate-600">{description}</dd>
      </div>
    </li>
  );
}

export function RipplingIntegration() {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);
  return (
    <div id="rippling-integration">
      <div className="container mx-auto mt-20 mb-40 px-4">
        <div>
          <div className="max-w-[600px] space-y-4">
            <div className="font-medium text-brand-sky">
              {t('rippling.ripplingIntegration')}
              <button
                type="button"
                className={`p-1 ${copied ? 'text-green-600' : 'text-brand-sky'}`}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      'https://www.supervisas.com/partners/employers#rippling-integration',
                    );
                    setCopied(true);
                  } catch (err) {
                    /* eslint-disable-next-line no-console */
                    console.error('Failed to copy text: ', err);
                  }
                }}
              >
                <LinkIcon
                  className={cn(
                    'inline-block text-brand-sky transition-all duration-700 ease-in-out',
                    copied ? 'h-0 w-0' : 'h-4 w-4',
                  )}
                />
                <CheckIcon
                  className={cn(
                    'inline-block text-green-600 transition-all duration-700 ease-in-out',
                    copied ? 'h-4 w-4' : 'h-0 w-0',
                  )}
                />
                {copied && (
                  <span
                    className={cn(
                      'inline-block text-green-600 transition-opacity duration-700 ease-in-out',
                      copied ? 'opacity-100' : 'opacity-0',
                    )}
                  >
                    {t('common.copied')}
                  </span>
                )}
              </button>
            </div>
            <h4 className="font-display text-4xl font-semibold  text-slate-900 text-balance">
              {t('rippling.ripplingIntegrationTitle')}
            </h4>
            <p className="break-keep-all-kr text-base text-slate-600 text-balance">
              {t('rippling.ripplingIntegrationDescription')}
            </p>
          </div>
          <ul className="mt-10 mb-16 grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-4">
            <Feature
              title={t('rippling.ripplingIntegrationFeature1Title')}
              description={t('rippling.ripplingIntegrationFeature1Description')}
            >
              <GlobeAltIcon className="h-6 w-6 text-blue-600" />
            </Feature>
            <Feature
              title={t('rippling.ripplingIntegrationFeature2Title')}
              description={t('rippling.ripplingIntegrationFeature2Description')}
            >
              <ClipboardDocumentListIcon className="h-6 w-6 text-blue-600" />
            </Feature>
            <Feature
              title={t('rippling.ripplingIntegrationFeature3Title')}
              description={t('rippling.ripplingIntegrationFeature3Description')}
            >
              <KeyIcon className="h-6 w-6 text-blue-600" />
            </Feature>
            <Feature
              title={t('rippling.ripplingIntegrationFeature4Title')}
              description={t('rippling.ripplingIntegrationFeature4Description')}
            >
              <CpuChipIcon className="h-6 w-6 text-blue-600" />
            </Feature>
          </ul>
        </div>
        <Link variant="primary" to="/partners/employers/rippling-integration/">
          {t('rippling.getStartedWithRipplingIntegration')}
        </Link>
      </div>
    </div>
  );
}

import { ProgramSummary } from '@/@types/program';
import { cn } from '@/utils/cn';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useLocalization } from 'gatsby-theme-i18n';
import { useReducer, useState, useDeferredValue, useTransition } from 'react';
import equal from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { ProgramBadge } from '../program/ProgramBadge';
import { CheckBox } from '../ui/inputs/CheckBox';
import { filterReducer, FilterState, initialState } from './filterReducer';
import { SearchByVisaName } from '../program/SearchByVisaName';
import { sortByNameOrPrice, sortReducer, SortButton } from './Sort';

const numberFormat = new Intl.NumberFormat('en-CA');

function matchWithProgramType({ programTypes }: FilterState, program: ProgramSummary) {
  return programTypes.length === 0 || programTypes.includes(program.program_type);
}

function filterBy(state: FilterState, program: ProgramSummary) {
  return matchWithProgramType(state, program);
}

function filterByVisaName(visaName: string, search: string) {
  return search.length === 0 || visaName.toLowerCase().includes(search.toLowerCase());
}

const PROGRAMS_QUERY = graphql`
  query {
    programs(language: { eq: "en" }) {
      id
      canada_programs {
        id
        slug
      }
      us_programs {
        id
        slug
      }
    }
  }
`;

export function PricingTableCanada({
  generalPrograms,
  provincialNomineePrograms,
  familySponsorshipPrograms,
}: {
  generalPrograms: ProgramSummary[];
  provincialNomineePrograms: ProgramSummary[];
  familySponsorshipPrograms: ProgramSummary[];
}) {
  const [, startTransition] = useTransition();
  const [visaName, setVisaName] = useState('');
  const deferredVisaName = useDeferredValue(visaName);
  const { localizedPath, defaultLang, prefixDefault, locale } = useLocalization();
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, {
    sortBy: 'default',
    sortDirection: 'default',
  });
  const { t } = useTranslation();
  const [showProvincialNomineePrograms, setShowProvincialNomineePrograms] = useState(false);
  const [showFamilySponsorshipPrograms, setShowFamilySponsorshipPrograms] = useState(false);
  const filteredGeneralPrograms = generalPrograms
    .filter((program) => filterBy(state, program) && filterByVisaName(program.name, deferredVisaName))
    .sort((a, b) => sortByNameOrPrice(sortState, a, b));
  const filteredProvincialNomineePrograms = provincialNomineePrograms
    .filter((program) => filterBy(state, program) && filterByVisaName(program.name, deferredVisaName))
    .sort((a, b) => sortByNameOrPrice(sortState, a, b));
  const filteredFamilySponsorshipPrograms = familySponsorshipPrograms
    .filter((program) => filterBy(state, program) && filterByVisaName(program.name, deferredVisaName))
    .sort((a, b) => sortByNameOrPrice(sortState, a, b));
  const { programTypes } = state;

  const {
    programs: { canada_programs: canadaPrograms },
  } = useStaticQuery(PROGRAMS_QUERY);
  const idToCanadaSlugMap = canadaPrograms.reduce((acc, { id: programId, slug }) => {
    acc[programId] = slug;
    return acc;
  }, {});
  /*
    The fallback is for non-production environments.
    In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
  */
  function getCanadaSlugById(id: number) {
    return idToCanadaSlugMap[id] || canadaPrograms[0].slug;
  }
  return (
    <>
      <div className="container mx-auto flex flex-col gap-4 px-4 lg:flex-row lg:items-start lg:gap-8">
        <div className="lg:w-[300px]">
          <SearchByVisaName
            wrapperClassName="w-full mb-4"
            className="block w-full"
            value={deferredVisaName}
            setValue={setVisaName}
          />
          <div className="rounded-lg bg-gray-100 p-4 shadow lg:px-6 lg:py-8">
            <div className="flex flex-row-reverse">
              <button
                type="button"
                className="-mr-2 -mt-2 rounded-md p-2 font-medium uppercase text-brand-blue hover:bg-brand-blue-light focus:text-brand-blue-dark disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
                onClick={() => dispatch({ type: 'RESET' })}
                disabled={equal(state, initialState)}
              >
                {t('common.clear')}
              </button>
            </div>
            <div className="flex flex-col gap-6">
              <div>
                <div className="mb-1 text-xs font-medium uppercase text-gray-500">{t('programs.wantTo')}</div>
                <CheckBox
                  id="study"
                  label={t('program.study')}
                  name="study"
                  checked={programTypes.includes('study')}
                  onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'study' })}
                />
                <CheckBox
                  id="visit"
                  label={t('program.visit')}
                  name="visit"
                  checked={programTypes.includes('visitor')}
                  onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'visitor' })}
                />
                <CheckBox
                  id="work"
                  label={t('program.work')}
                  name="work"
                  checked={programTypes.includes('work')}
                  onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'work' })}
                />
                <CheckBox
                  id="immigrate"
                  label={t('program.immigrate')}
                  name="immigrate"
                  checked={programTypes.includes('immigrate')}
                  onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'immigrate' })}
                />
                <CheckBox
                  id="additional_services"
                  label={t('programType.additional_services')}
                  name="additional_services"
                  checked={programTypes.includes('additional_services')}
                  onChange={() => dispatch({ type: 'SET_PROGRAM_TYPES', payload: 'additional_services' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="text-left font-medium uppercase text-brand-sky">
                <th className="p-4">
                  <SortButton
                    by="name"
                    sortState={sortState}
                    onClick={() => {
                      startTransition(() => {
                        sortDispatch({
                          type: 'SORT_BY',
                          payload: 'name',
                        });
                      });
                    }}
                  >
                    {t('common.visa')}
                  </SortButton>
                </th>
                <th className="p-4 text-right">
                  <SortButton
                    by="price"
                    sortState={sortState}
                    onClick={() => {
                      startTransition(() => {
                        sortDispatch({
                          type: 'SORT_BY',
                          payload: 'price',
                        });
                      });
                    }}
                  >
                    {`${t('common.standard')} (CAD)*`}
                  </SortButton>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredGeneralPrograms.map((program) => (
                <tr
                  key={program.id}
                  className="cursor-pointer odd:bg-gray-50 hover:ring hover:ring-inset hover:ring-brand-sky/20"
                  onClick={() =>
                    navigate(
                      localizedPath({
                        defaultLang,
                        prefixDefault,
                        locale,
                        path: `/visas/${getCanadaSlugById(program.id)}`,
                      }),
                    )
                  }
                >
                  <td className="p-4 text-gray-600">
                    {program.name}{' '}
                    <ProgramBadge type={program.program_type} name={t(`programType.${program.program_type}`)} />
                  </td>
                  <td className="p-4 text-right">
                    <span className="mr-0.5 font-light">$</span>
                    <span className="font-medium tabular-nums">{numberFormat.format(program.price.amount / 100)}</span>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={2}>
                  <button
                    type="button"
                    className="flex w-full items-center justify-between bg-gray-500 p-4 text-white"
                    onClick={() => setShowProvincialNomineePrograms((previous) => !previous)}
                  >
                    <span className="flex items-center">
                      <ChevronRightIcon
                        className={cn('mr-2 h-4 w-4 transition-all delay-100 ease-in-out', {
                          'rotate-90': showProvincialNomineePrograms,
                        })}
                      />
                      <span>{t('pricing.provincialNomineePrograms')}</span>
                    </span>
                    <span>{t('pricing.numberOfVisas', { count: filteredProvincialNomineePrograms.length })}</span>
                  </button>
                </td>
              </tr>
              {showProvincialNomineePrograms &&
                filteredProvincialNomineePrograms.map((program) => (
                  <tr
                    key={program.id}
                    className="cursor-pointer odd:bg-gray-50 hover:ring hover:ring-inset hover:ring-brand-sky/20"
                    onClick={() =>
                      navigate(
                        localizedPath({
                          defaultLang,
                          prefixDefault,
                          locale,
                          path: `/visas/${getCanadaSlugById(program.id)}`,
                        }),
                      )
                    }
                  >
                    <td className="p-4 pl-10 text-gray-600">
                      {program.name}{' '}
                      <ProgramBadge type={program.program_type} name={t(`programType.${program.program_type}`)} />
                    </td>
                    <td className="p-4 text-right">
                      <span className="mr-0.5 font-light">$</span>
                      <span className="font-medium tabular-nums">
                        {numberFormat.format(program.price.amount / 100)}
                      </span>
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={2}>
                  <button
                    type="button"
                    className="flex w-full items-center justify-between bg-gray-500 p-4 text-white"
                    onClick={() => setShowFamilySponsorshipPrograms((previous) => !previous)}
                  >
                    <span className="flex items-center">
                      <ChevronRightIcon
                        className={cn('mr-2 h-4 w-4 transition-all delay-100 ease-in-out', {
                          'rotate-90': showFamilySponsorshipPrograms,
                        })}
                      />
                      <span>{t('pricing.familySponsorship')}</span>
                    </span>
                    <span>{t('pricing.numberOfVisas', { count: filteredFamilySponsorshipPrograms.length })}</span>
                  </button>
                </td>
              </tr>
              {showFamilySponsorshipPrograms &&
                filteredFamilySponsorshipPrograms
                  .filter((program) => filterBy(state, program))
                  .map((program) => (
                    <tr
                      key={program.id}
                      className="cursor-pointer odd:bg-gray-50 hover:ring hover:ring-inset hover:ring-brand-sky/20"
                      onClick={() =>
                        navigate(
                          localizedPath({
                            defaultLang,
                            prefixDefault,
                            locale,
                            path: `/visas/${getCanadaSlugById(program.id)}`,
                          }),
                        )
                      }
                    >
                      <td className="p-4 pl-10 text-gray-600">
                        {program.name}{' '}
                        <ProgramBadge type={program.program_type} name={t(`programType.${program.program_type}`)} />
                      </td>
                      <td className="p-4 text-right">
                        <span className="mr-0.5 font-light">$</span>
                        <span className="font-medium tabular-nums">
                          {numberFormat.format(program.price.amount / 100)}
                        </span>
                      </td>
                    </tr>
                  ))}
              <tr>
                <td colSpan={2}>
                  <p className="pt-8 text-sm font-medium text-brand-sky">
                    * {t('pricing.superVisasPricesDoNotInclude')}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

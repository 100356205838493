export function IconQuoteRight({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M6.92887 0C7.48117 2.49756 7.75732 4.89106 7.75732 7.18049C7.75732 10.0943 7.1046 12.3057 5.79916 13.8146C4.54393 15.2715 2.66109 16 0.150627 16V12.8C1.45607 12.8 2.38494 12.4618 2.93724 11.7854C3.48954 11.0569 3.76569 9.96423 3.76569 8.50732V6.7122H0V0H6.92887ZM17.0962 0C17.6987 2.70569 18 5.09918 18 7.18049C18 10.0943 17.3473 12.3057 16.0418 13.8146C14.7364 15.2715 12.8285 16 10.318 16V12.8C11.6234 12.8 12.5523 12.4618 13.1046 11.7854C13.6569 11.0569 13.9331 9.96423 13.9331 8.50732V6.7122H10.1674V0H17.0962Z"
        fill="#828282"
      />
    </svg>
  );
}

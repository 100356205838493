import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./src/assets/scss/tailwind.css";
import * as React from "react";
import { setItemToLocalStorage } from "./src/utils/storage-api";
import { CountryProvider } from "./src/utils/CountryContext";
import { RedirectProvider } from "./src/utils/RedirectContext";

export const onClientEntry = () => {
  const { pathname } = window.location;

  if (new RegExp(`^/canada|^/us`).test(pathname)) {
    setItemToLocalStorage("destination_country", JSON.stringify(new RegExp(`^/canada`).test(pathname) ? "CA" : "US"));
  }
};

export const wrapRootElement = ({ element }) => (
  <CountryProvider>
    <RedirectProvider>{element}</RedirectProvider>
  </CountryProvider>
);

/* Workaround for ChunkLoadError
 * https://github.com/gatsbyjs/gatsby/issues/18866#issuecomment-844449917
 */
let nextRoute = ``;
export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};
window.addEventListener("unhandledrejection", (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const {
    location: { hash },
  } = routerProps;
  /*
   * If the previous page is blog page and the current page is blog page,
   * don't scroll to the top of the page.
   * This is to prevent the page from jumping when the user selects filters on blog.
   */
  if (/blog/i.test(prevRouterProps?.location.pathname) && /blog/i.test(routerProps?.location.pathname)) {
    return false;
  }

  if (hash) {
    window.setTimeout(() => {
      const element = document.querySelector(`${hash}`);
      if (element) {
        const { offsetTop } = element;
        window.scrollTo({ left: 0, top: offsetTop - 106, behavior: "smooth" });
      }
    }, 0);
    return false;
  }
  return true;
};

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof window?.gtag === "function") {
      window.gtag("event", "page_view", { page_path: pagePath });
    }
  }, 100);
  return null;
};

import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

export function BannerGPT() {
  const { t } = useTranslation();
  return (
    <a
      href="https://chat.openai.com/g/g-u4tnAlamk-supervisas-immigration-and-visas"
      className="group absolute -top-24 block w-fit rounded-xl border border-white bg-white px-4 py-2 text-brand-sky shadow-2xl transition-all duration-200 hover:scale-105 lg:-top-16"
      target="_blank"
      rel="noreferrer"
    >
      <span className="mr-1 lg:mr-2">🎉</span> {t('home.useOurGpt')}{' '}
      <ArrowRightCircleIcon className="inline-block h-6 w-6 transition-all duration-200 group-hover:translate-x-1" />
    </a>
  );
}

export function BannerFreeStudyPermit() {
  const { t } = useTranslation();
  return (
    <LocalizedLink
      to="/visas/free-study-permit-generator/"
      className="group absolute -top-24 block w-fit rounded-xl border border-white bg-white px-4 py-2 text-brand-sky shadow-2xl transition-all duration-200 hover:scale-105 lg:-top-16"
    >
      <span className="mr-1 lg:mr-2">🎉</span> {t('home.freeStudyPermitBanner')}{' '}
      <ArrowRightCircleIcon className="inline-block h-6 w-6 transition-all duration-200 group-hover:translate-x-1" />
    </LocalizedLink>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'react-i18next';

function NumberCircle({ number }: { number: number }) {
  return (
    <span className="mt-0.5 flex h-6 w-6 items-center justify-center rounded-full border-2 border-brand-blue font-mono text-sm font-semibold text-brand-blue">
      {number}
    </span>
  );
}

function CanadianVisaFeatures() {
  const { t } = useTranslation();
  return (
    <>
      <li className="flex items-start gap-3">
        <NumberCircle number={1} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-lg font-semibold text-gray-800">{t('home.whyChooseSuperVisas1')}</div>
          <div>{t('home.whyChooseSuperVisas1Description')}</div>
        </div>
      </li>
      <li className="flex items-start gap-3">
        <NumberCircle number={2} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-lg font-semibold text-gray-800">{t('home.whyChooseSuperVisas2')}</div>
          <div>{t('home.whyChooseSuperVisas2Description')}</div>
        </div>
      </li>
      <li className="flex items-start gap-3">
        <NumberCircle number={2} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-lg font-semibold text-gray-800">{t('home.reviewedByLicensedRCICs')}</div>
          <div>
            <Trans
              i18nKey="home.reviewedByLicensedRCICsDescription"
              components={[
                // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                <a
                  className="text-brand-blue hover:underline"
                  href="https://college-ic.ca/protecting-the-public/find-an-immigration-consultant"
                  target="_blank"
                  rel="nofollow noreferrer"
                />,
              ]}
            />
          </div>
        </div>
      </li>
    </>
  );
}

function USVisaFeatures() {
  const { t } = useTranslation();
  return (
    <>
      <li className="flex items-start gap-3">
        <NumberCircle number={1} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-xl font-semibold text-gray-800">{t('home.whyChooseSuperVisas1')}</div>
          <div>{t('home.whyChooseSuperVisas1Description')}</div>
        </div>
      </li>
      <li className="flex items-start gap-3">
        <NumberCircle number={2} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-xl font-semibold text-gray-800">{t('home.whyChooseSuperVisas2')}</div>
          <div>{t('home.whyChooseSuperVisas2Description')}</div>
        </div>
      </li>
      <li className="flex items-start gap-3">
        <NumberCircle number={3} />
        <div className="flex-1 space-y-1">
          <div className="font-display text-xl font-semibold text-gray-800">
            {t('home.reviewedByExperiencedImmigrationLawyers')}
          </div>
          <div>{t('home.reviewedByExperiencedImmigrationLawyersDescription')}</div>
        </div>
      </li>
    </>
  );
}

export function WhyPeopleChooseSuperVisas({ destinationCountry }: { destinationCountry: 'US' | 'CA' }) {
  const { t } = useTranslation();
  return (
    <section className="mx-auto my-20 flex max-w-[1500px] flex-col gap-8 px-4 lg:my-40 lg:flex-row lg:items-center lg:gap-12 xl:gap-16">
      <div className="flex-1">
        <StaticImage
          className="rounded-lg"
          src="../../assets/img/home/woman_with_laptop.jpg"
          layout="fullWidth"
          alt="woman with laptop"
          placeholder="blurred"
        />
      </div>
      <div className="flex-1">
        <h5 className="font-display text-xl font-semibold text-gray-600 lg:text-2xl">
          {destinationCountry === 'CA'
            ? t('home.whyPeopleChooseSuperVisasForCA')
            : t('home.whyPeopleChooseSuperVisasFor')}
        </h5>
        <h4 className="mt-1 font-display text-2xl font-semibold text-brand-blue lg:text-4xl">
          {destinationCountry === 'CA' ? t('home.gettingACanadianVisa') : t('home.gettingAVisaToTheUSA')}
        </h4>
        <ul className="mt-6 space-y-4 text-base text-gray-600">
          {destinationCountry === 'CA' ? <CanadianVisaFeatures /> : <USVisaFeatures />}
        </ul>
      </div>
    </section>
  );
}

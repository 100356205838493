import { Trans, useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import scrollTo from 'gatsby-plugin-smoothscroll';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { useCountry } from '@/utils/CountryContext';
import { ComponentProps, ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CanadaProgramIndex, USProgramIndex } from '@/@types/program-index';
import { SuperVisasLogo } from '../svg/SuperVisasLogo';

const PROGRAMS_QUERY = graphql`
  query {
    programs(language: { eq: "en" }) {
      id
      canada_programs {
        id
        slug
      }
      us_programs {
        id
        slug
      }
    }
  }
`;

function VisaLink({ slug, children }: { slug: string; children: ReactNode }) {
  return (
    <LocalizedLink
      to={`/visas/${slug}/`}
      className="py-1 leading-tight text-blue-50 hover:underline focus-visible:underline"
    >
      {children}
    </LocalizedLink>
  );
}

function LocalLink({ to, children }: { to: string; children: ReactNode }) {
  return (
    <LocalizedLink to={to} className="py-1 leading-tight text-blue-50 hover:underline focus-visible:underline">
      {children}
    </LocalizedLink>
  );
}

function Category({ children }: ComponentProps<'div'>) {
  return <div className="mb-0.5 select-none font-display text-lg font-semibold">{children}</div>;
}

function SiteMap() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const { country } = useCountry();
  const {
    programs: { canada_programs: canadaPrograms, us_programs: usPrograms },
  } = useStaticQuery(PROGRAMS_QUERY);
  const idToCanadaSlugMap = canadaPrograms.reduce((acc, { id: programId, slug }) => {
    acc[programId] = slug;
    return acc;
  }, {});
  const idToUSSlugMap = usPrograms.reduce((acc, { id: programId, slug }) => {
    acc[programId] = slug;
    return acc;
  }, {});

  /*
    The fallback is for non-production environments.
    In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
  */
  function getCanadaSlugById(id: number) {
    return idToCanadaSlugMap[id] || canadaPrograms[0].slug;
  }
  function getUSSlugById(id: number) {
    return idToUSSlugMap[id] || usPrograms[0].slug;
  }

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const homePath = { US: '/us/', CA: '/canada/' }[country] || '/';
  const visasPath = { US: '/visas-us/list/', CA: '/visas-canada/list/' }[country] || '/visas';
  return (
    <nav className="container mx-auto px-4 text-white">
      <ul className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:flex lg:justify-between">
        <li className="flex flex-col">
          <Category>{t('footer.tools')}</Category>
          <LocalLink to={visasPath}>{t('footer.findAProgram')}</LocalLink>
          <a href={`/eligibility?${queryParams}`} className="py-1 hover:underline focus-visible:underline">
            {t('footer.eligibilityChecker')}
          </a>
          {country === 'CA' && <LocalLink to="/canada/pricing/">{t('header.pricing')}</LocalLink>}
          {country === 'US' && <LocalLink to="/us/pricing/">{t('header.pricing')}</LocalLink>}
          <LocalLink to="/blog/">{t('header.blog')}</LocalLink>
        </li>
        <li className="flex flex-col">
          <Category>{t('footer.company')}</Category>
          <LocalLink to={homePath}>{t('footer.homePage')}</LocalLink>
          <LocalLink to="/about/">{t('header.aboutUs')}</LocalLink>
          <a href="https://www.supervisas.ai" className="py-1 hover:underline focus-visible:underline">
            {t('footer.ourTechnology')}
          </a>
          <LocalLink to="/partners/">{t('header.partners')}</LocalLink>
        </li>
        <li className="flex flex-col">
          <Category>{t('common.usVisas')}</Category>
          <VisaLink slug={getUSSlugById(USProgramIndex.B_1)}>B1/B2 Visit Visa</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.H_1B)}>H-1B Work Visa</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.TN)}>TN Work Visa</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.L_1)}>L-1 Work Visa</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.O_1)}>O-1 Work Visa</VisaLink>
        </li>
        <li className="flex flex-col">
          <Category>{t('footer.usImmigration')}</Category>
          <VisaLink slug={getUSSlugById(USProgramIndex.EB_1A)}>EB-1A</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.EB_2_NIW)}>EB-2 NIW</VisaLink>
          <VisaLink slug={getUSSlugById(USProgramIndex.I_130)}>I-130</VisaLink>
        </li>
        <li className="flex flex-col">
          <Category>{t('footer.canadianVisas')}</Category>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.VISITOR_VISA)}>Visitor Visa</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.STUDY_PERMIT)}>Study Permit</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.OPEN_WORK_PERMIT)}>Open Work Permit</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.ICT_WORK_PERMIT)}>
            Intra-company Transferee (ICT) Work Permit
          </VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.LMIA)}>LMIA</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.GLOBAL_TALENT_STREAM)}>Global Talent Stream</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.PARENT_AND_GRANDPARENT_SUPER_VISA)}>
            Parent and Grandparent Super Visa
          </VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.EMPLOYER_SPECIFIC_WORK_PERMIT)}>
            Employer-specific Work Permit
          </VisaLink>
        </li>
        <li className="flex flex-col">
          <Category>{t('footer.canadianImmigration')}</Category>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.EXPRESS_ENTRY)}>Express Entry</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.PROVINCIAL_NOMINATION_CLASS)}>
            Provincial Nomination Class
          </VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.SPOUSAL_SPONSORSHIP)}>Spousal Sponsorship</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.STARTUP_VISA_PROGRAM)}>Start-up Visa Program</VisaLink>
          <VisaLink slug={getCanadaSlugById(CanadaProgramIndex.FEDERAL_SELF_EMPLOYED_PROGRAM)}>
            Federal Self-employed Persons
          </VisaLink>
        </li>
      </ul>
    </nav>
  );
}

function Address() {
  const { t } = useTranslation();

  return (
    <ul className="container mx-auto mt-20 grid grid-cols-1 gap-10 px-4 text-blue-50 sm:grid-cols-2  lg:flex lg:justify-between">
      <li>
        <div className="flex">
          <SuperVisasLogo className="-ml-2 h-[75px] w-[131px] text-white lg:w-auto" />
        </div>
        <p className="break-keep-all-kr my-4 text-blue-50 lg:my-5">
          <Trans
            i18nKey="footer.privacyPolicy"
            t={t}
            components={[
              // eslint-disable-next-line
              <a
                href="https://www.supervisas.com/static/local/pdf/terms-of-use-agreement.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              />,
              // eslint-disable-next-line
              <a
                href="https://www.supervisas.com/static/local/pdf/privacy-policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              />,
            ]}
          />
        </p>
        <p className="mt-4 text-white lg:mt-5">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
      </li>
      <li className="flex flex-col gap-10 sm:gap-6">
        <div>
          <div className="mb-1 select-none font-display text-lg font-semibold uppercase text-white">
            <div>{t('footer.canadaOffice')}</div>
          </div>
          <address className="whitespace-nowrap not-italic">
            151 Charles St W Suite #199, <br />
            Kitchener, ON N2G 1H6
          </address>
        </div>
        <div>
          <div className="mb-1 select-none font-display text-lg font-semibold uppercase text-white">
            <div>{t('footer.usOffice')}</div>
          </div>
          <address className="whitespace-nowrap not-italic">
            100 S Cincinnati Ave Suite #513, <br />
            Tulsa, OK 74103
          </address>
        </div>
        <div>
          <div className="mb-1 select-none font-display text-lg font-semibold uppercase text-white">
            {t('footer.phone')}
          </div>
          <div className="whitespace-nowrap">1-888-246-9856</div>
        </div>
      </li>
      <li className="flex flex-col gap-10 sm:order-last sm:gap-6 lg:order-none lg:min-w-[315.5px] 2xl:min-w-[365.5px]">
        <div>
          <div className="mb-1 block select-none font-display text-lg font-semibold uppercase text-white">
            {t('footer.email')}
          </div>
          <a className="whitespace-nowrap" href="mailto:support@supervisas.com">
            support@supervisas.com
          </a>
        </div>
        <div className="text-blue-50">
          <div className="mb-2 block select-none font-display text-lg font-semibold uppercase text-white">
            {t('footer.followUs')}
          </div>
          <div className="flex gap-4">
            <a
              href="https://www.xiaohongshu.com/user/profile/637271c3000000001f01ad8a?xhsshare=CopyLink&appuid=60fc11c300000000010029a0&apptime=1694030629"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../../assets/img/social_media/xiaohongshu.png"
                alt="Xiaohongshu logo"
                placeholder="blurred"
              />
            </a>
            <a
              href="https://www.facebook.com/people/SuperVisas/100063455936823/"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../../assets/img/social_media/Facebook.png"
                alt="Facebook logo"
                placeholder="blurred"
              />
            </a>
            <a href="https://www.instagram.com/supervisas/" target="_blank" rel="nofollow noreferrer">
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../../assets/img/social_media/Instagram.png"
                alt="Instagram logo"
                placeholder="blurred"
              />
            </a>
            <a href="https://ca.linkedin.com/company/supervisas" target="_blank" rel="nofollow noreferrer">
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../../assets/img/social_media/LinkedIn.png"
                alt="LinkedIn logo"
                placeholder="blurred"
              />
            </a>
          </div>
        </div>
      </li>
      <li className="flex shrink-0 flex-col gap-6">
        <div className="flex gap-1">
          <a
            href="https://www.bbb.org/ca/on/kitchener/profile/immigration-consultant/supervisas-0107-1396462/#sealclick"
            target="_blank"
            rel="nofollow noreferrer"
            className="rounded-md bg-white"
          >
            <StaticImage
              className="w-12"
              layout="fullWidth"
              src="../../../assets/img/BBB-vertical.png"
              alt="SUPERVISAS BBB Business Review"
              placeholder="blurred"
            />
          </a>
        </div>
      </li>
    </ul>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="w-full bg-brand-blue py-20">
      <SiteMap />
      <Address />
      <div className="container mx-auto mt-12 flex px-4 text-white lg:flex-row-reverse">
        <button className="text-sm" type="button" onClick={() => scrollTo('#top')}>
          {t('footer.scrollToTop')}
        </button>
      </div>
    </footer>
  );
}

import { useTranslation } from 'react-i18next';
import { TrustedBy } from '@/components/home/Trustedby';
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import { UniversalHomeHero } from '@/components/home/UniversalHomeHero';
import { WhyPeopleChooseSuperVisas } from '@/components/home/WhyPeopleChooseSuperVisas';
import { FeatureLinks } from '@/components/home/FeatureLinks';
import { Reviews } from '@/components/home/Reviews';
import { ProgramLinks } from '@/components/home/ProgramLinks';
import { FAQ } from '@/components/home/FAQ';
import { TrustBox } from '@/components/home/TrustBox';

const CanadaPage = () => {
  const { t } = useTranslation();
  return (
    <Layout variant="blue">
      <main>
        <SEO
          title={`SuperVisas | ${t('seo.makingImmigrationSimple')}`}
          description={t('seo.theEasiestAndMostAffordable')}
          trustPilot
        />
        <UniversalHomeHero destinationCountry="CA" />
        <WhyPeopleChooseSuperVisas destinationCountry="CA" />
        <FeatureLinks destinationCountry="CA" className="my-20" />
        <TrustedBy destinationCountry="CA" />
        <TrustBox />
        <Reviews destinationCountry="CA" />
        <ProgramLinks destinationCountry="CA" />
        <FAQ destinationCountry="CA" />
        <FeatureLinks destinationCountry="CA" />
      </main>
    </Layout>
  );
};

export default CanadaPage;

import { graphql, PageProps } from 'gatsby';
import SEO from '@/components/ui/seo/SEO';
import Layout from '@/components/ui/layouts/Layout';
import { BlogPost } from '@/@types/blog';
import { Post } from '@/components/blog/Post';

interface ProgramData {
  post: BlogPost & { relatedPosts: Pick<BlogPost, 'id' | 'categories' | 'slug' | 'image' | 'thumbnail' | 'title'>[] };
}

interface PageContext {
  hrefLang: string;
  metaDescription: string;
}

export default function PostDetail({
  data: { post },
  pageContext: { hrefLang, metaDescription },
}: PageProps<ProgramData, PageContext>) {
  const {
    title,
    image,
    author: { name: authorName },
  } = post;
  return (
    <Layout>
      <main className="wrapper white-content" id="program-landing-page">
        <SEO title={title} description={metaDescription} image={image} author={authorName} article />
        <div className="main-panel">
          <Post post={post} hrefLang={hrefLang} />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query PostDetailTemplate($slug: String!) {
    post(slug: { eq: $slug }) {
      id
      categories {
        id
        name
      }
      country
      content
      image
      description
      publishedDate
      title
      author {
        id
        title
        name
        image
      }
      relatedPosts {
        id
        categories {
          id
          name
        }
        slug
        image
        thumbnail
        title
      }
    }
  }
`;

import { Trans, useTranslation } from 'react-i18next';
import { CanadaProgramIndex } from '@/@types/program-index';
import { graphql, useStaticQuery } from 'gatsby';
import Link from '../ui/links/Link';

const PROGRAMS_QUERY = graphql`
  query {
    programs(language: { eq: "en" }) {
      id
      canada_programs {
        id
        slug
      }
      us_programs {
        id
        slug
      }
    }
  }
`;

export function ProcessForStudy() {
  const { t } = useTranslation();
  const {
    programs: { canada_programs: canadaPrograms },
  } = useStaticQuery(PROGRAMS_QUERY);
  const idToCanadaSlugMap = canadaPrograms.reduce((acc, { id: programId, slug }) => {
    acc[programId] = slug;
    return acc;
  }, {});
  /*
    The fallback is for non-production environments.
    In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
  */
  function getCanadaSlugById(id: number) {
    return idToCanadaSlugMap[id] || canadaPrograms[0].slug;
  }
  return (
    <div className="container mx-auto mt-20 px-4">
      <h2 className="text-2xl font-semibold text-gray-700 lg:text-3xl">{t('pathways.whatIsTheProcess')}</h2>
      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.applyToACanadianSchool')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.applyToACanadianSchoolDescription1')}</p>
          <p>{t('pathways.applyToACanadianSchoolDescription2')}</p>
          <div>{t('pathways.applyToACanadianSchoolDescription3')}</div>
          <ul>
            <li>{t('pathways.applyToACanadianSchoolDescription4')}</li>
            <li>{t('pathways.applyToACanadianSchoolDescription5')}</li>
            <li>{t('pathways.applyToACanadianSchoolDescription6')}</li>
            <li>{t('pathways.applyToACanadianSchoolDescription7')}</li>
            <li>{t('pathways.applyToACanadianSchoolDescription8')}</li>
          </ul>
          <p>{t('pathways.applyToACanadianSchoolDescription9')}</p>
          <p>{t('pathways.applyToACanadianSchoolDescription10')}</p>
        </div>
      </section>

      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.getAStudyPermit')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.getAStudyPermitDescription1')}</p>
          <p>{t('pathways.getAStudyPermitDescription2')}</p>
          <p>{t('pathways.getAStudyPermitDescription3')}</p>
          <p>{t('pathways.getAStudyPermitDescription4')}</p>
          <h4>{t('pathways.getAStudyPermitDescription5')}</h4>
          <ul>
            <li>{t('pathways.getAStudyPermitDescription6')}</li>
            <li>{t('pathways.getAStudyPermitDescription7')}</li>
            <li>{t('pathways.getAStudyPermitDescription8')}</li>
            <li>{t('pathways.getAStudyPermitDescription9')}</li>
            <li>{t('pathways.getAStudyPermitDescription10')}</li>
            <li>{t('pathways.getAStudyPermitDescription11')}</li>
          </ul>

          <h4>{t('pathways.getAStudyPermitDescription12')}</h4>
          <ul>
            <li>{t('pathways.getAStudyPermitDescription13')}</li>
            <li>{t('pathways.getAStudyPermitDescription14')}</li>
            <li>{t('pathways.getAStudyPermitDescription15')}</li>
            <li>{t('pathways.getAStudyPermitDescription16')}</li>
            <li>{t('pathways.getAStudyPermitDescription17')}</li>
          </ul>

          <h4>{t('pathways.getAStudyPermitDescription18')}</h4>
          <ol>
            <li>{t('pathways.getAStudyPermitDescription19')}</li>
            <li>{t('pathways.getAStudyPermitDescription20')}</li>
            <li>{t('pathways.getAStudyPermitDescription21')}</li>
            <li>{t('pathways.getAStudyPermitDescription22')}</li>
          </ol>
        </div>
      </section>

      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.getAWorkPermit')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.studyGetAWorkPermitDescription1')}</p>
          <p>{t('pathways.studyGetAWorkPermitDescription2')}</p>
          <p>{t('pathways.studyGetAWorkPermitDescription3')}</p>
          <ul>
            <li>{t('pathways.studyGetAWorkPermitDescription4')}</li>
            <li>{t('pathways.studyGetAWorkPermitDescription5')}</li>
            <li>{t('pathways.studyGetAWorkPermitDescription6')}</li>
          </ul>
        </div>
      </section>

      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.applyForImmigration')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.studyApplyForImmigrationDescription1')}</p>
          <p>{t('pathways.studyApplyForImmigrationDescription2')}</p>
          <p>{t('pathways.studyApplyForImmigrationDescription3')}</p>
          <p>{t('pathways.studyApplyForImmigrationDescription4')}</p>
          <ul>
            <li>
              <Trans
                i18nKey="pathways.studyApplyForImmigrationDescription5"
                t={t}
                components={[
                  <Link
                    /* 42: Express Entry */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.EXPRESS_ENTRY)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.studyApplyForImmigrationDescription6"
                t={t}
                components={[
                  <Link
                    /* 79: New Brunswick Skilled Worker Stream */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.NEW_BRUNSWICK_SKILLED_WORKER_STREAM)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.studyApplyForImmigrationDescription7"
                t={t}
                components={[
                  <Link
                    /* 59: British Columbia International Post-Graduate */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.BRITISH_COLUMBIA_INTERNATIONAL_POST_GRADUATE)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.studyApplyForImmigrationDescription8"
                t={t}
                components={[
                  <Link
                    /* 104: OINP Masters Graduate Stream */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.OINP_MASTERS_GRADUATE_STREAM)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                  <Link
                    /* 105: OINP PhD Graduate Stream */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.OINP_PHD_GRADUATE_STREAM)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

import { ProgramSummaryData } from '@/@types/ProgramData';
import Layout from '@/components/ui/layouts/Layout';
import { Features } from '@/components/program/Features';
import { HowItWorks } from '@/components/program/HowItWorks';
import { Pricing } from '@/components/program/Pricing';
import { ProgramHeader } from '@/components/program/ProgramHeader';
import { Documents } from '@/components/program/Documents';
import SEO from '@/components/ui/seo/SEO';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { graphql, PageProps } from 'gatsby';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

export default function Program({
  data: {
    program: {
      name,
      plainTextSummary,
      sanitizedDescriptionAndSummary: { description },
      price: { amount },
      requiredDocuments,
      countryCode,
      programType: { id: programType },
      programId,
    },
  },
}: PageProps<ProgramSummaryData>) {
  const { t } = useTranslation();
  const isCanada = countryCode === 'CA';
  return (
    <Layout>
      <main className="mt-10">
        <SEO title={`SuperVisas | ${name}`} description={plainTextSummary} />
        <div className="container mx-auto mb-10 px-4 text-left font-normal">
          <nav className="text-gray-700" aria-label="Breadcrumb">
            <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
              <li className="inline-flex items-center">
                <LocalizedLink
                  to="/visas/"
                  className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                >
                  {t('header.programs')}
                </LocalizedLink>
              </li>
              <li className="inline-flex items-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                <LocalizedLink
                  to={`/visas-${isCanada ? 'canada' : 'us'}/`}
                  className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 md:ml-1"
                >
                  {isCanada ? t('home.canada') : t('common.unitedStates')}
                </LocalizedLink>
              </li>
              <li className="inline-flex items-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                <LocalizedLink
                  to={`/visas-${isCanada ? 'canada' : 'us'}/list/`}
                  className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 md:ml-1"
                >
                  {t('common.list')}
                </LocalizedLink>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                  <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{name}</span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <ProgramHeader
          name={name}
          plainTextSummary={plainTextSummary}
          countryCode={countryCode}
          programType={programType}
          programId={programId}
          description={description}
        />
        <Pricing amount={amount} countryCode={countryCode} programType={programType} programId={programId} />
        <HowItWorks />
        <Features />
        <Documents
          documents={requiredDocuments}
          countryCode={countryCode}
          programType={programType}
          programId={programId}
        />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query programTemplate($locale: String!, $programId: Int!) {
    program(programId: { eq: $programId }, language: { eq: $locale }) {
      id
      language
      name
      countryCode
      plainTextSummary
      sanitizedDescriptionAndSummary {
        description
      }
      price {
        amount
      }
      programId
      requiredDocuments {
        label
        required
      }
      programType {
        id
      }
    }
  }
`;

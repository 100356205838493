import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { ProgramType } from '@/@types/ProgramData';
import { useBreakpoints } from '@/utils/breakpoint';
import { gTag } from '@/utils/gTag';
import ExternalLink from '../ui/links/ExternalLink';

interface DocumentsProps {
  documents: Array<{
    label: string;
    required: boolean;
  }>;
  countryCode: 'CA' | 'US';
  programType: ProgramType;
  programId: number;
}

function RequiredDocuments({
  documents,
}: {
  documents: Array<{
    label: string;
    required: boolean;
  }>;
}) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  if (documents.length === 0) {
    return (
      <li
        className="text-gray-600"
        {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
      >
        <span>{t('programs.noDocumentRequired')}</span>
      </li>
    );
  }
  return (
    <>
      {documents.map(({ label }) => (
        <li
          key={label}
          className="ml-4 list-disc marker:text-blue-200"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
        >
          <span className="text-xs text-gray-700 lg:text-base">{label}</span>
        </li>
      ))}
    </>
  );
}

function OptionalDocuments({ documents }: { documents: Array<{ label: string; required: boolean }> }) {
  const { isMobile } = useBreakpoints();
  return (
    <>
      {documents.map(({ label }) =>
        label === 'Additional Documents' ? null : (
          <li
            key={label}
            className="ml-4 list-disc marker:text-blue-200"
            {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          >
            <span className="text-xs text-gray-700 lg:text-base">{label}</span>
          </li>
        ),
      )}
    </>
  );
}

export function Documents({ documents, countryCode, programType, programId }: DocumentsProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const orderedDocuments = documents.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  const requiredDocuments = [];
  const optionalDocuments = [];
  orderedDocuments.forEach((d) => {
    if (d.required) {
      requiredDocuments.push(d);
    } else {
      optionalDocuments.push(d);
    }
  });
  const showCheckMyEligibility = !['sponsor_employee', 'sponsor_family', 'additional_services'].includes(programType);
  return (
    <div className="min-h-[800px]">
      <div className="container mx-auto mt-10 mb-20 px-4">
        <h5 className="text-xl font-medium text-brand-blue lg:text-3xl">{t('programs.requiredDocuments')}</h5>
        <p className="mt-0.5 text-xs text-gray-400 lg:text-sm">{t('programs.requiredDocumentsDescription')}</p>
        <ul className="mt-8 space-y-1">
          <RequiredDocuments documents={requiredDocuments} />
        </ul>
        {requiredDocuments.length < 5 && optionalDocuments.length > 0 && (
          <>
            <h5 className="mt-20 text-xl font-medium text-brand-blue lg:text-3xl">{t('programs.optionalDocuments')}</h5>
            <p className="mt-0.5 text-xs text-gray-400 lg:text-sm">{t('programs.optionalDocumentsDescription')}</p>
            <ul className="mt-8 space-y-1">
              <OptionalDocuments documents={optionalDocuments} />
            </ul>
          </>
        )}
      </div>
      <div className="mt-10 mb-30 px-4 lg:flex lg:justify-center">
        <div className="flex flex-col gap-4 lg:flex-row">
          {programType !== 'additional_services' && (
            <ExternalLink
              variant="primary"
              to={`/start_new_application?program_id=${programId}&${queryParams}`}
              onClick={() => gTag('event', 'start_an_application')}
              className="w-full text-center lg:w-auto lg:min-w-[184px]"
            >
              {t('common.startAnApplication')}
            </ExternalLink>
          )}
          {showCheckMyEligibility && (
            <ExternalLink
              variant="outline"
              to={`/eligibility?country=${countryCode}&type=${programType}&${queryParams}`}
              className="w-full text-center lg:w-auto lg:min-w-[184px]"
              onClick={() => gTag('event', 'check_my_eligibility')}
            >
              {t('common.checkMyEligibility')}
            </ExternalLink>
          )}
        </div>
      </div>
    </div>
  );
}

import Layout from '@/components/ui/layouts/Layout';
import Link from '@/components/ui/links/Link';
import { CanadaDescription } from '@/components/program/CanadaDescription';
import { ImmigrationStatistics } from '@/components/program/ImmigrationStatistics';
import { ProgramCount } from '@/components/program/ProgramCount';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import SEO from '@/components/ui/seo/SEO';

export default function Canada() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="pb-40">
        <SEO
          title={`SuperVisas | ${t('seo.canadianImmigrationSystem')}`}
          description={t('seo.canadianImmigrationSystemDescription')}
        />
        <StaticImage
          src="../../assets/img/business/city-with-canada-flag.jpg"
          alt="city with Canada flag"
          placeholder="blurred"
          layout="fullWidth"
          className="max-h-[420px]"
        />
        <nav className="container mx-auto mt-20 rounded-lg px-4 text-gray-700" aria-label="Breadcrumb">
          <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
            <li className="inline-flex items-center">
              <LocalizedLink
                to="/visas/"
                className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
              >
                {t('header.programs')}
              </LocalizedLink>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{t('home.canada')}</span>
              </div>
            </li>
          </ul>
        </nav>
        <div className="container mx-auto mt-20 flex flex-col gap-8 lg:flex-row lg:gap-16 lg:px-4">
          <div className="flex-1 px-4 lg:px-0">
            <CanadaDescription />
          </div>
          <div className="space-y-8 lg:pt-18">
            <ProgramCount
              title={
                <Trans
                  i18nKey="translation:programs.weSupportProgramsInCanada"
                  values={{
                    programCount: '100+',
                  }}
                  components={[<span className="text-brand-blue" />]}
                />
              }
              country="CA"
            >
              <Link variant="primary" to="/visas-canada/list/" className="inline-block">
                {t('programs.viewCanadaPrograms')}
              </Link>
            </ProgramCount>
            <ImmigrationStatistics
              year={2019}
              study={402427}
              work={404369}
              visit={5774342}
              immigrate={341180}
              sourceLabel={t('programs.annualReport')}
              sourceUrl="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/annual-report-parliament-immigration-2020.html"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
}

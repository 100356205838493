import { ReactNode } from 'react';
import { IconQuoteLeft } from '../svg/IconQuoteLeft';
import { IconQuoteRight } from '../svg/IconQuoteRight';

export function Quote({
  children,
  author,
  organization,
}: {
  children: ReactNode;
  author: string;
  organization: string;
}) {
  return (
    <>
      <div className="relative px-6 py-2">
        <IconQuoteLeft className="absolute left-0 top-0" />
        <p className="break-keep-all-kr font-medium text-gray-500">{children}</p>
        <IconQuoteRight className="absolute bottom-0 right-0" />
      </div>
      <div className="mt-2">
        <div className="text-base font-medium text-brand-blue lg:text-lg">
          {author}, {organization}
        </div>
      </div>
    </>
  );
}

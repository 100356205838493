import { cn } from '@/utils/cn';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

export function TrustedBy({ destinationCountry = '' }: { destinationCountry?: 'US' | 'CA' | '' }) {
  const { t } = useTranslation();
  return (
    <div className="my-20">
      {destinationCountry === '' && (
        <div className="container mx-auto px-4 text-center">
          <h4 className="font-display text-xl font-semibold text-gray-600 lg:text-3xl">
            {t('home.trustworthyAndReliable')}
          </h4>
        </div>
      )}
      <ul
        className={cn(
          'container mx-auto mt-6 grid grid-cols-1 items-center gap-8 px-4',
          'lg:w-fit lg:grid-cols-2 lg:justify-center lg:gap-16 2xl:grid-cols-4',
        )}
      >
        <li className="flex items-center justify-center">
          <a
            href="https://www.bbb.org/ca/on/kitchener/profile/immigration-consultant/supervisas-0107-1396462/#sealclick"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <StaticImage
              className="w-[260px] lg:w-[320px]"
              layout="fullWidth"
              src="../../assets/img/home/BBB.png"
              alt="SUPERVISAS BBB Business Review"
              placeholder="blurred"
            />
          </a>
        </li>
        <li className={cn('flex items-center justify-center', { 'order-last': destinationCountry === 'US' })}>
          <StaticImage
            className="w-[240px] lg:w-[280px]"
            layout="fullWidth"
            src="../../assets/img/home/SecureEncryption.png"
            alt="Secure Encryption logo"
            placeholder="blurred"
          />
        </li>
      </ul>
    </div>
  );
}

import { Requirement } from '@/@types/program';
import { ProgramBadge } from './ProgramBadge';

interface LanguageAbilityBadgeProps {
  requirements: Requirement[];
}

// it should be greater than maximum value of CLB
const INIT_VALUE = 13;

export function LanguageAbilityBadge({ requirements }: LanguageAbilityBadgeProps) {
  if (requirements.length === 0) {
    return null;
  }
  let minCLB = INIT_VALUE;
  requirements.forEach(({ language_min: languageMin }) => {
    if (languageMin !== null && languageMin.test_language !== 'FRENCH' && !!languageMin.reading) {
      minCLB = Math.min(minCLB, languageMin.reading);
    }
  });
  if (minCLB === INIT_VALUE) {
    return null;
  }
  return <ProgramBadge type="text" name={`CLB ${minCLB}`} />;
}

export function CheckBox({
  id,
  label,
  name,
  checked,
  onChange,
}: {
  id: string;
  label: string;
  name: string;
  checked: boolean;
  onChange: () => void;
}) {
  return (
    <div className="flex items-center py-0.5">
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="form-checkbox rounded border-gray-300 text-brand-blue shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:ring-offset-0"
      />
      <label htmlFor={id} className="ml-2 text-sm text-gray-600">
        {label}
      </label>
    </div>
  );
}

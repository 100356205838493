import { graphql, PageProps } from 'gatsby';
import { FAQ, FAQTopic, FAQSection } from '@/@types/faq';
import FAQAnswer from '@/views/faq/components/FAQAnswer';
import FAQList from '@/views/faq/components/FAQList';
import FAQBreadcrumbs from '@/views/faq/components/FAQBreadcrumbs';
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';

interface FAQData {
  faqTopic: FAQTopic;
  faq: FAQ;
  allFaqSections: { edges: { node: { faqSections: FAQSection[] } }[] };
  allCategory: { edges: { node: { slug: string; title: string } }[] };
}

function FAQTemplate({
  data: {
    faqTopic,
    faq,
    allFaqSections: { edges: faqSectionNodes },
    allCategory,
  },
  pageContext: { metaDescription },
}: PageProps<FAQData, { metaDescription: string }>) {
  const filteredFaqList = faqTopic.faqs.filter((f) => f.slug !== faq.slug);
  const { faqSections } = faqSectionNodes[0].node;
  const section = faqSections.find((s) => s.slug === faqTopic.section);
  const category = allCategory.edges.find((edge) => edge.node.slug === section.category).node;

  return (
    <Layout>
      <main id="program-landing-page">
        <SEO title={`SuperVisas | ${faq.title}`} description={metaDescription} />
        <div>
          <div className="container mx-auto px-4">
            <FAQBreadcrumbs category={category} section={section} topic={faqTopic} faqTitle={faq.title} />
            <FAQAnswer faq={faq} />
          </div>
          <FAQList category={category} section={section} faqList={filteredFaqList} faqTopic={faqTopic} />
        </div>
      </main>
    </Layout>
  );
}

export default FAQTemplate;

export const query = graphql`
  query FAQTemplate($topicSlug: String!, $faqSlug: String!) {
    faqTopic(slug: { eq: $topicSlug }) {
      slug
      section
      title
      faqs {
        answer
        slug
        title
      }
    }
    faq(slug: { eq: $faqSlug }) {
      slug
      title
      answer
    }
    allFaqSections {
      edges {
        node {
          faqSections {
            category
            slug
            title
            topics {
              title
              slug
            }
          }
        }
      }
    }
    allCategory {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

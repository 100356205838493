import { useContext, ReactNode, createContext, Dispatch, SetStateAction, useMemo } from 'react';
import { useLocalStorage } from './useLocalStorage';

type CountryProviderProps = { children: ReactNode };

const CountryContext =
  createContext<{ country: 'US' | 'CA' | ''; setCountry: Dispatch<SetStateAction<'US' | 'CA' | ''>> } | undefined>(
    undefined,
  );

export function CountryProvider({ children }: CountryProviderProps) {
  const { value: country, setValue: setCountry } = useLocalStorage<'US' | 'CA' | ''>('destination_country', '');
  const value = useMemo(() => ({ country, setCountry }), [country, setCountry]);
  return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>;
}

export const useCountry = () => {
  const context = useContext(CountryContext);
  if (context === undefined) {
    throw new Error('useCountry must be used within a CountryProvider');
  }
  return context;
};

import { useContext, ReactNode, createContext, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import i18nConfig from '../../i18n/config.json';
import { getItemFromLocalStorage, setItemToLocalStorage } from './storage-api';
import { useCountry } from './CountryContext';

type RedirectProviderProps = { children: ReactNode };

const RedirectContext = createContext<any>(undefined);

const INITIAL_STATE = {
  showModal: false,
  acceptRedirect: () => undefined,
  rejectRedirect: () => undefined,
};

export function RedirectProvider({ children }: RedirectProviderProps) {
  const { country, setCountry } = useCountry();
  const [redirectState, setRedirectState] =
    useImmer<{
      showModal: boolean;
      acceptRedirect: () => void;
      rejectRedirect: () => void;
    }>(INITIAL_STATE);
  useEffect(() => {
    const userLang = navigator.language;
    const { pathname } = window.location;

    // get originalPath from localizedPath
    let originalPath = pathname;
    let localeCodeFromUrl = 'en';
    i18nConfig.forEach((item) => {
      /* check if locale code needs to be removed from url to get original path */
      if (new RegExp(`^/${item.code}$|^/${item.code}/`).test(originalPath)) {
        originalPath = originalPath.replace(new RegExp(`^/${item.code}`), '');
        localeCodeFromUrl = item.code;
      }
    });
    let destination = pathname;
    let originalPathWithoutCountry = null;

    const persistedLang = getItemFromLocalStorage('i18nextLng');
    const locale = persistedLang || userLang;

    const persistedCountry = country || '';
    /* If the user is in the root path and the country has one of US or Canada, redirect to the country page */
    if (originalPath === '/' && persistedCountry !== '') {
      originalPathWithoutCountry = originalPath + (persistedCountry === 'CA' ? 'canada' : 'us');
    }

    /* local could be language code or hrefLang, so just check if local is matched with language code from URL */
    if (new RegExp(`^${localeCodeFromUrl}`).test(locale)) {
      if (originalPathWithoutCountry) {
        destination = originalPathWithoutCountry || '/';
        if (pathname !== destination) {
          setRedirectState((draft) => {
            draft.showModal = true;
            draft.acceptRedirect = () => {
              window.location.pathname = destination;
            };
            draft.rejectRedirect = () => {
              setCountry('');
            };
          });
        }
      }
      /* If our target locale and current locale are same, no needs to redirect */
      return;
    }
    originalPath = originalPathWithoutCountry || originalPath;
    switch (true) {
      case /^en/.test(locale):
        destination = originalPath || '/';
        break;
      case /^zh/.test(locale):
        destination = !originalPath || originalPath === '/' ? `/zh` : `/zh${originalPath}`;
        break;
      case /^ko/.test(locale):
        destination = !originalPath || originalPath === '/' ? `/ko` : `/ko${originalPath}`;
        break;
      case /^es/.test(locale):
        destination = !originalPath || originalPath === '/' ? `/es` : `/es${originalPath}`;
        break;
      default:
        return;
    }
    if (pathname !== destination) {
      setRedirectState((draft) => {
        draft.showModal = true;
        draft.acceptRedirect = () => {
          window.location.pathname = destination;
        };
        draft.rejectRedirect = () => {
          setItemToLocalStorage('i18nextLng', localeCodeFromUrl);
        };
      });
    }
  }, []);
  const value = useMemo(
    () => ({ ...redirectState, resetRedirectState: () => setRedirectState(INITIAL_STATE) }),
    [redirectState],
  );
  return <RedirectContext.Provider value={value}>{children}</RedirectContext.Provider>;
}

export const useRedirect = () => {
  const context = useContext(RedirectContext);
  if (context === undefined) {
    throw new Error('useRedirect must be used within a RedirectProvider');
  }
  return context;
};

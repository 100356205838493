import { useTranslation } from 'react-i18next';

export function CanadaDescription() {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="font-display text-2xl font-semibold text-brand-blue lg:text-4xl">{t('home.canada')}</h1>
      <ul className="mt-8 space-y-8 text-sm text-gray-500 lg:text-base">
        <li>
          <h4 className="mb-1 font-display text-base font-semibold uppercase text-gray-700 lg:text-xl">
            {t('programs.canadianImmigrationSystem')}
          </h4>
          <p className="break-keep-all-kr">{t('programs.canadianImmigrationSystemDescription')}</p>
        </li>
        <li>
          <h4 className="mb-1 font-display text-base font-semibold uppercase text-gray-700 lg:text-xl">
            {t('programs.permanentResidency')}
          </h4>
          <p className="break-keep-all-kr">{t('programs.permanentResidencyDescription1')}</p>
          <p className="break-keep-all-kr mt-4">{t('programs.permanentResidencyDescription2')}</p>
          <p className="break-keep-all-kr mt-4">{t('programs.permanentResidencyDescription3')}</p>
          <p className="break-keep-all-kr mt-4">{t('programs.permanentResidencyDescription4')}</p>
        </li>
        <li>
          <h4 className="mb-1 font-display text-base font-semibold uppercase text-gray-700 lg:text-xl">
            {t('programs.healthAndEducation')}
          </h4>
          <p className="break-keep-all-kr">{t('programs.healthAndEducationDescription')}</p>
        </li>
      </ul>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@/utils/breakpoint';
import { useCheckList } from '@/utils/useCheckList';
import { useRandomVisa } from '@/utils/useRandomVisa';
import { AnimatedCheckbox } from './AnimatedCheckbox';

export function TaskList({ destinationCountry = '' }: { destinationCountry?: 'US' | 'CA' | '' }) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const checked = useCheckList({ count: 4 });
  const { name, country } = useRandomVisa({ checked, country: destinationCountry });

  return (
    <div className="m-5 lg:m-0 xl:m-0">
      <div
        className="relative z-10 mt-24 w-full rounded-t-lg border border-gray-200 bg-white p-4 font-display text-gray-700 lg:static lg:z-0 lg:mt-0 lg:w-[500px] lg:rounded-lg lg:border-0 lg:p-10 lg:shadow-2xl lg:ring-1 lg:ring-inset lg:ring-gray-200 xl:mt-0 xl:w-[540px] xl:p-12"
        {...(!isMobile && { 'data-sal': 'slide-left', 'data-sal-delay': '500', 'data-sal-easing': 'ease' })}
      >
        <div className="flex flex-col-reverse items-start">
          <div className="space-y-1 text-base font-semibold lg:-mt-4 lg:text-xl xl:text-2xl">
            <div>{t('home.my')}</div>
            <div className="text-lg text-brand-blue lg:text-2xl xl:text-3xl">{name}</div>
            <div>{t('home.application')}</div>
          </div>
          <div className="self-end rounded-xl border-2 border-gray-400 py-1 px-3 text-xs text-gray-400 lg:text-sm">
            {country === 'US' ? t('common.usa') : t('home.canada')}
          </div>
        </div>
        <ul className="mt-10 mb-8 space-y-4 text-base font-semibold lg:mb-0 lg:text-xl xl:mt-10 xl:space-y-5 xl:text-2xl">
          <li className="relative pl-9 lg:pl-11">
            <AnimatedCheckbox checked={checked > 0} />
            {t('home.eligibilityConfirmation')}
          </li>
          <li className="relative pl-9 lg:pl-11">
            <AnimatedCheckbox checked={checked > 1} />
            {t('home.secureDocumentUpload')}
          </li>
          <li className="relative pl-9 lg:pl-11">
            <AnimatedCheckbox checked={checked > 2} />
            {t('home.simpleFeePayment')}
          </li>
          <li className="relative pl-9 lg:pl-11">
            <AnimatedCheckbox checked={checked > 3} />
            {t('home.submitToGovernment')}
          </li>
        </ul>
      </div>
    </div>
  );
}

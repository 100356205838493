import { EstimatedTime } from '@/components/pathways/EstimatedTime';
import { PathwayHero } from '@/components/pathways/PathwayHero';
import { ProcessForStudy } from '@/components/pathways/ProcessForStudy';
import Layout from '@/components/ui/layouts/Layout';
import ExternalLink from '@/components/ui/links/ExternalLink';
import SEO from '@/components/ui/seo/SEO';
import { gTag } from '@/utils/gTag';
import { useQueryParams } from '@/utils/useQueryParams';
import { useTranslation } from 'react-i18next';

export default function Study() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { queryParams } = useQueryParams({ language });
  return (
    <Layout>
      <main className="mt-10 mb-40">
        <SEO
          title={`SuperVisas | ${t('seo.canadianImmigrationPathway')}: ${t('seo.studyThenWorkThenImmigrate')}`}
          description={t('seo.areYouLookingToStudyAbroad')}
        />
        <PathwayHero title={t('pathways.studyThenWorkThenImmigrate')}>
          <>
            <p>{t('pathways.studyThenWorkThenImmigrateSummary1')}</p>
            <p>{t('pathways.studyThenWorkThenImmigrateSummary2')}</p>
          </>
        </PathwayHero>
        <EstimatedTime totalTime={`2.5-${t('common.year', { count: 6 })}`}>
          <ul className="space-y-4 text-base font-semibold text-gray-600 lg:text-lg">
            <li>
              {t('pathways.applyToAndBeAccepted')}
              <span className="ml-2 text-brand-blue">3-{t('common.month', { count: 6 })}</span>
            </li>
            <li>
              {t('pathways.getAStudyPermit')}
              <span className="ml-2 text-brand-blue">1-{t('common.month', { count: 3 })}</span>
            </li>
            <li>
              {t('pathways.completeYourStudy')}
              <span className="ml-2 text-brand-blue">
                {t('common.atLeast')} {t('common.month', { count: 8 })}
              </span>
            </li>
            <li>
              {t('pathways.getAWorkPermit')}
              <span className="ml-2 text-brand-blue">1-{t('common.month', { count: 3 })}</span>
            </li>
            <li className="space-y-1">
              <div>
                {t('pathways.applyForImmigration')}
                <span className="ml-2 text-brand-blue">24-{t('common.month', { count: 30 })}</span>
              </div>
              <p className="text-sm font-normal text-gray-500">{t('pathways.timelineForCanadianExpressEntry')}</p>
            </li>
          </ul>
        </EstimatedTime>
        <ProcessForStudy />
        <div className="mt-10 mb-20 flex justify-center">
          <ExternalLink
            to={`/applicant/signup?${queryParams}`}
            variant="primary"
            className="w-full text-center lg:w-[160px]"
            onClick={() => gTag('event', 'sign_up', { event_label: 'study' })}
          >
            {t('header.signUp')}
          </ExternalLink>
        </div>
      </main>
    </Layout>
  );
}

import Layout from '@/components/ui/layouts/Layout';
import { CanadaSummary } from '@/components/program/CanadaSummary';
import { ProgramHero } from '@/components/program/ProgramHero';
import { USSummary } from '@/components/program/USSummary';
import SEO from '@/components/ui/seo/SEO';
import { useTranslation } from 'react-i18next';

export default function Visas() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="mt-10">
        <SEO title={`SuperVisas | ${t('header.programs')}`} description={t('seo.hundredsOfVisaOptions')} />
        <div className="space-y-22 pb-22">
          <ProgramHero />
          <CanadaSummary />
          <USSummary />
        </div>
      </main>
    </Layout>
  );
}

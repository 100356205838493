import { useBreakpoints } from '@/utils/breakpoint';
import { CheckIcon } from '@heroicons/react/24/outline';

interface CheckListItemProps {
  title: string;
  description: string;
}
export function CheckListItem({ title, description }: CheckListItemProps) {
  const { isMobile } = useBreakpoints();
  return (
    <li {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}>
      <div className="flex items-center gap-1.5 font-display text-lg font-semibold text-brand-blue">
        <CheckIcon className="inline-block h-4 w-4 opacity-60 lg:mt-0.5" />
        <span>{title}</span>
      </div>
      <p className="break-keep-all-kr ml-5 mt-1.5">{description}</p>
    </li>
  );
}

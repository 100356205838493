import { Requirement } from '@/@types/program';
import { useTranslation } from 'react-i18next';
import { ProgramBadge } from './ProgramBadge';

interface NetWorthBadgeProps {
  requirements: Requirement[];
}

const INIT_VALUE = 9999;

export function NetWorthBadge({ requirements }: NetWorthBadgeProps) {
  const { t } = useTranslation();
  if (requirements.length === 0) {
    return null;
  }
  let minNetWorth = INIT_VALUE;
  requirements.forEach(({ net_worth_min: netWorthMin }) => {
    if (netWorthMin !== null) {
      minNetWorth = Math.min(minNetWorth, netWorthMin);
    }
  });

  // minNetWorth is in thousands of dollars
  // example: if minNetWorth is 1, it is actually $1,000
  if (minNetWorth === INIT_VALUE || minNetWorth === 0) {
    return null;
  }
  return <ProgramBadge type="text" name={`${t('common.netWorth')} ${minNetWorth}k`} />;
}

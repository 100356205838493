export const initialState = {
  programTypes: [],
};

type ProgramType = 'study' | 'work' | 'visitor' | 'immigrate' | 'additional_services';

export interface FilterState {
  programTypes: Array<ProgramType>;
}

export type FilterAction = { type: 'RESET'; payload?: never } | { type: 'SET_PROGRAM_TYPES'; payload?: ProgramType };

export function filterReducer(state: FilterState, action: FilterAction) {
  switch (action.type) {
    case 'SET_PROGRAM_TYPES':
      if (state.programTypes.includes(action.payload)) {
        return {
          ...state,
          programTypes: state.programTypes.filter((type) => type !== action.payload),
        };
      }
      return {
        ...state,
        programTypes: [...state.programTypes, action.payload],
      };

    case 'RESET':
      return initialState;
    default:
      return initialState;
  }
}

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { Trans, useTranslation } from 'react-i18next';
import queryString from 'query-string';

function BreadCrumbs() {
  const { t } = useTranslation();
  return (
    <nav className="container mx-auto rounded-lg px-4 text-gray-700" aria-label="Breadcrumb">
      <ul className="-ml-6 inline-flex flex-wrap items-center md:gap-x-1">
        <li className="inline-flex items-center">
          <LocalizedLink
            to="/partners/"
            className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
          >
            {t('header.partners')}
          </LocalizedLink>
        </li>
        <li className="inline-flex items-center">
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <LocalizedLink
              to="/partners/employers/"
              className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
            >
              {t('partner.employers')}
            </LocalizedLink>
          </div>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">
              {t('rippling.ripplingIntegration')}
            </span>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default function RipplingIntegration() {
  const location = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  return (
    <Layout>
      <main className="mt-10">
        <SEO
          title={`SuperVisas | ${t('seo.installationGuideForRippling')}`}
          description={t('seo.learnStepByStepHowToInstall')}
          image="https://www.supervisas.com/static/local/img/SuperVisas-Insatallation-Guiide-for-Rippling.png"
        />
        <div className="container mx-auto px-4">
          <BreadCrumbs />
          <div className="mt-20 mb-20 space-y-20 2xl:flex 2xl:justify-between 2xl:space-y-0">
            <article className="prose 2xl:flex-1">
              <h1 className="text-balance">{t('rippling.howToInstallSuperVisasAsAnAdministrator')}</h1>
              <ol>
                <li>
                  <Trans
                    i18nKey="rippling.searchForTheSuperVisasApp"
                    t={t}
                    components={[
                      <a href="https://app.rippling.com/dashboard" target="_blank" rel="noopener noreferrer" />,
                      <code />,
                    ]}
                  />
                  <a
                    href="https://app.rippling.com/app-shop/category/finance-legal__legal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <figure>
                      <StaticImage
                        src="../../../assets/img/rippling/legal.png"
                        alt="SuperVisas under legal category"
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <figcaption>https://app.rippling.com/app-shop/category/finance-legal__legal</figcaption>
                    </figure>
                  </a>
                </li>
                <li>
                  <Trans i18nKey="rippling.selectTheSuperVisasApp" t={t} components={[<code />]} />
                </li>
                <li>
                  {t('rippling.ifYouHaveASuperVisasPartner')}
                  <blockquote>
                    <Trans i18nKey="rippling.selectingCreateNewAccount" t={t} components={[<code />]} />
                  </blockquote>
                  <a href="https://app.rippling.com/app-shop/app/supervisas" target="_blank" rel="noopener noreferrer">
                    <figure>
                      <StaticImage
                        src="../../../assets/img/rippling/connect-account.png"
                        alt="Connect account"
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <figcaption>https://app.rippling.com/app-shop/app/supervisas</figcaption>
                    </figure>
                  </a>
                </li>
                <li>
                  <Trans i18nKey="rippling.followingTheRipplingWizard" t={t} components={[<code />]} />
                </li>
                <li>
                  {t('rippling.theSuperVisasSingleSingOn')}
                  <a href="https://app.rippling.com/dashboard" target="_blank" rel="noopener noreferrer">
                    <figure>
                      <StaticImage
                        src="../../../assets/img/rippling/sso.png"
                        alt="SuperVisas SSO"
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <figcaption>https://app.rippling.com/dashboard</figcaption>
                    </figure>
                  </a>
                </li>
                <li>{t('rippling.youCanFacilitateAndOverseeTheVisa')}</li>
                <li>{t('rippling.asAdminYouCanAddAnEmployee')}</li>
                <li>{t('rippling.asAdminYouCanAlsoAddVisaApplications')}</li>
              </ol>
              <blockquote>
                <Trans
                  i18nKey="rippling.companyAdminsDoNotHaveAccess"
                  t={t}
                  components={[<a className="whitespace-nowrap" href="mailto:support@supervisas.com" />]}
                />
              </blockquote>
              <p>
                <Trans
                  i18nKey="rippling.stillNotSureHowToDoIt"
                  t={t}
                  components={[
                    <a
                      href="https://share.vidyard.com/watch/CLRRa7SuehSdiKEAhXxX5Q"
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              </p>
            </article>
            <article className="prose 2xl:flex-1">
              <h2 className="text-[32px] font-extrabold text-balance">
                {t('rippling.howToUseSuperVisasAsAnEmployee')}
              </h2>
              <ol>
                <li>
                  <Trans
                    i18nKey="rippling.doYouHaveAccess"
                    t={t}
                    components={[
                      <a href="https://app.rippling.com/dashboard" target="_blank" rel="noopener noreferrer" />,
                    ]}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="rippling.alternativelyGoTo"
                    t={t}
                    components={[<a href={`/login?${queryParams}`} />, <code />]}
                  />
                  <blockquote>
                    <Trans
                      i18nKey="rippling.ifYouAreACompanyAdministrator"
                      t={t}
                      components={[<a className="whitespace-nowrap" href="mailto:support@supervisas.com" />]}
                    />
                  </blockquote>
                </li>
                <li>
                  {t('rippling.selectingTheSuperVisasIcon')}
                  <a href="https://app.rippling.com/dashboard" target="_blank" rel="noopener noreferrer">
                    <figure>
                      <StaticImage
                        src="../../../assets/img/rippling/employee.png"
                        alt="Rippling Dashboard"
                        placeholder="blurred"
                        layout="fullWidth"
                      />
                      <figcaption>https://app.rippling.com/dashboard</figcaption>
                    </figure>
                  </a>
                </li>
                <li>
                  <Trans
                    i18nKey="rippling.nowYouCanSearchForAVisa"
                    t={t}
                    components={[<a href="https://apply.supervisas.com/user/applications" />]}
                  />
                </li>
                <li>{t('rippling.createAnApplicationAndStart')}</li>
                <li>{t('rippling.youCanAlsoSchedule')}</li>
              </ol>
            </article>
          </div>
        </div>
      </main>
    </Layout>
  );
}

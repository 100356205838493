import { Trans, useTranslation } from 'react-i18next';

export default function HeroAbout() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-20 w-full px-4 text-center">
      <h1 className="inline font-display text-3xl font-bold lg:text-5xl">
        <Trans i18nKey="about.thePursuitOfHappiness" t={t} components={[<span className="text-brand-blue" />]} />
      </h1>
      <div className="prose mx-auto mt-10 max-w-[94ch]">
        <p className="break-keep-all-kr mx-auto !max-w-[60ch] text-balance">
          {t('about.thePursuitOfHappinessDescription1')}
        </p>
        <p className="break-keep-all-kr text-balance">{t('about.thePursuitOfHappinessDescription2')}</p>
        <p className="text-balance">{t('about.thePursuitOfHappinessDescription3')}</p>
      </div>
    </div>
  );
}

import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars3Icon } from '@heroicons/react/24/outline';

import { LanguageSwitcher } from '@/components/ui/i18n/LanguageSwitcher';
import ExternalLink from '@/components/ui/links/ExternalLink';
import { LocalizedLink } from 'gatsby-theme-i18n';
import Link from '@/components/ui/links/Link';
import { useBreakpoints } from '@/utils/breakpoint';
import { cn } from '@/utils/cn';
import { useCountry } from '@/utils/CountryContext';
import { SuperVisasLogo } from '../svg/SuperVisasLogo';
import { CountrySwitcher } from './CountrySwitcher';
import { Drawer, DrawerContent, DrawerTrigger } from '../Drawer';

export default function Header({ variant }: { variant: 'blue' | 'white' }) {
  const { country } = useCountry();
  const [internalVariant, setInternalVariant] = useState(variant);
  const listenScrollEvent = () => {
    if (window.scrollY > 10) {
      setInternalVariant('white');
    } else {
      setInternalVariant(variant);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  const location = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const { isMobile } = useBreakpoints();
  const homePath = { US: '/us/', CA: '/canada/' }[country] || '/';
  const visasPath = { US: '/visas-us/list/', CA: '/visas-canada/list/' }[country] || '/visas/';
  const blogPath = { US: '/blog/?topic=0&country=US', CA: '/blog/?topic=0&country=CA' }[country] || '/blog';

  return (
    <Drawer>
      <header className="sticky inset-x-0 top-0 z-30 w-screen">
        {isMobile && (
          <DrawerContent>
            <nav className="p-4 pb-10">
              <ul className="flex flex-col items-start gap-4 text-sm font-medium">
                <li>
                  <Link to={homePath} className="text-sm">
                    {t('header.home')}
                  </Link>
                </li>
                {country === 'CA' && (
                  <li>
                    <Link to="/canada/pricing/" partiallyActive className="text-sm">
                      {t('header.pricing')}
                    </Link>
                  </li>
                )}
                {country === 'US' && (
                  <li>
                    <Link to="/us/pricing/" partiallyActive className="text-sm">
                      {t('header.pricing')}
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={visasPath} partiallyActive className="text-sm">
                    {t('header.programs')}
                  </Link>
                </li>
                {country === 'CA' && (
                  <li>
                    <Link to="/pathways/" partiallyActive className="text-sm">
                      {t('header.pathways')}
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/partners/" partiallyActive className="text-sm">
                    {t('header.partners')}
                  </Link>
                </li>
                <li>
                  <Link to="/about/" partiallyActive className="text-sm">
                    {t('header.aboutUs')}
                  </Link>
                </li>
                <li>
                  <Link to={blogPath} partiallyActive className="text-sm">
                    {t('header.blog')}
                  </Link>
                </li>
                <div className="mt-3 w-full border-t border-gray-200 pt-3">
                  <div className="flex w-full items-center justify-between">
                    <div className="flex-1 font-normal text-gray-500">{t('header.country')}</div>
                    <CountrySwitcher variant="white" className="w-auto text-sm" />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex-1 font-normal text-gray-500">{t('header.switchLanguage')}</div>
                    <LanguageSwitcher variant="white" className="w-auto text-sm" />
                  </div>
                </div>
                <li className="mt-22 w-full">
                  <ExternalLink
                    variant="primary"
                    to={`/login?${queryParams}`}
                    className="!block w-full !px-6 text-center"
                  >
                    {t('header.login')}
                  </ExternalLink>
                  <ExternalLink
                    variant="outline"
                    to={`/applicant/signup?${queryParams}`}
                    className="mt-2 !block w-full whitespace-nowrap !px-6 text-center"
                  >
                    {t('header.signUp')}
                  </ExternalLink>
                </li>
              </ul>
            </nav>
          </DrawerContent>
        )}
        <div className={cn('absolute opacity-20', { 'inset-0 bg-conic-gradient blur': internalVariant === 'white' })} />
        <div
          className={cn(
            'relative py-4 backdrop-blur-lg transition-colors duration-200 ease-in-out',
            internalVariant === 'white' ? 'bg-white/90' : 'bg-brand-sky',
          )}
        >
          <div className="container mx-auto flex items-center justify-between px-4">
            <LocalizedLink to={homePath} className={cn(internalVariant === 'white' ? 'text-brand-blue' : 'text-white')}>
              <SuperVisasLogo className="h-[32px] text-inherit lg:h-[50px]" />
            </LocalizedLink>
            <nav className="hidden bg-transparent lg:block">
              <ul className="flex flex-col items-center space-y-6 space-x-0 font-medium lg:flex-row lg:space-y-0 lg:space-x-6">
                {country === 'CA' && (
                  <li>
                    <Link
                      to="/canada/pricing/"
                      partiallyActive
                      className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                        'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                          internalVariant === 'blue',
                      })}
                    >
                      {t('header.pricing')}
                    </Link>
                  </li>
                )}
                {country === 'US' && (
                  <li>
                    <Link
                      to="/us/pricing/"
                      partiallyActive
                      className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                        'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                          internalVariant === 'blue',
                      })}
                    >
                      {t('header.pricing')}
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to={visasPath}
                    partiallyActive
                    className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                      'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                        internalVariant === 'blue',
                    })}
                  >
                    {t('header.programs')}
                  </Link>
                </li>
                {country === 'CA' && (
                  <li>
                    <Link
                      to="/pathways/"
                      partiallyActive
                      className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                        'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                          internalVariant === 'blue',
                      })}
                    >
                      {t('header.pathways')}
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="/partners/"
                    partiallyActive
                    className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                      'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                        internalVariant === 'blue',
                    })}
                  >
                    {t('header.partners')}
                  </Link>
                </li>
                <li
                  className={cn({
                    'lg:hidden xl:inline': country === 'CA',
                  })}
                >
                  <Link
                    to="/about/"
                    partiallyActive
                    className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                      'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                        internalVariant === 'blue',
                    })}
                  >
                    {t('header.aboutUs')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={blogPath}
                    partiallyActive
                    className={cn('rounded-md p-1 transition-colors duration-200 ease-in-out', {
                      'p-1 text-white hover:text-white hover:underline focus:text-white focus:outline-white':
                        internalVariant === 'blue',
                    })}
                  >
                    {t('header.blog')}
                  </Link>
                </li>
              </ul>
            </nav>
            <DrawerTrigger
              className={cn(
                'm-0 flex items-center justify-center border-0 bg-transparent py-2 pl-4 text-xl normal-case lg:hidden',
                { 'text-white': internalVariant === 'blue' },
              )}
            >
              <Bars3Icon className="h-6 w-6 text-inherit" />
            </DrawerTrigger>
            <div className="hidden lg:flex lg:items-center lg:gap-x-2">
              <CountrySwitcher variant={internalVariant} className="w-auto" />
              <LanguageSwitcher variant={internalVariant} className="w-auto" />
              <ExternalLink
                variant="primary"
                to={`/login?${queryParams}`}
                className={cn(
                  'mr-2 whitespace-nowrap',
                  internalVariant === 'blue'
                    ? 'border-white bg-white text-brand-sky hover:border-blue-50 hover:bg-blue-50 hover:text-brand-sky focus:bg-blue-50 focus:text-brand-sky'
                    : '',
                )}
              >
                {t('header.login')}
              </ExternalLink>
              <ExternalLink
                variant="outline"
                to={`/applicant/signup?${queryParams}`}
                className={cn(
                  'whitespace-nowrap',
                  internalVariant === 'blue'
                    ? 'border-white !bg-brand-sky text-white hover:!bg-brand-blue hover:text-white'
                    : '',
                )}
              >
                {t('header.signUp')}
              </ExternalLink>
            </div>
          </div>
        </div>
      </header>
    </Drawer>
  );
}

export const initialState = {
  programTypes: [],
  jobOfferNeeded: undefined,
  languageAbility: undefined,
  moneyAvailable: undefined,
};

type ProgramType = 'study' | 'work' | 'visitor' | 'immigrate';

/*
  +------------------------------+-----+
  |       Language Ability       | CLB |
  +------------------------------+-----+
  | NO_ENGLISH                   | 0~3 |
  | ELEMENTARY_ENGLISH           | 4~5 |
  | LIMITED_WORKING_ENGLISH      | 6   |
  | PROFESSIONAL_WORKING_ENGLISH | 7~8 |
  | FULL_PROFESSIONAL_ENGLISH    | 9   |
  | NATIVE_BILINGUAL_ENGLISH     | 10+ |
  +------------------------------+-----+
  */
export enum LanguageAbility {
  NO_ENGLISH = 3,
  ELEMENTARY_ENGLISH = 5,
  LIMITED_WORKING_ENGLISH = 6,
  PROFESSIONAL_WORKING_ENGLISH = 8,
  FULL_PROFESSIONAL_ENGLISH = 9,
  NATIVE_BILINGUAL_ENGLISH = 10,
}

export interface FilterState {
  programTypes: Array<ProgramType>;
  jobOfferNeeded: boolean | undefined;
  languageAbility: LanguageAbility | undefined;
  moneyAvailable: number | undefined;
}

export type FilterAction =
  | { type: 'RESET'; payload?: never }
  | { type: 'SET_PROGRAM_TYPES'; payload?: ProgramType }
  | { type: 'SET_JOB_OFFER_NEEDED'; payload?: boolean }
  | {
      type: 'SET_LANGUAGE_ABILITY';
      payload?: LanguageAbility;
    }
  | {
      type: 'SET_MONEY_AVAILABLE';
      payload?: number;
    };

export function filterReducer(state: FilterState, action: FilterAction) {
  switch (action.type) {
    case 'SET_PROGRAM_TYPES':
      if (state.programTypes.includes(action.payload)) {
        return {
          ...state,
          programTypes: state.programTypes.filter((type) => type !== action.payload),
        };
      }
      return {
        ...state,
        programTypes: [...state.programTypes, action.payload],
      };

    case 'SET_JOB_OFFER_NEEDED':
      if (state.jobOfferNeeded === action.payload) {
        return {
          ...state,
          jobOfferNeeded: undefined,
        };
      }
      return {
        ...state,
        jobOfferNeeded: action.payload,
      };

    case 'SET_LANGUAGE_ABILITY':
      if (state.languageAbility === action.payload) {
        return {
          ...state,
          languageAbility: undefined,
        };
      }
      return {
        ...state,
        languageAbility: action.payload,
      };

    case 'SET_MONEY_AVAILABLE':
      return {
        ...state,
        moneyAvailable: action.payload,
      };

    case 'RESET':
      return initialState;
    default:
      return initialState;
  }
}

import { useBreakpoints } from '@/utils/breakpoint';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'vaul';
import Button from '../ui/buttons/Button';

export function FilterWrapper({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  if (isMobile) {
    return (
      <Drawer.Root>
        <Drawer.Trigger asChild>
          <Button variant="secondary">
            <AdjustmentsHorizontalIcon className="mr-1 inline-block h-5 w-5 align-text-bottom" />
            {t('common.filterBy')}
          </Button>
        </Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Content className="fixed bottom-0 left-0 right-0 z-[32] mt-24 flex flex-col rounded-t-[10px] bg-zinc-100">
            {children}
          </Drawer.Content>
          <Drawer.Overlay className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-[31] bg-gray-800/80 backdrop-blur-sm" />
        </Drawer.Portal>
      </Drawer.Root>
    );
  }
  return <>{children}</>;
}

import { CheckListItem } from '@/components/partner/CheckListItem';
import { PartnerFeatures } from '@/components/partner/PartnerFeatures';
import Layout from '@/components/ui/layouts/Layout';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import SEO from '@/components/ui/seo/SEO';

function BreadCrumbs() {
  const { t } = useTranslation();
  return (
    <nav className="container mx-auto rounded-lg px-4 text-gray-700" aria-label="Breadcrumb">
      <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
        <li className="inline-flex items-center">
          <LocalizedLink
            to="/partners/"
            className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
          >
            {t('header.partners')}
          </LocalizedLink>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{t('partner.schools')}</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default function Schools() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main className="mt-10">
        <SEO title={`SuperVisas | ${t('seo.schools')}`} description={t('partner.forSchoolsDescription')}>
          <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
        </SEO>
        <div className="container mx-auto px-4">
          <BreadCrumbs />
          <div className="mt-20 mb-20 flex flex-col gap-16 lg:mx-auto lg:max-w-[1200px] lg:flex-row">
            <div className="w-full lg:min-w-[420px] lg:flex-1">
              <StaticImage
                src="../../assets/img/partner/schools.jpg"
                alt="schools"
                placeholder="blurred"
                layout="fullWidth"
              />
              <div className="mt-12 flex flex-col items-center">
                <h3 className="inline-block text-2xl font-semibold uppercase text-brand-blue">
                  {t('partner.trustedBy')}
                </h3>
                <a href="https://metalworksinstitute.com/" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    class="mt-5 w-[300px] lg:w-[406px]"
                    src="../../assets/img/partner/MetalWorksInstitute.png"
                    alt="METALWORKS INSTITUTE"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </a>
              </div>
            </div>
            <div className="text-gray-500 lg:flex-1">
              <h1 className="font-display text-lg font-bold text-gray-800 lg:text-3xl">{t('partner.schools')}</h1>
              <p className="mt-4">{t('partner.manyInternationalStudents')}</p>
              <ul className="mt-10 space-y-6">
                <CheckListItem
                  title={t('partner.fewerDeferrals')}
                  description={t('partner.fewerDeferralsDescription')}
                />
                <CheckListItem
                  title={t('partner.betterExperienceForYourStudents')}
                  description={t('partner.betterExperienceForYourStudentsDescription')}
                />
                <CheckListItem
                  title={t('partner.supportAfterGraduation')}
                  description={t('partner.supportAfterGraduationDescription')}
                />
                <CheckListItem title={t('partner.itIsSimple')} description={t('partner.itIsSimpleDescription')} />
                <CheckListItem
                  title={t('partner.trackApplicationStatus')}
                  description={t('partner.trackApplicationStatusDescription')}
                />
              </ul>
            </div>
          </div>
        </div>
        <PartnerFeatures />
        <div
          className="meetings-iframe-container mt-20"
          data-src="https://meetings.hubspot.com/joe-chen/demo?embed=true"
        />
      </main>
    </Layout>
  );
}

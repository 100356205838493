import React from 'react';

export function TrustBox() {
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot?.loadFromElement?.(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget mb-20 lg:mb-40"
      data-locale="en-US"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5fad88459a4f790001addb47"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://www.trustpilot.com/review/supervisas.com" target="_blank" rel="noopener noreferrer">
        {' '}
        Trustpilot
      </a>
    </div>
  );
}

import { LocalizedLink } from 'gatsby-theme-i18n';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { ProgramSummary } from '@/@types/program';
import { ProgramBadge } from './ProgramBadge';
import { NetWorthBadge } from './NetWorthBadge';
import { LanguageAbilityBadge } from './LanguageAbilityBadge';
import { JobOfferNeededBadge } from './JobOfferNeededBadge';

interface ProgramCardProps {
  program: ProgramSummary;
  jobOfferNeeded: boolean | undefined;
}

export function ProgramCard({ program, jobOfferNeeded }: ProgramCardProps) {
  const {
    id,
    slug,
    name,
    country: { iso_code: countryCode },
    program_type_name: programTypeName,
    program_type: programType,
    plain_text_summary: summary,
    requirements,
  } = program;
  return (
    <LocalizedLink
      to={`/visas/${slug}/`}
      className="group flex flex-col justify-between rounded-lg border-2 border-gray-200 px-4 pt-4 pb-8 hover:border-brand-blue hover:bg-blue-50/40"
      key={id}
    >
      <div className="mt-4 text-base font-semibold leading-tight text-gray-700">{name}</div>
      <div className="pt-4">
        <p className="mb-4 text-sm text-gray-400 line-clamp-2" title={summary}>
          {summary}
        </p>
        <div className="flex flex-wrap gap-1">
          <ProgramBadge type={programType} name={programTypeName} />
          <JobOfferNeededBadge requirements={requirements} jobOfferNeeded={jobOfferNeeded} />
          <NetWorthBadge requirements={requirements} />
          {countryCode === 'CA' && <LanguageAbilityBadge requirements={requirements} />}
        </div>
        <div className="relative h-5 w-full">
          <ArrowRightCircleIcon className="absolute right-0 h-8 w-8 text-brand-blue/80 lg:hidden lg:group-hover:inline-block lg:group-focus:inline-block" />
        </div>
      </div>
    </LocalizedLink>
  );
}

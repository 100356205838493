import { Trans, useTranslation } from 'react-i18next';

export function ProgramHero() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-20 px-4">
      <div className="text-center">
        <h1 className="font-display text-3xl font-bold lg:text-5xl">
          <Trans
            i18nKey="translation:programs.clearPathToImmigration"
            components={[<span className="text-brand-blue" />]}
          />
        </h1>
        <p className="break-keep-all-kr my-6 mx-auto text-gray-500 md:max-w-[80ch]">
          {t('programs.clearPathToImmigrationDescription')}
        </p>
      </div>
    </div>
  );
}

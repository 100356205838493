import { useState } from 'react';
import { useLocation } from '@reach/router';
import FAQTopics from '@/views/faq/components/FAQTopics';
import FAQSidebar from '@/views/faq/components/FAQSidebar';
import { FAQSection, FAQCategory } from '@/@types/faq';

interface FAQOverviewProps {
  faqSections: FAQSection[];
  faqCategoryList: FAQCategory[];
}

function FAQOverview({ faqSections, faqCategoryList }: FAQOverviewProps) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const categoryQueryParam = query.get('category');
  const sectionQueryParam = query.get('section');

  let selectedSection: FAQSection;
  if (categoryQueryParam) {
    selectedSection = faqSections.find((section) => section.category === categoryQueryParam) || faqSections[0];
  } else {
    selectedSection = faqSections.find((section) => section.slug === sectionQueryParam) || faqSections[0];
  }

  const [sectionSlug, setSectionSlug] = useState<string>(selectedSection.slug);
  selectedSection = faqSections.find((section) => section.slug === sectionSlug);

  return (
    <div className="container mx-auto mb-8">
      <section className="lg:my-8 lg:flex">
        <div className="mt-7 lg:w-1/4">
          {faqCategoryList.map((category) => (
            <FAQSidebar
              key={`sidebar-${category.slug}`}
              sectionSlug={sectionSlug}
              setSectionSlug={setSectionSlug}
              category={category}
              openCategory={category.slug === selectedSection.category}
            />
          ))}
        </div>
        <FAQTopics
          sectionSlug={sectionSlug}
          categorySlug={selectedSection.category}
          topics={faqSections.filter((s) => s.slug === sectionSlug)[0].topics}
        />
      </section>
    </div>
  );
}

export default FAQOverview;

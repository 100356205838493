import { graphql, PageProps } from 'gatsby';
import { useDeferredValue, useState } from 'react';
import { useFuse } from '@/utils/fuse';
import Layout from '@/components/ui/layouts/Layout';
import FAQTopicOverview from '@/views/faq/FAQTopicOverview';
import SEO from '@/components/ui/seo/SEO';
import FAQHeader from '@/views/faq/components/FAQHeader';
import FAQSearchResults from '@/views/faq/components/FAQSearchResults';
import { FAQ, FAQSection, FAQTopic as Topic } from '@/@types/faq';

interface FAQTopicData {
  faqTopic: Topic;
  allFaqSections: { edges: { node: { faqSections: FAQSection[] } }[] };
  allFaq: { edges: { node: FAQ }[] };
  allCategory: { edges: { node: { slug: string; title: string } }[] };
}

const options = {
  keys: ['title'],
  threshold: 0.6,
  distance: 300,
};

function FAQTopic({
  data: {
    faqTopic,
    allFaqSections: { edges: faqSectionNodes },
    allFaq: { edges },
    allCategory,
  },
  pageContext: { metaDescription },
}: PageProps<FAQTopicData, { metaDescription: string }>) {
  const [userInput, setUserInput] = useState<string>('');
  const value = useDeferredValue(userInput);

  const { faqSections } = faqSectionNodes[0].node;

  const faqs = edges.map((e) => e.node);
  const matchedFaqs = useFuse(faqs, value, options);

  const section = faqSections.find((s) => s.slug === faqTopic.section);
  const category = allCategory.edges.find((edge) => edge.node.slug === section.category).node;

  return (
    <Layout>
      <main id="program-landing-page">
        <SEO title={`SuperVisas | ${faqTopic.title}`} description={metaDescription} />
        <div className="bg-white">
          <FAQHeader
            title={faqTopic.title}
            category={category}
            section={section}
            topic={faqTopic.title}
            onUserInputChange={setUserInput}
          />
          {value.length ? (
            <FAQSearchResults allSections={faqSections} faqs={matchedFaqs} />
          ) : (
            <FAQTopicOverview category={category} section={section} faqTopic={faqTopic} />
          )}
        </div>
      </main>
    </Layout>
  );
}

export default FAQTopic;

export const query = graphql`
  query FAQTopicTemplate($topicSlug: String!) {
    faqTopic(slug: { eq: $topicSlug }) {
      slug
      title
      section
      faqs {
        answer
        slug
        title
      }
    }
    allFaqSections {
      edges {
        node {
          faqSections {
            category
            slug
            title
            topics {
              title
              slug
            }
          }
        }
      }
    }
    allFaq {
      edges {
        node {
          title
          slug
          topicSlug
        }
      }
    }
    allCategory {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

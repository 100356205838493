import { StaticImage } from 'gatsby-plugin-image';

export function FlagAU() {
  return (
    <StaticImage
      src="../../../assets/img/svg/au.svg"
      alt="Australia Flag"
      placeholder="blurred"
      layout="fixed"
      width={18}
      height={18}
    />
  );
}

import { Trans, useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { CanadaProgramIndex } from '@/@types/program-index';
import Link from '../ui/links/Link';

const PROGRAMS_QUERY = graphql`
  query {
    programs(language: { eq: "en" }) {
      id
      canada_programs {
        id
        slug
      }
      us_programs {
        id
        slug
      }
    }
  }
`;

export function ProcessForInvest() {
  const { t } = useTranslation();
  const {
    programs: { canada_programs: canadaPrograms },
  } = useStaticQuery(PROGRAMS_QUERY);
  const idToCanadaSlugMap = canadaPrograms.reduce((acc, { id: programId, slug }) => {
    acc[programId] = slug;
    return acc;
  }, {});
  /*
    The fallback is for non-production environments.
    In production, the program corresponding to hardcoded program ID always exists, but not in non-production environments. 
  */
  function getCanadaSlugById(id: number) {
    return idToCanadaSlugMap[id] || canadaPrograms[0].slug;
  }
  return (
    <div className="container mx-auto mt-20 px-4">
      <h2 className="text-2xl font-semibold text-gray-700 lg:text-3xl">{t('pathways.whatIsTheProcess')}</h2>
      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.investInABusiness')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.investInABusinessDescription1')}</p>
          <ul>
            <li>{t('pathways.investInABusinessDescription2')}</li>
            <li>{t('pathways.investInABusinessDescription3')}</li>
            <li>{t('pathways.investInABusinessDescription4')}</li>
          </ul>
          <p>{t('pathways.investInABusinessDescription5')}</p>
          <p>{t('pathways.investInABusinessDescription6')}</p>
        </div>
      </section>

      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.getAWorkPermit')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.investGetAWorkPermitDescription1')}</p>
          <p>{t('pathways.investGetAWorkPermitDescription2')}</p>

          <h4>{t('pathways.workGetAWorkPermitDescription3')}</h4>
          <p>{t('pathways.workGetAWorkPermitDescription4')}</p>
          <ul>
            <li>
              <Trans
                i18nKey="pathways.investGetAWorkPermitDescription3"
                t={t}
                components={[
                  <Link
                    /* 23: Employer-specific Work Permit */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.EMPLOYER_SPECIFIC_WORK_PERMIT)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.workGetAWorkPermitDescription8"
                t={t}
                components={[
                  <Link
                    /* 254: C11 Significant Benefit Work Permit */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.C11_SIGNIFICANT_BENEFIT_WORK_PERMIT)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.workGetAWorkPermitDescription6"
                t={t}
                components={[
                  <Link
                    /* 222: CUSMA T24 Work Permit */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.CUSMA_T23_T24_WORK_PERMIT)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.workGetAWorkPermitDescription7"
                t={t}
                components={[
                  <Link
                    /* 25: Intra-Company Transferee (ICT) Work Permit */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.ICT_WORK_PERMIT)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
          </ul>
          <p>{t('pathways.investGetAWorkPermitDescription4')}</p>
        </div>
      </section>

      <section className="mb-20">
        <h3 className="my-4 text-xl font-semibold uppercase text-brand-blue">{t('pathways.applyForImmigration')}</h3>
        <div className="prose max-w-none">
          <p>{t('pathways.investApplyForImmigrationDescription1')}</p>
          <p>{t('pathways.workApplyForImmigrationDescription2')}</p>
          <p>{t('pathways.workApplyForImmigrationDescription3')}</p>
          <p>{t('pathways.investApplyForImmigrationDescription2')}</p>
          <ul>
            <li>
              <Trans
                i18nKey="pathways.investApplyForImmigrationDescription3"
                t={t}
                components={[
                  <Link
                    /* 42: Express Entry */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.EXPRESS_ENTRY)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.investApplyForImmigrationDescription4"
                t={t}
                components={[
                  <Link
                    /* Provincial Nomination Programs - Entrepreneurship is a group of programs */
                    to="/visas-canada/list"
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.workApplyForImmigrationDescription6"
                t={t}
                components={[
                  <Link
                    /* 51: Alberta Opportunity Stream */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.ALBERTA_OPPORTUNITY_STREAM)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="pathways.workApplyForImmigrationDescription7"
                t={t}
                components={[
                  <Link
                    /* 71: Skilled Workers in Manitoba- Temporary Foreign Workers */
                    to={`/visas/${getCanadaSlugById(CanadaProgramIndex.SKILLED_WORKERS_IN_MANITOBA_TFW)}`}
                    className="!text-brand-blue !no-underline hover:!text-brand-blue-dark hover:!underline"
                  />,
                ]}
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-canada-index-tsx": () => import("./../../../src/pages/canada/index.tsx" /* webpackChunkName: "component---src-pages-canada-index-tsx" */),
  "component---src-pages-canada-pricing-tsx": () => import("./../../../src/pages/canada/pricing.tsx" /* webpackChunkName: "component---src-pages-canada-pricing-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-accelerators-tsx": () => import("./../../../src/pages/partners/accelerators.tsx" /* webpackChunkName: "component---src-pages-partners-accelerators-tsx" */),
  "component---src-pages-partners-agencies-tsx": () => import("./../../../src/pages/partners/agencies.tsx" /* webpackChunkName: "component---src-pages-partners-agencies-tsx" */),
  "component---src-pages-partners-employers-index-tsx": () => import("./../../../src/pages/partners/employers/index.tsx" /* webpackChunkName: "component---src-pages-partners-employers-index-tsx" */),
  "component---src-pages-partners-employers-rippling-integration-tsx": () => import("./../../../src/pages/partners/employers/rippling-integration.tsx" /* webpackChunkName: "component---src-pages-partners-employers-rippling-integration-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-partners-schools-tsx": () => import("./../../../src/pages/partners/schools.tsx" /* webpackChunkName: "component---src-pages-partners-schools-tsx" */),
  "component---src-pages-pathways-index-tsx": () => import("./../../../src/pages/pathways/index.tsx" /* webpackChunkName: "component---src-pages-pathways-index-tsx" */),
  "component---src-pages-pathways-invest-tsx": () => import("./../../../src/pages/pathways/invest.tsx" /* webpackChunkName: "component---src-pages-pathways-invest-tsx" */),
  "component---src-pages-pathways-study-tsx": () => import("./../../../src/pages/pathways/study.tsx" /* webpackChunkName: "component---src-pages-pathways-study-tsx" */),
  "component---src-pages-pathways-work-tsx": () => import("./../../../src/pages/pathways/work.tsx" /* webpackChunkName: "component---src-pages-pathways-work-tsx" */),
  "component---src-pages-us-index-tsx": () => import("./../../../src/pages/us/index.tsx" /* webpackChunkName: "component---src-pages-us-index-tsx" */),
  "component---src-pages-us-pricing-tsx": () => import("./../../../src/pages/us/pricing.tsx" /* webpackChunkName: "component---src-pages-us-pricing-tsx" */),
  "component---src-pages-visas-canada-index-tsx": () => import("./../../../src/pages/visas-canada/index.tsx" /* webpackChunkName: "component---src-pages-visas-canada-index-tsx" */),
  "component---src-pages-visas-canada-list-tsx": () => import("./../../../src/pages/visas-canada/list.tsx" /* webpackChunkName: "component---src-pages-visas-canada-list-tsx" */),
  "component---src-pages-visas-index-tsx": () => import("./../../../src/pages/visas/index.tsx" /* webpackChunkName: "component---src-pages-visas-index-tsx" */),
  "component---src-pages-visas-us-index-tsx": () => import("./../../../src/pages/visas-us/index.tsx" /* webpackChunkName: "component---src-pages-visas-us-index-tsx" */),
  "component---src-pages-visas-us-list-tsx": () => import("./../../../src/pages/visas-us/list.tsx" /* webpackChunkName: "component---src-pages-visas-us-list-tsx" */),
  "component---src-templates-faq-topic-tsx": () => import("./../../../src/templates/FAQTopic.tsx" /* webpackChunkName: "component---src-templates-faq-topic-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/FAQ.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-post-detail-tsx": () => import("./../../../src/templates/PostDetail.tsx" /* webpackChunkName: "component---src-templates-post-detail-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/Program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */)
}


import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
import RightArrow from '@/views/faq/components/RightArrow';
import { FAQTopic, FAQSection } from '@/@types/faq';

interface FAQTopicOverviewProps {
  category: { slug: string; title: string };
  section: FAQSection;
  faqTopic: FAQTopic;
}

function FAQTopicOverview({ category, section, faqTopic }: FAQTopicOverviewProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const topicSlug = pathname.split('/').slice(-2, -1);

  return (
    <div className="container mx-auto my-12 divide-y divide-x-0 divide-solid divide-brand-gray px-4">
      {faqTopic.faqs.length ? (
        faqTopic.faqs.map((faq) => (
          <div key={`faq-${faq.slug}`} className="py-8 text-lg font-semibold lg:text-xl">
            <Link className="text-brand-dark" to={`/faq/${category.slug}/${section.slug}/${topicSlug}/${faq.slug}/`}>
              <RightArrow />
              {faq.title}
            </Link>
          </div>
        ))
      ) : (
        <div className="font-semibold">{t('faq.noQuestions')}</div>
      )}
    </div>
  );
}

export default FAQTopicOverview;

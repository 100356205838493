import { useTranslation } from 'react-i18next';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { FAQSection } from '@/@types/faq';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

interface FAQHeaderProps {
  title: string;
  subTitle?: string;
  category?: { slug: string; title: string };
  section?: FAQSection;
  topic?: string;
  onUserInputChange: React.Dispatch<React.SetStateAction<string>>;
}

function FAQHeader({ title, subTitle, category, section, topic, onUserInputChange }: FAQHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-brand-blue py-12 text-center leading-8 text-white">
      {section && topic && (
        <div className="container mx-auto mb-8 px-4 text-left font-normal">
          <Link className="hover:underline" to="/faq/">
            {t('header.faq')}
          </Link>{' '}
          <ChevronRightIcon className="inline-block h-5 w-5 select-none text-blue-300" />{' '}
          <Link className="hover:underline" to={`/faq/?category=${category.slug}`}>
            {category.title}
          </Link>{' '}
          <ChevronRightIcon className="inline-block h-5 w-5 select-none text-blue-300" />{' '}
          <Link className="hover:underline" to={`/faq/?section=${section.slug}`}>
            {section.title}
          </Link>
          <span>
            {' '}
            <ChevronRightIcon className="inline-block h-5 w-5 select-none text-blue-300" />{' '}
            <span className="text-blue-200">{topic}</span>
          </span>
        </div>
      )}
      <div className="container mx-auto px-4">
        <h1 className="font-display text-3xl font-medium text-brand-gray-light lg:text-4xl">{title}</h1>
        {subTitle && <div className="m-auto mt-3 max-w-sm text-sm font-normal lg:text-lg">{subTitle}</div>}
        <input
          className="border-grey-50 placeholder-brand-gray-dark mt-8 w-full border py-2 pr-16 pl-4 text-brand-dark placeholder-opacity-30 lg:w-1/2 lg:py-4 lg:pl-6 lg:pr-16"
          placeholder={t('faq.search')}
          onChange={(e) => {
            onUserInputChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default FAQHeader;

import { BlogAuthor } from '@/@types/blog';

interface AuthorProps {
  author: BlogAuthor;
}

export function Author({ author: { title, name, image } }: AuthorProps) {
  return (
    <div className="not-prose flex items-center gap-2">
      <img src={image} alt={name} className="h-12 w-12 shrink-0 rounded-full" />
      <div className="flex flex-col gap-1">
        <span className="text-xs leading-tight text-slate-600 lg:text-sm">{title}</span>
        <span className="text-sm font-semibold leading-tight text-slate-900 lg:text-base">{name}</span>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { gTag } from '@/utils/gTag';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import ExternalLink from '../ui/links/ExternalLink';
import { ProgramType } from '../../@types/ProgramData';

interface ProgramHeaderProps {
  name: string;
  plainTextSummary: string;
  countryCode: 'CA' | 'US';
  programType: ProgramType;
  programId: number;
  description: string;
}

function TableOfContentsMobile() {
  const { t } = useTranslation();
  return (
    <ul className="container mx-auto flex flex-col gap-2 px-4 lg:hidden">
      <li className="font-semibold lg:hidden">{t('programDetail.tableOfContents')}</li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#general-info-mobile')} className="flex items-center gap-2">
          {t('programDetail.generalInformation')} <ArrowDownCircleIcon className="inline-block h-5 w-5" />
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#pricing-desktop')} className="flex items-center gap-2">
          {t('programs.pricing')}
          <ArrowDownCircleIcon className="inline-block h-5 w-5" />
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#how-it-works-mobile')} className="flex items-center gap-2">
          {t('programs.howItWorks')}
          <ArrowDownCircleIcon className="inline-block h-5 w-5" />
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#success-rate-desktop')} className="flex items-center gap-2">
          {t('common.successRate')}
          <ArrowDownCircleIcon className="inline-block h-5 w-5" />
        </button>
      </li>
      <li className="ml-4 lg:ml-0" id="general-info-mobile">
        <button
          type="button"
          onClick={() => scrollTo('#required-documents-desktop')}
          className="flex items-center gap-2"
        >
          {t('programs.requiredDocuments')}
          <ArrowDownCircleIcon className="inline-block h-5 w-5" />
        </button>
      </li>
    </ul>
  );
}

function TableOfContentsDesktop() {
  const { t } = useTranslation();
  return (
    <ul className="container mx-auto hidden px-4 lg:flex lg:flex-row lg:items-center lg:gap-10">
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#general-info-desktop')}>
          {t('programDetail.generalInformation')}
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#pricing-desktop')}>
          {t('programs.pricing')}
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#how-it-works-desktop')}>
          {t('programs.howItWorks')}
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#success-rate-desktop')}>
          {t('common.successRate')}
        </button>
      </li>
      <li className="ml-4 lg:ml-0">
        <button type="button" onClick={() => scrollTo('#required-documents-desktop')}>
          {t('programs.requiredDocuments')}
        </button>
      </li>
    </ul>
  );
}

export function ProgramHeader({
  name,
  plainTextSummary,
  countryCode,
  programType,
  programId,
  description,
}: ProgramHeaderProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const showCheckMyEligibility = !['sponsor_employee', 'sponsor_family', 'additional_services'].includes(programType);
  return (
    <>
      <nav className="mb-16 bg-brand-blue py-6 text-white lg:py-4" id="general-info-desktop">
        <TableOfContentsMobile />
        <TableOfContentsDesktop />
      </nav>
      <div className="container mx-auto px-4">
        <div className="flex justify-between lg:items-center lg:gap-6">
          <h1 className="text-3xl font-semibold text-brand-blue">{name}</h1>
          <div className="hidden shrink-0 gap-4 lg:flex">
            {programType !== 'additional_services' && (
              <ExternalLink
                variant="primary"
                to={`/start_new_application?program_id=${programId}&${queryParams}`}
                onClick={() => gTag('event', 'start_an_application')}
                className="w-full text-center lg:w-auto lg:min-w-[184px]"
              >
                {t('common.startAnApplication')}
              </ExternalLink>
            )}
            {showCheckMyEligibility && (
              <ExternalLink
                variant="outline"
                to={`/eligibility?country=${countryCode}&type=${programType}&${queryParams}`}
                onClick={() => gTag('event', 'check_my_eligibility')}
                className="w-full text-center lg:w-auto lg:min-w-[184px]"
              >
                {t('common.checkMyEligibility')}
              </ExternalLink>
            )}
          </div>
        </div>
        <p className="my-6 leading-snug text-gray-500 lg:leading-normal">{plainTextSummary}</p>
        <div className="my-16 flex flex-col gap-4 lg:hidden">
          {programType !== 'additional_services' && (
            <ExternalLink
              variant="primary"
              to={`/start_new_application?program_id=${programId}&${queryParams}`}
              onClick={() => gTag('event', 'start_an_application')}
              className="w-full text-center lg:w-auto"
            >
              {t('common.startAnApplication')}
            </ExternalLink>
          )}
          {showCheckMyEligibility && (
            <ExternalLink
              variant="outline"
              to={`/eligibility?country=${countryCode}&type=${programType}&${queryParams}`}
              onClick={() => gTag('event', 'check_my_eligibility')}
              className="w-full text-center lg:w-auto"
            >
              {t('common.checkMyEligibility')}
            </ExternalLink>
          )}
        </div>
      </div>
      <div className="container mx-auto mt-8 mb-24 px-4 lg:mt-16">
        <div dangerouslySetInnerHTML={{ __html: description }} className="prose !max-w-none" />
        <div id="pricing-desktop" className="mt-5" />
      </div>
    </>
  );
}

import { useBreakpoints } from '@/utils/breakpoint';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

export function TrustedPartners() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <div className="my-30">
      <h3 className="text-center text-2xl font-semibold uppercase text-brand-blue">{t('partner.trustedBy')}</h3>
      <div className="mt-16 flex flex-col items-center justify-items-center gap-16 lg:mx-auto lg:max-w-[1260px] lg:flex-row lg:flex-wrap lg:items-start lg:items-center lg:justify-center">
        <a
          href="https://www.techstars.com/accelerators/toronto"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="h-[131px]"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partner/Techstars.png"
            alt="Techstars"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.acceleratorcentre.com/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="h-[131px]"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partner/The_ACCELERATOR_CENTRE.png"
            alt="The ACCELERATOR CENTRE"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.launchacademy.ca/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="h-[131px]"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partner/LAUNCH.png"
            alt="LAUNCH"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.alacritycanada.com/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partner/Alacrity_Canada.png"
            alt="Alacrity Canada"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://albertaiot.com/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partner/Alberta_IoT.jpeg"
            alt="Alberta IoT"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.forumvc.com/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px]"
            src="../../assets/img/partners/forum.png"
            alt="Forum Ventures"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://velocityincubator.com/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px] lg:-mt-4"
            src="../../assets/img/partners/velocity.png"
            alt="Velocity"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://dmz.torontomu.ca/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center px-5"
        >
          <StaticImage
            class="w-[160px]"
            src="../../assets/img/partner/DMZ-logo.png"
            alt="DMZ"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.treefrog.biz/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px] lg:-mt-4"
            src="../../assets/img/partner/Treefrog-logo.png"
            alt="treefrog"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
        <a
          href="https://www.yorku.ca/yspace/"
          target="_blank"
          rel="noopener noreferrer"
          {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
          className="flex h-[131px] items-center justify-center"
        >
          <StaticImage
            class="w-[200px] lg:-mt-4"
            src="../../assets/img/partner/y-space-logo.png"
            alt="yspace"
            placeholder="blurred"
            layout="fullWidth"
          />
        </a>
      </div>
    </div>
  );
}

import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useState, useEffect, useRef } from 'react';

import { BlogPosts } from '@/@types/blog';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { CountryBadge } from './CountryBadge';

interface PostListProps {
  posts: BlogPosts;
  hrefLang: string;
  topicId: number;
  country: 'CA' | 'US' | 'BOTH';
  debouncedSearchTerm: string;
}

export function PostList({ posts, hrefLang, topicId, country, debouncedSearchTerm }: PostListProps) {
  const { t } = useTranslation();
  const allPosts = posts.filter((post) => {
    const isCountryMatched = country === 'BOTH' || post.country === country || post.country === 'BOTH';
    return topicId === 0
      ? isCountryMatched &&
          (post.title.toLowerCase().includes(debouncedSearchTerm) ||
            (post.description || '').toLowerCase().includes(debouncedSearchTerm))
      : isCountryMatched && post.categories?.some(({ id: categoryId }) => categoryId === topicId);
  });
  const [list, setList] = useState([...allPosts.slice(0, 10)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(allPosts.length > 10);
  const loadRef = useRef();
  useEffect(() => {
    setList([...allPosts.slice(0, 10)]);
  }, [topicId, debouncedSearchTerm, country]);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allPosts.length;
      const nextResults = isMore ? allPosts.slice(currentLength, currentLength + 10) : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line
  useEffect(() => {
    const isMore = list.length < allPosts.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  return (
    <>
      {list.length > 0 ? (
        <div className="mt-10 grid grid-cols-6 gap-x-14 gap-y-20 lg:mt-0">
          {list.map(
            ({
              image: coverImageUrl,
              thumbnail: thumbnailUrl,
              title,
              description,
              plainText,
              categories,
              publishedDate,
              id,
              slug,
              country: postCountry,
            }) => (
              <Link
                key={id}
                className="group col-span-6 cursor-pointer duration-1000 animate-in fade-in lg:col-span-3 lg:first:col-span-6 lg:first:grid lg:first:grid-cols-6 lg:first:gap-y-8 lg:first:gap-x-14"
                to={`/blog/${slug}/`}
              >
                <img
                  src={thumbnailUrl || coverImageUrl}
                  alt={title}
                  className="h-[221px] w-full rounded-md object-cover lg:group-first:col-span-3 lg:group-first:h-[376px] xl:group-first:col-span-4"
                />
                <div className="mt-4 flex h-[264px] flex-col overflow-y-hidden lg:group-first:col-span-3 lg:group-first:mt-0 lg:group-first:h-full xl:group-first:col-span-2">
                  <h4 className="text-lg font-semibold text-slate-900 lg:text-xl">{title}</h4>
                  <div className="mt-3 flex flex-wrap items-baseline gap-3">
                    <div className="text-xs text-slate-600">
                      {new Date(publishedDate).toLocaleDateString(hrefLang, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </div>
                    <CountryBadge country={postCountry} />
                    {categories?.map(({ id: categoryId, name: categoryName }) => (
                      <span
                        key={categoryId}
                        className="rounded bg-slate-100 px-2.5 py-0.5 text-xs font-medium text-slate-500"
                      >
                        {categoryName}
                      </span>
                    ))}
                  </div>
                  <div className="mt-4 shrink grow basis-1 overflow-y-hidden text-slate-700 [mask-image:linear-gradient(to_bottom,white,white,transparent)]">
                    {description ? description.trim() : plainText}
                  </div>
                  <div className="mt-5">
                    <button type="button" className="text-slate-700">
                      {t('post.readMore')} <ChevronRightIcon className="inline-block h-5 w-5" />{' '}
                    </button>
                  </div>
                </div>
              </Link>
            ),
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center rounded-lg border border-slate-200 p-4 lg:p-12">
          <MagnifyingGlassIcon className="inline-block h-16 w-16 text-slate-400 lg:h-[100px] lg:w-[100px]" />
          <h4 className="mt-4 text-base text-slate-500 lg:mt-10 lg:text-xl">
            <Trans
              i18nKey="common.noResultsFor"
              t={t}
              components={[<code className="text-pink-600">{debouncedSearchTerm}</code>]}
            />
          </h4>
        </div>
      )}
      <div ref={loadRef} className="flex justify-center">
        {hasMore ? (
          <svg
            className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <div />
        )}
      </div>
    </>
  );
}

export default PostList;

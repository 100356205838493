import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import { cn } from '@/utils/cn';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select/Select';
import { setItemToLocalStorage } from '@/utils/storage-api';

export function LanguageSwitcher({ variant, className = '' }: { variant: 'blue' | 'white'; className?: string }) {
  const { pathname } = useLocation();
  const { config, localizedPath, defaultLang, prefixDefault, locale } = useLocalization();

  let path = pathname;
  config.forEach((item) => {
    const regex = `^/${item.code}`;
    path = path.replace(new RegExp(regex), '');
  });

  return (
    <Select
      name="language"
      value={locale}
      onValueChange={(value) => {
        setItemToLocalStorage('i18nextLng', value);
        navigate(
          localizedPath({
            defaultLang,
            prefixDefault,
            locale: value,
            path: path || '/',
          }),
        );
      }}
    >
      <SelectTrigger
        className={cn(
          'whitespace-nowrap border-none',
          variant === 'white'
            ? 'border-transparent text-brand-dark hover:bg-brand-blue-light'
            : 'caret-white text-white hover:bg-white/10',
          className,
        )}
      >
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        {config.map(({ code, localName }) => (
          <SelectItem value={code} key={code}>
            {localName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

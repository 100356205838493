import { useLocation } from '@reach/router';
import { Trans, useTranslation } from 'react-i18next';
import queryString from 'query-string';
import ExternalLink from '../ui/links/ExternalLink';

interface EstimatedTimeProps {
  totalTime: string;
  children: React.ReactNode;
}
export function EstimatedTime({ totalTime, children }: EstimatedTimeProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  return (
    <div className="border-y border-gray-200 bg-gray-50">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col gap-8 lg:flex-row lg:gap-16">
          <div>
            <div className="text-lg font-medium text-gray-600">{t('pathways.howLongIsItGoingToTake')}</div>
            <div className="mt-2 text-2xl font-semibold text-brand-blue lg:text-4xl">
              {totalTime}
              <span className="font-normal text-gray-600">*</span>
            </div>
            <div className="mt-2 text-gray-500">*{t('pathways.estimatedTimeline')}</div>
          </div>
          <div className="text-sm lg:flex-1">{children}</div>
        </div>
        <div className="mt-12 text-sm text-gray-500">
          <p>{t('pathways.estimatedTimelineDescription1')}</p>
          <p>
            <Trans
              i18nKey="pathways.estimatedTimelineDescription2"
              t={t}
              components={[
                <ExternalLink
                  to={`/applicant/signup?${queryParams}`}
                  className="!text-brand-blue hover:!text-brand-blue-dark hover:underline"
                />,
              ]}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

import { ProgramsUS } from '@/@types/program';
import HeroPricing from '@/components/pricing/HeroPricing';
import { PricingTableUs } from '@/components/pricing/PricingTableUs';
import Layout from '@/components/ui/layouts/Layout';
import SEO from '@/components/ui/seo/SEO';
import { useCountry } from '@/utils/CountryContext';
import { graphql, navigate, PageProps } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function UsPricing({
  data: {
    programs: {
      us_general_programs: generalPrograms,
      us_external_attorney_representation_programs: externalAttorneyRepresentationPrograms = [],
    },
  },
}: PageProps<{ programs: ProgramsUS }>) {
  const { t } = useTranslation();
  const { country } = useCountry();
  const { localizedPath, defaultLang, prefixDefault, locale } = useLocalization();

  useEffect(() => {
    if (country === 'CA') {
      navigate(
        localizedPath({
          defaultLang,
          prefixDefault,
          locale,
          path: '/canada/pricing',
        }),
      );
    }
  }, [country]);
  return (
    <Layout>
      <main className="mt-10 mb-40">
        <SEO
          title={`SuperVisas | ${t('pricing.visaPricing')}`}
          description={t('pricing.superVisasMakesTheVisaProcess')}
        />
        <HeroPricing />
        <PricingTableUs
          generalPrograms={generalPrograms}
          externalAttorneyRepresentationPrograms={externalAttorneyRepresentationPrograms}
        />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query programsCanada($locale: String!) {
    programs(language: { eq: $locale }) {
      id
      language
      us_general_programs {
        id
        name
        program_type
        program_type_name
        price {
          amount
          currency
          formatted_price
        }
      }
    }
  }
`;

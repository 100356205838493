import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/24/outline';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import { gTag } from '@/utils/gTag';
import ExternalLink from '../ui/links/ExternalLink';
import { ProgramType } from '../../@types/ProgramData';

interface PricingProps {
  amount: number;
  countryCode: 'CA' | 'US';
  programType: ProgramType;
  programId: number;
}

const numberFormat = new Intl.NumberFormat('en-CA');

function Benefit({ title }: { title: string }) {
  return (
    <li className="flex items-start gap-1.5">
      <CheckIcon className="inline-block h-4 w-4 opacity-60 lg:mt-0.5" />
      <span>{title}</span>
    </li>
  );
}

export function Pricing({ amount, countryCode, programType, programId }: PricingProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const showCheckMyEligibility = !['sponsor_employee', 'sponsor_family', 'additional_services'].includes(programType);
  return (
    <div className="border-t border-b border-t-gray-200 border-b-gray-200 bg-gray-50 py-10">
      <div className="container mx-auto flex flex-col gap-8 px-4 lg:flex-row lg:justify-between lg:gap-20">
        <div className="2xl:flex-1">
          <div className="text-xl font-semibold text-gray-600">{t('common.pricing')}</div>
          <div className="mt-4 text-5xl font-light text-brand-blue lg:text-6xl">
            {countryCode}$
            <span className="font-sans-serif font-semibold tabular-nums">{numberFormat.format(amount / 100)}</span>
          </div>
          <div className="mt-4 text-xs text-gray-400">+ {t('common.governmentFees')}</div>
        </div>
        <ul className="space-y-2 text-sm text-brand-blue lg:mr-20 lg:text-base">
          <Benefit title={t('programs.pricingDescription1')} />
          <Benefit title={t('programs.pricingDescription2')} />
          <Benefit title={t('programs.pricingDescription3')} />
          <Benefit title={t('programs.pricingDescription4')} />
          <div id="how-it-works-desktop">
            <Benefit title={t('programs.pricingDescription5')} />
          </div>
        </ul>
        <div className="lg:flex lg:items-center lg:justify-center">
          <div className="flex flex-col gap-4">
            {programType !== 'additional_services' && (
              <ExternalLink
                variant="primary"
                to={`/start_new_application?program_id=${programId}&${queryParams}`}
                onClick={() => gTag('event', 'start_an_application')}
                className="w-full text-center lg:w-auto"
              >
                {t('common.startAnApplication')}
              </ExternalLink>
            )}
            {showCheckMyEligibility && (
              <ExternalLink
                variant="outline"
                to={`/eligibility?country=${countryCode}&type=${programType}&${queryParams}`}
                className="w-full text-center lg:w-auto"
                onClick={() => gTag('event', 'check_my_eligibility')}
              >
                {t('common.checkMyEligibility')}
              </ExternalLink>
            )}
          </div>
        </div>
      </div>
      <div id="how-it-works-mobile" />
    </div>
  );
}

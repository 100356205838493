import { useTranslation } from 'react-i18next';
import Link from '@/components/ui/links/Link';

interface PathwayProps {
  title: string;
  description: string;
  to: string;
  children: React.ReactNode;
}

export function Pathway({ title, description, to, children }: PathwayProps) {
  const { t } = useTranslation();
  return (
    <li className="flex w-full flex-col gap-8 lg:inline-flex lg:flex-row lg:items-center lg:gap-12">
      <div className="w-full lg:w-[330px]">{children}</div>
      <div className="flex w-full flex-col items-start justify-between gap-4 lg:h-[200px] lg:w-[480px] lg:gap-0 lg:py-2">
        <div>
          <h4 className="font-display text-2xl font-semibold text-gray-800">{title}</h4>
          <p className="break-keep-all-kr mt-2">{description}</p>
        </div>
        <Link variant="primary" className="mt-2 inline-block w-full text-center lg:w-auto" to={to}>
          {t('common.learnMore')}
        </Link>
      </div>
    </li>
  );
}

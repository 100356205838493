import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import Fuse from 'fuse.js';
import RightArrow from '@/views/faq/components/RightArrow';
import { useTranslation } from 'react-i18next';
import { FAQ, FAQSection } from '@/@types/faq';

interface FAQSearchResultsProps {
  allSections: FAQSection[];
  faqs: Fuse.FuseResult<FAQ>[];
}

function FAQSearchResults({ allSections, faqs }: FAQSearchResultsProps) {
  const { t } = useTranslation();

  return faqs.length ? (
    <div className="container mx-auto my-12 divide-y divide-x-0 divide-solid divide-brand-gray px-4">
      {faqs.map(({ item: f }) => {
        const section = allSections.find((s) => s.topics.map((topic) => topic.slug).includes(f.topicSlug));
        return (
          <div key={`search-result-${f.slug}`} className="py-8 text-lg font-semibold lg:text-xl">
            <Link className="text-brand-dark" to={`/faq/${section.category}/${section.slug}/${f.topicSlug}/${f.slug}/`}>
              <RightArrow />
              {f.title}
            </Link>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="container mx-auto my-12 py-8 text-lg text-brand-dark">{t('faq.noResults')}</div>
  );
}

export default FAQSearchResults;

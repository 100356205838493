import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface SearchByVisaNameProps {
  className?: string;
  wrapperClassName?: string;
  value: string;
  setValue: (value: string) => void;
}

export function SearchByVisaName({ className = '', wrapperClassName = '', value, setValue }: SearchByVisaNameProps) {
  const { t } = useTranslation();
  return (
    <div className={cn('relative inline-block', wrapperClassName)}>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon className="inline-block h-5 w-5 text-gray-500" />
      </div>
      <input
        type="search"
        className={cn('form-input rounded-md border-gray-300 pl-10 shadow-sm', className)}
        placeholder={t('common.search-by-visa-name')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

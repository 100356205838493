import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useDeferredValue, useState } from 'react';
import { useFuse } from '@/utils/fuse';
import FAQHeader from '@/views/faq/components/FAQHeader';
import FAQOverview from '@/views/faq/FAQOverview';
import SEO from '@/components/ui/seo/SEO';
import { FAQSection, FAQ, FAQCategory } from '@/@types/faq';
import FAQSearchResults from '@/views/faq/components/FAQSearchResults';
import Layout from '@/components/ui/layouts/Layout';

interface FAQData {
  allFaqSections: { edges: { node: { faqSections: FAQSection[] } }[] };
  allFaq: { edges: { node: FAQ }[] };
  allCategory: { edges: { node: FAQCategory }[] };
}

const options = {
  keys: ['title'],
  threshold: 0.6,
  distance: 300,
};

function Faq({
  data: {
    allFaqSections: { edges: faqSectionNodes },
    allFaq: { edges },
    allCategory: { edges: faqCategoryNodes },
  },
}: PageProps<FAQData>) {
  const [userInput, setUserInput] = useState<string>('');
  const value = useDeferredValue(userInput);
  const { t } = useTranslation();

  const { faqSections } = faqSectionNodes[0].node;
  const faqCategoryList = faqCategoryNodes.map((node) => node.node);
  const faqs = edges.map((e) => e.node);

  const matchedFaqs = useFuse(faqs, value, options);

  return (
    <Layout>
      <main id="program-landing-page">
        <SEO
          title={`SuperVisas | ${t('header.faq')}`}
          description={t('seo.weHaveCollectedTheMostFrequentlyAskedQuestions')}
        />
        <div className="bg-white">
          <FAQHeader title={t('faq.Q&A')} subTitle={t('faq.mostFrequent')} onUserInputChange={setUserInput} />
          {value.length ? (
            <FAQSearchResults allSections={faqSections} faqs={matchedFaqs} />
          ) : (
            <FAQOverview faqSections={faqSections} faqCategoryList={faqCategoryList} />
          )}
        </div>
      </main>
    </Layout>
  );
}

export default Faq;

export const query = graphql`
  query FAQTemplatePage {
    allFaqSections {
      edges {
        node {
          faqSections {
            category
            slug
            title
            topics {
              title
              slug
            }
          }
        }
      }
    }
    allFaq {
      edges {
        node {
          title
          slug
          topicSlug
        }
      }
    }
    allCategory {
      edges {
        node {
          icon
          slug
          title
          sections {
            slug
            title
          }
        }
      }
    }
  }
`;

import { cn } from '@/utils/cn';
import { useCountry } from '@/utils/CountryContext';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select/Select';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocalization } from 'gatsby-theme-i18n';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

export function CountrySwitcher({ variant, className = '' }: { variant: 'blue' | 'white'; className?: string }) {
  const { country, setCountry } = useCountry();
  const { t } = useTranslation();
  const { localizedPath, defaultLang, prefixDefault, locale, config } = useLocalization();
  const { pathname } = useLocation();

  function navigateToLocalizedPath({ path, hardRefresh = false }: { path: string; hardRefresh?: boolean }) {
    const localPath = localizedPath({
      defaultLang,
      prefixDefault,
      locale,
      path,
    });
    if (hardRefresh) {
      window.location.href = localPath;
    } else {
      navigate(localPath);
    }
  }

  function navigateConditionally({ value }: { value: 'US' | 'CA' | '' }) {
    let originalPath = pathname;
    config.forEach((item) => {
      /* check if locale code needs to be removed from url to get original path */
      if (new RegExp(`^/${item.code}$|^/${item.code}/`).test(originalPath)) {
        originalPath = originalPath.replace(new RegExp(`^/${item.code}`), '');
      }
    });
    if (['/', '/canada/', '/us/'].includes(originalPath)) {
      if (value === '') {
        navigateToLocalizedPath({ path: '/' });
      } else if (value === 'CA') {
        navigateToLocalizedPath({ path: '/canada' });
      } else if (value === 'US') {
        navigateToLocalizedPath({ path: '/us' });
      }
    }

    /* It needs hard refresh to apply params filters in same page */
    if (['/blog/'].includes(originalPath)) {
      if (value === '') {
        navigateToLocalizedPath({ path: '/blog/?topic=0&country=BOTH', hardRefresh: true });
      } else if (value === 'CA') {
        navigateToLocalizedPath({ path: '/blog/?topic=0&country=CA', hardRefresh: true });
      } else if (value === 'US') {
        navigateToLocalizedPath({ path: '/blog/?topic=0&country=US', hardRefresh: true });
      }
    }

    if (value === 'US') {
      if (pathname.includes('/visas-canada/list')) {
        navigateToLocalizedPath({ path: '/visas-us/list' });
      }
    } else if (value === 'CA') {
      if (pathname.includes('/visas-us/list')) {
        navigateToLocalizedPath({ path: '/visas-canada/list' });
      }
    }
  }
  return (
    <Select
      name="language"
      value={country}
      onValueChange={(value: 'US' | 'CA' | '') => {
        setCountry(value);
        navigateConditionally({ value });
      }}
    >
      <SelectTrigger
        className={cn(
          'whitespace-nowrap border-none',
          variant === 'white'
            ? 'border-transparent text-brand-dark hover:bg-brand-blue-light'
            : 'caret-white text-white hover:bg-white/10',
          className,
        )}
      >
        <SelectValue placeholder={t('common.selectCountry')} />
      </SelectTrigger>
      <SelectContent className="min-w-[165px]">
        <SelectItem value="">{t('common.selectCountry')}</SelectItem>
        <SelectItem value="US">
          <StaticImage
            className="inline-block align-middle shadow-lg"
            src="../../../assets/img/us.svg"
            alt="USA flag"
            placeholder="blurred"
            layout="fixed"
            width={28.5}
            height={15}
          />
        </SelectItem>
        <SelectItem value="CA">
          <StaticImage
            className="inline-block align-middle shadow-lg"
            src="../../../assets/img/ca.svg"
            alt="Canada flag"
            placeholder="blurred"
            layout="fixed"
            width={30}
            height={15}
          />
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

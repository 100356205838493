import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { ReactNode, PropsWithChildren } from 'react';
import { useBreakpoints } from '@/utils/breakpoint';
import ExternalLink from '../ui/links/ExternalLink';

interface ProgramCountProps {
  title: ReactNode;
  country: 'CA' | 'US';
}

export function ProgramCount({ title, country, children }: PropsWithChildren<ProgramCountProps>) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();

  /* for sending language code data from Gatsby to React with query string */
  const parsed = queryString.parse(location.search);
  parsed.lng = language;
  const queryParams = queryString.stringify(parsed);
  const { isMobile } = useBreakpoints();
  return (
    <div
      className="flex flex-col gap-4 bg-gray-100 py-8 px-4 text-center lg:w-[467px] lg:items-center lg:px-12"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <div className="font-display font-medium text-gray-700">{title}</div>
      {children}
      <ExternalLink variant="outline" to={`/eligibility?country=${country}&${queryParams}`} className="inline-block">
        {t('programs.checkProgramEligibility')}
      </ExternalLink>
    </div>
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { FAQCategory } from '@/@types/faq';
import { ChevronDownIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { cn } from '@/utils/cn';

interface FAQSideBarProps {
  sectionSlug: string;
  setSectionSlug: React.Dispatch<React.SetStateAction<string>>;
  category: FAQCategory;
  openCategory: boolean;
}

function CategoryIcon({ icon }: { icon: string }) {
  if (icon === 'language') {
    return <GlobeAltIcon className="inline-block h-5 w-5 text-brand-blue" />;
  }
  return null;
}

function FAQSideBar({ sectionSlug, setSectionSlug, category, openCategory }: FAQSideBarProps) {
  const [isOpen, setIsOpen] = useState<boolean>(openCategory);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container mx-auto mb-4 px-4 lg:mb-0">
      <div className="hidden cursor-pointer hover:text-brand-blue lg:mr-5 lg:flex lg:justify-between" onClick={toggle}>
        <div className="flex items-center">
          <CategoryIcon icon={category.icon} />
          <span className="ml-1 align-middle">{category.title}</span>
        </div>
        <span className="align-middle">
          <ChevronDownIcon
            className={cn('inline-block h-5 w-5 duration-300 ease-in-out', { '-rotate-90 ': !isOpen })}
          />
        </span>
      </div>
      <div
        className={`flex cursor-pointer justify-between border border-brand-gray p-3 ${
          isOpen && 'border-b-0'
        } hover:text-brand-blue lg:hidden`}
        onClick={toggle}
      >
        <div className="flex">
          <CategoryIcon icon={category.icon} />
          <span className="ml-1 align-middle">{category.title}</span>
        </div>
        <span className="align-middle">
          <ChevronDownIcon
            className={cn('inline-block h-5 w-5 duration-300 ease-in-out', { '-rotate-90 ': !isOpen })}
          />
        </span>
      </div>
      <div className={`mt-3 hidden overflow-hidden duration-500 lg:block ${isOpen ? 'max-h-full' : 'max-h-0'}`}>
        {category.sections.map((section) => (
          <div
            key={`section-${section.slug}`}
            className={`ml-2.5 cursor-pointer overflow-auto border-l-2 border-r-0 border-t-0 border-b-0 border-solid py-2 pl-4 hover:text-brand-blue ${
              sectionSlug === section.slug ? 'border-brand-blue text-brand-blue' : 'border-brand-gray'
            }`}
            onClick={() => {
              setSectionSlug(section.slug);
            }}
          >
            <span>{section.title}</span>
          </div>
        ))}
      </div>
      <div
        className={`${!isOpen && 'hidden'} ${
          isOpen ? 'max-h-full' : 'max-h-0'
        } divide-y divide-x-0 divide-solid divide-brand-gray overflow-hidden border border-brand-gray duration-500 lg:hidden`}
      >
        {category.sections.map((section) => (
          <div
            key={`section-${section.slug}-mobile`}
            className={`cursor-pointer overflow-auto py-3 pl-12 hover:text-brand-blue ${
              sectionSlug === section.slug ? 'text-brand-blue' : ''
            }`}
            onClick={() => {
              setSectionSlug(section.slug);
            }}
          >
            <span>{section.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQSideBar;

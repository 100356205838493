import { useBreakpoints } from '@/utils/breakpoint';
import { useTranslation } from 'react-i18next';

function Feature({ title, description }: { title: string; description: string }) {
  const { isMobile } = useBreakpoints();
  return (
    <li
      className="max-w-[250px] flex-1"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <h5 className="text-center text-xl font-semibold text-brand-blue lg:text-left">{title}</h5>
      <p className="mt-2 text-center text-gray-600 text-balance lg:max-w-[306px] lg:text-left">{description}</p>
    </li>
  );
}

function Mission({ title, descriptions }: { title: string; descriptions: string[] }) {
  const { isMobile } = useBreakpoints();
  return (
    <li
      className="prose lg:!max-w-none"
      {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
    >
      <h5 className="font-display text-xl font-semibold text-gray-700">{title}</h5>
      <div className="space-y-4">
        {descriptions.map((description) => (
          <p key={description} className="break-keep-all-kr mt-4 text-gray-600">
            {description}
          </p>
        ))}
      </div>
    </li>
  );
}

export function OurMission() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-30 px-4">
      <h3 className="break-keep-all-kr text-center font-display text-3xl font-bold text-gray-700 text-balance lg:mx-auto lg:max-w-[1000px]">
        {t('about.ourMissionIsToProvide')}
      </h3>
      <ul className="mt-12 flex flex-col items-center gap-8 lg:flex-row lg:items-start lg:justify-around lg:gap-16">
        <Feature title="600+" description={t('about.peopleWeHaveHelped')} />
        <Feature title="98%" description={t('about.currentGovernmentOfCanadaVisaApprovalRate')} />
        <Feature title="2" description={t('about.destinationCountriesCurrentlySupported')} />
      </ul>
      <div id="transparency-pledge" />
      <ul className="mt-30 space-y-10">
        <Mission
          title={t('about.ourTransparencyPledge')}
          descriptions={[t('about.ourTransparencyPledgeDescription1'), t('about.ourTransparencyPledgeDescription2')]}
        />
        <Mission
          title={t('about.peaceOfMindThoughTechnologyAndExpertReview')}
          descriptions={[
            t('about.peaceOfMindThoughTechnologyAndExpertReviewDescription1'),
            t('about.peaceOfMindThoughTechnologyAndExpertReviewDescription2'),
          ]}
        />
      </ul>
    </div>
  );
}

import { ArrowRightIcon } from '@heroicons/react/24/outline';

function RightArrow() {
  return (
    <div>
      <ArrowRightIcon className="mb-4 inline-block h-6 w-6 font-light lg:relative lg:top-1.5 lg:float-right" />
    </div>
  );
}
export default RightArrow;

import { useTranslation } from 'react-i18next';
import Layout from '@/components/ui/layouts/Layout';
import Link from '@/components/ui/links/Link';
import SEO from '@/components/ui/seo/SEO';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Layout>
      <main>
        <SEO title={`SuperVisas | ${t('notFound.pageNotFound')}`} description={t('notFound.seoDescription')}>
          <meta name="robots" content="noindex" />
        </SEO>
        <div className="mt-6 flex  w-full justify-center rounded-lg bg-white lg:items-center">
          <div className="mt-10 flex flex-col items-center text-center lg:mt-0">
            <p className="font-bold text-blue-500">{t('notFound.404Error')}</p>
            <h1 className="my-6 text-2xl font-extrabold text-gray-700 lg:text-5xl">{t('notFound.pageNotFound')}</h1>
            <p className="mt-1 text-base text-gray-500">{t('notFound.sorryWeCouldNotFindThePage')}</p>
            <Link className="mt-10 mb-40 lg:mt-20" variant="primary" to="/">
              {t('notFound.goBackHome')}
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}

import { Quote } from '@/components/ui/review/Quote';
import { CheckListItem } from '@/components/partner/CheckListItem';
import { PartnerFeatures } from '@/components/partner/PartnerFeatures';
import Layout from '@/components/ui/layouts/Layout';
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@/utils/breakpoint';
import SEO from '@/components/ui/seo/SEO';
import { RipplingIntegration } from '@/components/partner/RipplingIntegration';

function BreadCrumbs() {
  const { t } = useTranslation();
  return (
    <nav className="container mx-auto rounded-lg px-4 text-gray-700" aria-label="Breadcrumb">
      <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
        <li className="inline-flex items-center">
          <LocalizedLink
            to="/partners/"
            className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
          >
            {t('header.partners')}
          </LocalizedLink>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{t('partner.employers')}</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default function Employers() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  return (
    <Layout>
      <main className="mt-10">
        <SEO title={`SuperVisas | ${t('seo.employers')}`} description={t('partner.forEmployersDescription')}>
          <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
        </SEO>
        <div className="container mx-auto px-4">
          <BreadCrumbs />
          <div className="mt-20 mb-20 flex flex-col gap-16 lg:mx-auto lg:max-w-[1200px] lg:flex-row">
            <div className="w-full lg:min-w-[420px] lg:flex-1">
              <StaticImage
                src="../../../assets/img/partner/employers.jpg"
                alt="employers"
                placeholder="blurred"
                layout="fullWidth"
              />
              <ul
                className="mt-12 flex flex-col items-center gap-8 text-center"
                {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}
              >
                <li className="flex flex-col p-4 md:p-6 lg:justify-between  lg:p-4 xl:p-8 2xl:p-12">
                  <Quote author="LISE RUSSELL, COO" organization="Orbiseed Technology, Inc.">
                    {t('partner.liseReview')}
                  </Quote>
                </li>
                <li className="flex flex-col p-4 md:p-6 lg:justify-between  lg:p-4 xl:p-8 2xl:p-12">
                  <Quote author="BO PENG, PRODUCT & OPERATIONS" organization="Arcana">
                    {t('partner.boReview')}
                  </Quote>
                </li>
              </ul>
            </div>
            <div className="text-gray-500 lg:flex-1">
              <h1 className="font-display text-lg font-bold text-gray-800 lg:text-3xl">{t('partner.employers')}</h1>
              <p className="mt-4">{t('partner.weAreYourOneStopShop')}</p>
              <ul className="mt-10 space-y-6">
                <CheckListItem
                  title={t('partner.trackStatusOnline')}
                  description={t('partner.trackStatusOnlineDescription')}
                />
                <CheckListItem
                  title={t('partner.immigrationExperts')}
                  description={t('partner.immigrationExpertsDescription')}
                />
                <li {...(!isMobile && { 'data-sal': 'slide-up', 'data-sal-delay': '300', 'data-sal-easing': 'ease' })}>
                  <div className="flex items-center gap-1.5 text-lg font-semibold text-brand-blue">
                    <CheckIcon className="inline-block h-4 w-4 opacity-60 lg:mt-0.5" />
                    <span>{t('partner.ourPlatform')}</span>
                  </div>
                  <p className="break-keep-all-kr ml-5 mt-1.5">{t('partner.ourPlatformDescription1')}</p>
                  <p className="break-keep-all-kr ml-5 mt-3">{t('partner.ourPlatformDescription2')}</p>
                  <p className="break-keep-all-kr ml-5 mt-3">{t('partner.ourPlatformDescription3')}</p>
                </li>
                <CheckListItem
                  title={t('partner.discountedRates')}
                  description={t('partner.discountedRatesDescription')}
                />
              </ul>
            </div>
          </div>
        </div>
        <PartnerFeatures />
        <RipplingIntegration />
        <div
          className="meetings-iframe-container mt-20"
          data-src="https://meetings.hubspot.com/joe-chen/demo?embed=true"
        />
      </main>
    </Layout>
  );
}

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

interface PathwayHeroProps {
  title: string;
  children: React.ReactNode;
}

export function PathwayHero({ title, children }: PathwayHeroProps) {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto px-4">
      <nav className="mb-10 text-gray-700" aria-label="Breadcrumb">
        <ul className="-ml-2 inline-flex flex-wrap items-center md:gap-x-1">
          <li className="inline-flex items-center">
            <LocalizedLink
              to="/pathways/"
              className="inline-flex items-center rounded-md p-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
            >
              {t('header.pathways')}
            </LocalizedLink>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
              <span className="select-none p-2 text-sm font-medium text-gray-500 md:ml-1">{title}</span>
            </div>
          </li>
        </ul>
      </nav>
      <h1 className="text-2xl font-semibold text-gray-700 lg:text-3xl">
        {t('pathways.canadianImmigrationPathway')}: {title}
      </h1>
      <div className="prose mt-10 mb-20 lg:max-w-[1024px]">{children}</div>
    </div>
  );
}

import cn from 'classnames';

interface ProgramBadgeProps {
  type: string;
  name: string;
}

export function ProgramBadge({ type, name }: ProgramBadgeProps) {
  return (
    <span
      className={cn(
        'inline-flex items-center whitespace-nowrap rounded px-2 py-1.5 text-[10px] font-bold leading-none',
        { 'bg-violet-50 text-violet-700': type === 'immigrate' },
        { 'bg-indigo-50 text-indigo-700': type === 'work' },
        { 'bg-teal-50 text-teal-700': type === 'study' },
        { 'bg-fuchsia-50 text-fuchsia-700': type === 'visitor' },
        { 'bg-gray-100 text-gray-700': !['immigrate', 'work', 'study', 'visitor'].includes(type) },
      )}
    >
      {name}
    </span>
  );
}

import { useTranslation } from 'react-i18next';
import { Quote } from '../ui/review/Quote';

function CanadaReviews() {
  const { t } = useTranslation();

  return (
    <>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[190px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Johnny" organization={t('home.china')}>
          {t('home.johnnyReview')}
        </Quote>
      </li>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[190px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Fintelics" organization={t('home.canada')}>
          {t('home.fintelicsReview')}
        </Quote>
      </li>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[190px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Sebastian" organization={t('home.peru')}>
          {t('home.sebastianReview')}
        </Quote>
      </li>
    </>
  );
}

function USReviews() {
  const { t } = useTranslation();
  return (
    <>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[246px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Bo" organization={t('common.usa')}>
          {t('home.boReview')}
        </Quote>
      </li>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[246px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Asim" organization={t('home.pakistan')}>
          {t('home.asimReview')}
        </Quote>
      </li>
      <li className="flex max-w-[300px] flex-col md:max-w-[360px] lg:min-h-[246px] lg:max-w-[420px] lg:justify-between">
        <Quote author="Salina" organization={t('home.china')}>
          {t('home.salinaReview')}
        </Quote>
      </li>
    </>
  );
}

export function Reviews({ destinationCountry }: { destinationCountry: 'CA' | 'US' }) {
  const { t } = useTranslation();
  return (
    <div className="my-20">
      <div className="container mx-auto px-4 text-center text-gray-600">
        <div className="text-base font-semibold lg:text-3xl">
          <span className="text-brand-blue">98%</span> <span>{t('common.successRate')}</span>
        </div>
        <div className="mt-4 text-base font-semibold lg:text-3xl">{t('home.seeWhatOurCustomers')}</div>
      </div>
      <div className="mt-6 overflow-x-auto px-4 text-center lg:container lg:mx-auto">
        <ul className="mt-12 flex min-w-max flex-row items-start gap-12 lg:min-w-0 lg:justify-between lg:gap-4">
          {destinationCountry === 'CA' ? <CanadaReviews /> : <USReviews />}
        </ul>
      </div>
    </div>
  );
}

import { BlogPost } from '@/@types/blog';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { Trans, useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useQueryParams } from '@/utils/useQueryParams';
import { shareSocial } from '@/utils/shareSocial';
import ExternalLink from '../ui/links/ExternalLink';
import Link from '../ui/links/Link';
import { CountryBadge } from './CountryBadge';
import { Author } from './Author';

interface PostProps {
  post: BlogPost & { relatedPosts: Pick<BlogPost, 'id' | 'categories' | 'slug' | 'image' | 'thumbnail' | 'title'>[] };
  hrefLang: string;
}

export function Post({
  post: {
    categories,
    publishedDate,
    title,
    description,
    image: coverImageUrl,
    content,
    country: postCountry,
    relatedPosts,
    author,
  },
  hrefLang,
}: PostProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { queryParams } = useQueryParams({ language });
  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <div className="container mx-auto mt-20 mb-60 flex flex-col gap-24 px-4 lg:mt-40 lg:justify-center xl:flex-row">
      <article className="prose prose-slate prose-a:text-blue-600 lg:prose-lg">
        <div className="mb-4 flex flex-wrap items-baseline gap-3">
          <div className="text-xs text-slate-600">
            {new Date(publishedDate).toLocaleDateString(hrefLang, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
          <CountryBadge country={postCountry} />
          {categories?.map(({ id: categoryId, name: categoryName }) => (
            <span key={categoryId} className="rounded bg-slate-100 px-2.5 py-0.5 text-xs font-medium text-slate-500">
              {categoryName}
            </span>
          ))}
        </div>
        <h1 className="font-display !leading-tight">{title}</h1>
        {description && <p className="text-slate-500">{description.trim()}</p>}
        <img src={coverImageUrl} alt={title} className="aspect-video w-full rounded-md object-cover" />
        <div className="mt-20" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="my-20">
          <Author author={author} />
        </div>
        <div className="mt-10 flex items-center gap-4 border-t border-slate-200 pt-6">
          <span className="text-slate-400">{t('post.share')}:</span>
          <span className="inline-flex items-center gap-4">
            <a href={shareSocial.linkedin(url)} target="_blank" rel="noreferrer" title="Share article to LinkedIn">
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../assets/img/social_media/LinkedInx4.png"
                alt="LinkedIn logo"
                placeholder="blurred"
              />
            </a>
            <a href={shareSocial.facebook(url)} target="_blank" rel="noreferrer" title="Share article to Facebook">
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../assets/img/social_media/Facebookx4.png"
                alt="Facebook logo"
                placeholder="blurred"
              />
            </a>
            <a href={shareSocial.twitter(url)} target="_blank" rel="noreferrer" title="Share article to Twitter">
              <StaticImage
                className="w-[24px] lg:w-[24px]"
                layout="fullWidth"
                src="../../assets/img/social_media/Twitterx4.png"
                alt="Twitter logo"
                placeholder="blurred"
              />
            </a>
          </span>
        </div>
      </article>
      <aside className="flex w-full flex-col-reverse gap-16 lg:block lg:gap-0 xl:w-[350px]">
        <div className="mb-8 flex flex-col gap-4 rounded-lg bg-slate-50 p-4 lg:items-center lg:p-8">
          <h4 className="text-center font-display text-xl font-semibold">
            <Trans
              i18nKey="programs.weSupportVisasInTheUSAAndCanada"
              t={t}
              components={[<span className="text-brand-blue" />]}
            />
          </h4>
          <ExternalLink variant="primary" to={`/eligibility?${queryParams}`} className="inline-block text-center">
            {t('programs.checkProgramEligibility')}
          </ExternalLink>
          <Link to="/visas/" variant="outline" className="bg-white text-center">
            {t('programs.viewAllVisas')}
          </Link>
        </div>
        <div>
          <h3 className="mb-4 text-lg font-semibold uppercase text-slate-600">{t('post.relatedArticles')}</h3>
          <div className="mt-6 flex flex-col gap-12 lg:flex-row xl:flex-col">
            {relatedPosts.map(
              ({ id, categories: relatedPostCategories, slug, image, thumbnail, title: relatedPostTitle }) => (
                <LocalizedLink key={id} to={`/blog/${slug}`} className="lg:w-[350px]">
                  <img
                    src={thumbnail || image}
                    alt={relatedPostTitle}
                    className="aspect-video w-full rounded-md object-cover"
                  />
                  <h4 className="mt-4 text-lg font-semibold text-slate-900 lg:text-xl">{relatedPostTitle}</h4>
                  <div className="mt-3 flex flex-wrap items-baseline gap-3">
                    <div className="text-xs text-slate-600">
                      {new Date(publishedDate).toLocaleDateString(hrefLang, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </div>
                    {relatedPostCategories?.map(({ id: categoryId, name: categoryName }) => (
                      <span
                        key={categoryId}
                        className="rounded bg-slate-100 px-2.5 py-0.5 text-xs font-medium text-slate-500"
                      >
                        {categoryName}
                      </span>
                    ))}
                  </div>
                </LocalizedLink>
              ),
            )}
          </div>
        </div>
      </aside>
    </div>
  );
}
